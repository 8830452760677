import moment from 'moment';

export default class PriceTrackerProductModel {
  constructor(data, index) {
    this.id = index;
    this.seller_id = data && data.seller_id ? data.seller_id : '';
    this.brand = data && data.brand ? data.brand : '';
    this.asin = data && data.asin ? data.asin : '';
    this.description = data && data.description ? data.description : '';
    this.is_fulfilled_by_amazon = data && data.is_fulfilled_by_amazon ? data.is_fulfilled_by_amazon : '';
    this.is_prime = data && data.is_prime ? data.is_prime : '';
    this.listing_price_amount = data && data.listing_price_amount ? data.listing_price_amount : 0;
    this.map = data && data.map ? data.map : 0;
    this.variance = Math.round((this.listing_price_amount - this.map) * 100) / 100;
    this.part_no = data && data.part_no ? data.part_no : 0;
    this.upc_ean = data && data.upc_ean ? data.upc_ean : 0;
    this.vendor = data && data.vendor ? data.vendor : 0;
    this.map_violations = data && data.map_violations ? data.map_violations : 0;
    this.unauthorized = data && data.unauthorized ? data.unauthorized : 0;
    this.created_ts = data && data.created_ts ? moment(data.created_ts).format('MM/DD/YYYY h:mm A') : '';
  } 
}