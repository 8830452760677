import React, { useCallback, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Col, Input, Row, Table } from 'reactstrap';
import errorTrackerService from '../../services/error_tracker.service';
import ReactJson from 'react-json-view';
import './ErrorTracker.css';
import ErrorModel from '../../models/error.model';

const ErrorTracker = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(true);
  const [allErrors, setAllErrors] = useState(true);
  const [selectedError, setSelectedError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const getErrors = useCallback(async () => {
    try {
      let newErrors = await errorTrackerService.getErrors();
      newErrors = newErrors.map(e => new ErrorModel(e));
      setErrors(newErrors);
      setAllErrors(newErrors);
      setSelectedError(newErrors[0]);
      setSelectedRow(newErrors[0].id);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const filterErrors = (filterSearch) => {
    try {
      const filter = filterSearch.target.value;
      // setFilterString(filter);
      if (filter && filter !== '') {
        const filteredErrors = allErrors.filter(e => e.service.indexOf(filter) !== -1 || e.module.indexOf(filter) !== -1 || e.created_ts.indexOf(filter) !== -1);
        setErrors(filteredErrors);
      } else {
        setErrors(allErrors);
      }

    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getErrors();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid error-tracker" style={{marginTop: '10px', fontSize: '14px'}}>
        <Row style={{height: 'calc(100vh - 130px)', overflowY: 'auto'}}>
          <React.Fragment>
            <Col lg={6}>
              <Input className="error-tracker-search-field" placeholder="Search" onChange={(e) => filterErrors(e)} />
              <div className="error-tracker-table">
                <Table style={{height: '1200px'}} size="sm">
                  <thead>
                    <tr>
                      <th>Service</th>
                      <th>Module</th>
                      <th>Date / Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    { errors.length > 0 && errors.map(e =>
                      <tr key={e.id} onClick={() => {setSelectedError(e); setSelectedRow(e.id);}} className={e.id === selectedRow ? 'list-row error-tracker-selected-row' : 'list-row'}>
                        <td className="error-tracker-row" style={{textAlign: 'center'}}>{e.service}</td>
                        <td className="error-tracker-row" style={{textAlign: 'center'}}>{e.module}</td>
                        <td style={{textAlign: 'center'}}>{e.created_ts}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

            </Col>
            <Col lg={6} style={{marginTop: '56px'}}>
              { selectedError && isJsonString(selectedError.error_output) &&
                <ReactJson src={JSON.parse(selectedError.error_output)} style={{textAlign: 'left'}} theme="bright:inverted" displayDataTypes="true" indentWidth="7" />
              }
              { !selectedError &&
                <span className="error-tracker-warning-message">Please select error to display</span>
              }
              { selectedError && !isJsonString(selectedError.error_output) &&
                <React.Fragment>
                  <span className="error-tracker-warning-message">JSON not in proper format, raw output provided</span>
                  <ReactJson src={{raw_output: selectedError.error_output}} style={{textAlign: 'left'}} theme="bright:inverted" displayDataTypes="true" indentWidth="7" />
                </React.Fragment>
              }
            </Col>
          </React.Fragment>
        </Row>
      </div>
    );
  }
};

export default ErrorTracker;