import moment from 'moment';

export default class ShipmentInvoiceItem {
  constructor(data) {
    this.shipment_name = data && data.shipment_name ? data.shipment_name : '';
    this.shipment_id = data && data.shipment_id ? data.shipment_id : '';
    this.shipment_name_table = this.shipment_name !== '' && !data.shipment_name.toLowerCase().startsWith('q_') ? `${this.shipment_name} - ${this.shipment_id}` : this.shipment_name;
    this.shipment_status = data && data.shipment_status ? data.shipment_status : '';
    this.qbp = data && data.qbp ? data.qbp : 0;
    this.jbi = data && data.jbi ? data.jbi : 0;
    this.hlc = data && data.hlc ? data.hlc : 0;
    this.tgc = data && data.tgc ? data.tgc : 0;
    this.olympic = data && data.olympic ? data.olympic : 0;
    this.bti = data && data.bti ? data.bti : 0;
    this.mpn = data && data.mpn ? data.mpn : 0;
    this.upc = data && data.upc ? data.upc : 0;
    this.invoices_uploaded = data && data.invoices_uploaded ? data.invoices_uploaded : 0;
    this.invoice_link = data && data.invoice_link ? data.invoice_link : '';
    this.lookup_shipment_id = data && data.lookup_shipment_id ? data.lookup_shipment_id : '';
    this.updated_ts = data && data.updated_ts ? moment(new Date(data.updated_ts)).format('MM/DD/YY hh:mm A') : '';
    this.shipment_ts = data && data.shipment_ts ? moment(new Date(data.shipment_ts)).format('MM/DD/YY hh:mm A') : '';
  } 
}