import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FiTruck } from 'react-icons/fi';
import { openReturnReimbursementDetail } from '../../redux/actions';

const ReturnReimbursementDetail = (props) => {
  const dispatch = useDispatch();
  const reimbursementDetail = useSelector(state => state.open_return_reimbursement_detail_info);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const newColumns = [
      {
        text: 'SKU',
        headerStyle: {width: '120px'},
        dataField: 'sku',
        style: {textAlign: 'left'},
        sort: true
      },
      {
        text: 'ASIN',
        dataField: 'asin',
        style: {textAlign: 'left'},
        sort: true
      },
      {
        text: 'FNSKU',
        dataField: 'fnsku',
        style: {textAlign: 'left'},
        sort: true
      },
      {
        text: 'Product',
        dataField: 'product_name',
        headerStyle: {width: '200px'},
        style: {textAlign: 'left'},
        sort: true
      },
      {
        text: 'Approval Date',
        headerStyle: {width: '200px'},
        dataField: 'approval_date',
        style: {textAlign: 'center'},
        sort: true
      },
      {
        text: '$ Per Unit',
        dataField: 'amount_per_unit',
        style: {textAlign: 'right'},
        sort: true
      },
      {
        text: 'Total',
        dataField: 'amount_total',
        style: {textAlign: 'right'},
        sort: true
      },
      {
        text: 'Qty Reimbursed Cash',
        dataField: 'quantity_reimbursed_cash',
        style: {textAlign: 'right'},
        sort: true
      },
      {
        text: 'Qty Reimbursed Inv',
        dataField: 'quantity_reimbursed_inventory',
        style: {textAlign: 'right'},
        sort: true
      }
    ];

    setColumns(newColumns);
    setRows(reimbursementDetail.reimbursement_data);
  }, [reimbursementDetail]);

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => dispatch(openReturnReimbursementDetail())} backdrop="static" size="xl">
      <ModalHeader toggle={() => dispatch(openReturnReimbursementDetail())}>
        <FiTruck style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Reimbursement Breakdown - { reimbursementDetail.return_info.tracking_number }
      </ModalHeader>
      <ModalBody className="return-tracking-history">
        <Row>
          <Col lg={12}>
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} condensed bordered={false} noDataIndication={() => (<div>There is no activity for this tracking number</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" style={{float: 'right'}} color="outline-secondary" onClick={() => dispatch(openReturnReimbursementDetail())}>Close</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default ReturnReimbursementDetail;