export default class ProductInfo {
  constructor(data) {
    this.base_price = data.base_price;
    this.cost = (Math.round(data.cost * 100) / 100).toFixed(2);
    this.discount = data.discount;
    this.distributor = data.distributor;
    this.item_no = data.item_no;
    this.qty = data.qty;
    this.sales_description = data.sales_description;
    this.surcharge =  (Math.round(data.surcharge * 100) / 100).toFixed(2);
    this.warehouse = data.warehouse;
    this.internal_upc = data.internal_upc;
    this.map_profit_amazon =  (Math.round(data.map_profit_amazon * 100) / 100).toFixed(2);
    this.map_profit_ebay =  (Math.round(data.map_profit_ebay * 100) / 100).toFixed(2);
  }
}