import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { setAdminViewOptions, setEndDate, setMobile, setPriceTrackerBrand, setPriceTrackerBrandAllOptions, setPriceTrackerBrandOptions, setPriceTrackerVendor, setPriceTrackerVendorOptions, setSelectedView, setShippingVendorOptions, setStartDate, setTitanViewOptions, setViewOptions } from './redux/actions';
import { PulseLoader } from 'react-spinners';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorTracker from './components/ErrorTracker/ErrorTracker';
import Navbar from './components/Navbar/Navbar';
// import Views from './components/Views/Views';
// import Footer from './components/Footer/Footer';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-notifications-component/dist/theme.css';
import optionsService from './services/options.service';
import Option from './models/option.model';
import moment from 'moment';
import { ReactNotifications } from 'react-notifications-component';
import Login from './components/Login/Login';
import OneTimePassword from './components/Login/OneTimePassword';
import SideNavigation from './components/SIdeNavigation/SideNavigation';
import ProfitReport from './components/Views/ProfitReport';
import ProfitReportDaily from './components/Views/ProfitReportDaily';
import CancelledReport from './components/Views/CancelledReport';
import ErrorReport from './components/Views/ErrorReport';
import UnshippedReport from './components/Views/UnshippedReport';
import RecentProcesses from './components/Views/RecentProcesses';
import ProfitReportLQ from './components/Views/ProfitReportLQ';
import ProfitReportDailyLQ from './components/Views/ProfitReportDailyLQ';
import ProfitReportMonthly from './components/Views/ProfitReportMonthly';
import ProfitReportMonthlyLQ from './components/Views/ProfitReportMonthlyLQ';
import FeedvisorErrorReport from './components/Views/FeedvisorErrorReport';
import ReturnTracking from './components/ReturnTracking/ReturnTracking';
import Returns from './components/Returns/Returns';
import Removals from './components/Removals/Removals';
import SingleSkuShipments from './components/SingleSkuShipments/SingleSkuShipments';
import ShipmentInvoices from './components/ShipmentInvoices/ShipmentInvoices';
import PriceTracker from './components/PriceTracker/PriceTracker';
import InboundShipments from './components/InboundShipments/InboundShipments';
import PriceTrackerOption from './models/price_tracker_option.model';
import Backstock from './components/Backstock/Backstock';

function App() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultComponent, setDefaultComponent] = useState(null);
  const styles = {
    contentDiv: {
      display: 'flex',
    },
    contentMargin: {
      width: '100%'
    },
  };

  const loadApp = async () => {
    try {
      const newOptions = await optionsService.getOptions(user.id);
      const viewOptions = newOptions.view_options.map(o => new Option(o));
      const titanViewOptions = newOptions.titan_view_options.map(o => new Option(o));
      const adminViewOptions = newOptions.admin_view_options.map(o => new Option(o));
      const shippingVendors = newOptions.shipping_vendors.map(o => new Option(o));
      const priceTrackerVendors = newOptions.price_tracker_vendors.map(o => new PriceTrackerOption(o));
      const priceTrackerBrands = newOptions.price_tracker_brands.map(o => new PriceTrackerOption(o));
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      dispatch(setMobile(isMobile));
      dispatch(setViewOptions(viewOptions));
      dispatch(setTitanViewOptions(titanViewOptions));
      dispatch(setAdminViewOptions(adminViewOptions));
      dispatch(setShippingVendorOptions(shippingVendors));
      dispatch(setPriceTrackerVendorOptions(priceTrackerVendors));
      dispatch(setPriceTrackerVendor(priceTrackerVendors[0]));
      let newBrandOptions = priceTrackerBrands.filter(v => v.value === priceTrackerVendors[0].value);
      newBrandOptions = newBrandOptions.map(b => ({ value: b.brand, label: b.brand }));
      newBrandOptions.unshift({ value: 'all', label: 'All Brands' });
      dispatch(setPriceTrackerBrandOptions(newBrandOptions));
      dispatch(setPriceTrackerBrandAllOptions(priceTrackerBrands));
      dispatch(setPriceTrackerBrand(newBrandOptions[0]));
      dispatch(setSelectedView(viewOptions.filter(v => v.id === 1)[0]));
      dispatch(setStartDate(new Date(moment().subtract(2, 'months').startOf('month'))));
      dispatch(setEndDate(new Date()));
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (user) {
      const newDefaultComponent = defaultRoute();
      setDefaultComponent(newDefaultComponent);
      loadApp();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  const defaultRoute = () => {
    if (user.removals === 1) {
      return <Removals />;
    } else if (user.single_sku_shipments === 1) {
      return <SingleSkuShipments />;
    } else if (user.returns === 1) {
      return <Returns />;
    } else if (user.shipment_invoices === 1) {
      return <ShipmentInvoices />;
    } else if (user.backstock === 1) {
      return <Backstock />;
    } else if (user.comp_pricing === 1) {
      return <PriceTracker />;
    }
  };

  if (!isLoading) {
    if (!user) {
      return (
        <div className="App background-image">
          <ReactNotifications />
          <Login setUser={setUser} />
        </div>
      );
    } else if (user && !user.mfaEnabled) {
      return (
        <div className="App">
          <OneTimePassword enabled={false} />
        </div>
      );
    } else if (user.requiredMfa) {
      return (
        <div className="App">
          <OneTimePassword enabled={true} />
        </div>
      );
    } else {
      return (
        <div className="App">
          <Router>
            <React.Fragment>
              <ReactNotifications />
              <Navbar title="Bicycle Addiction" />
              <div style={styles.contentDiv}>
                <SideNavigation></SideNavigation>
                <div style={styles.contentMargin}>
                  <Routes>
                    { user.admin === 1 &&
                      <React.Fragment>
                        <Route path="/" element={<ProfitReportDaily />}/>
                        <Route path="/cancelled" element={<CancelledReport />}/>
                        <Route path="/error-orders" element={<ErrorReport />}/>
                        <Route path="/profit-report" element={<ProfitReport />}/>
                        <Route path="/unshipped" element={<UnshippedReport />}/>
                        <Route path="/recent-processes" element={<RecentProcesses />}/>
                        <Route path="/profit-report-lq" element={<ProfitReportLQ />}/>
                        <Route path="/profit-report-daily-lq" element={<ProfitReportDailyLQ />}/>
                        <Route path="/profit-report-monthly" element={<ProfitReportMonthly />}/>
                        <Route path="/profit-report-monthly-lq" element={<ProfitReportMonthlyLQ />}/>
                        <Route path="/error-tracker" element={<ErrorTracker />}/>
                        <Route path="/feedvisor-errors" element={<FeedvisorErrorReport />}/>
                        <Route path="/return-tracking" element={<ReturnTracking />}/>
                        <Route path="/returns" element={<Returns />}/>
                        <Route path="/removals" element={<Removals />}/>
                        <Route path="/single-sku-shipments" element={<SingleSkuShipments />}/>
                        <Route path="/shipment-invoices" element={<ShipmentInvoices />}/>
                        <Route path="/seller-verify" element={<PriceTracker />}/>
                        <Route path="/inbound-shipments" element={<InboundShipments />}/>
                        <Route path="/backstock" element={<Backstock />}/>
                      </React.Fragment>
                    } 
                    { user.admin === 0 &&
                      <Route path="/" element={defaultComponent}/>

                    }
                    { (user.removals === 1 && user.admin === 0) &&
                      <Route path="/removals" element={<Removals />}/>
                    }
                    { (user.single_sku_shipments === 1 && user.admin === 0) &&
                      <Route path="/single-sku-shipments" element={<SingleSkuShipments />}/>
                    }
                    { (user.returns === 1 && user.admin === 0) &&
                      <Route path="/returns" element={<Returns />}/>
                    }
                    { (user.shipment_invoices === 1 && user.admin === 0) &&
                      <Route path="/shipment-invoices" element={<ShipmentInvoices />}/>
                    }
                    { (user.comp_pricing === 1 && user.admin === 0) &&
                      <Route path="/seller-verify" element={<PriceTracker />}/>
                    }
                    { (user.backstock === 1 && user.admin === 0) &&
                      <Route path="/backstock" element={<Backstock />}/>
                    }
                  </Routes>
                </div>
              </div>
              {/* <Footer/> */}
            </React.Fragment>
          </Router>
        </div>
      );
    }
  } else {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12" style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader 
              sizeUnit={'px'}
              size={20}
              color={'#419bf7'}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
