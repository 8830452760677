import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FaFileInvoiceDollar, FaDownload, FaRegCheckCircle } from 'react-icons/fa';
import { openCreateEditShipmentInvoice } from '../../redux/actions';
import ShipmentInvoiceItem from '../../models/shipment_invoice_item.model';
import ShipmentInvoiceDetailItem from '../../models/shipment_invoice_detail_item.model';
import shipmentInvoicesService from '../../services/shipment_invoices.service';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import InvoiceUploader from './InvoiceUploader';
import { saveAs } from 'file-saver';

const { SearchBar } = Search;

const CreateEditShipmentInvoice = (props) => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.open_create_edit_shipment_invoice_info);
  // const user = useSelector(state => state.user);
  const [shipmentInvoiceInfo, setShipmentInvoiceInfo] = useState(info ? info : new ShipmentInvoiceItem());
  const [shipmentDetailsRows, setShipmentDetailsRows] = useState([]);
  const [shipmentDetailsColumns, setShipmentDetailsColumns] = useState([]);

  const shipmentInvoiceStatusFilter = useSelector(state => state.shipment_invoices_status_filter);
  const shipmentOptionsFilter = useSelector(state => state.shipment_options_filter);

  const pagination = pagionationFactory({
    sizePerPage: 10,
    hideSizePerPage: true
  });

  const getData = async () => {
    try {
      const results = await shipmentInvoicesService.getShipmentDetails(shipmentInvoiceInfo.shipment_name);
      const newShipmentDetailsRows = results.map(r => new ShipmentInvoiceDetailItem(r));
      setShipmentDetailsRows(newShipmentDetailsRows);
    } catch (e) {
      console.log(e);
    }
  };

  const refreshRecord = async (shipmentId) => {
    try {
      const results = await shipmentInvoicesService.shipmentLookup(shipmentId);
      const newShipment = new ShipmentInvoiceItem(results);
      setShipmentInvoiceInfo(newShipment);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const newColumns = [
      {
        text: 'Shipment ID',
        dataField: 'shipment_id',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.shipment_id)
        }
      },
      {
        text: 'SKU',
        dataField: 'seller_sku',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://sellercentral.amazon.com/fba/inbound-shipment/summary/${row.shipment_id}/contents`, '_blank')
        }
      },
      {
        text: 'FNSKU',
        dataField: 'fnsku',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://sellercentral.amazon.com/fba/inbound-shipment/summary/${row.shipment_id}/contents`, '_blank')
        }
      },
      {
        text: 'ASIN',
        dataField: 'asin',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://sellercentral.amazon.com/fba/inbound-shipment/summary/${row.shipment_id}/contents`, '_blank')
        }
      },
      {
        text: 'Description',
        dataField: 'description',
        headerStyle: {width: '400px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://sellercentral.amazon.com/fba/inbound-shipment/summary/${row.shipment_id}/contents`, '_blank')
        }
      }
    ];

    setShipmentDetailsColumns(newColumns);
  }, [shipmentDetailsRows, setShipmentDetailsColumns]);

  const handleCheck = (e) => {
    const newShipmentInvoiceInfo = JSON.parse(JSON.stringify(shipmentInvoiceInfo));
    newShipmentInvoiceInfo[e.target.id] = e.target.checked;
    shipmentInvoicesService.updateShipment(newShipmentInvoiceInfo);
    setShipmentInvoiceInfo(newShipmentInvoiceInfo);
  };

  const handleClose = () => {
    dispatch(openCreateEditShipmentInvoice());
    props.refresh(shipmentInvoiceStatusFilter.value, shipmentOptionsFilter.value);
  };

  const downloadInvoice = () => {
    if (shipmentInvoiceInfo.invoice_link !== '') saveAs(shipmentInvoiceInfo.invoice_link, `${shipmentInvoiceInfo.lookup_shipment_id !== '' ? shipmentInvoiceInfo.lookup_shipment_id : shipmentInvoiceInfo.shipment_name}.pdf`);
  };

  const markShipmentComplete = async () => {
    await shipmentInvoicesService.markShipmentComplete(shipmentInvoiceInfo);
    handleClose();
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="xl" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FaFileInvoiceDollar style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Shipment Detail
      </ModalHeader>
      <ModalBody className="create-edit-shipment-invoices">
        <Row>
          { shipmentInvoiceInfo &&
            <Col lg={12} style={{marginBottom: '10px', textAlign: 'right'}}>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" id="qbp" checked={shipmentInvoiceInfo.qbp} onChange={(e) => handleCheck(e)} />{' '}
                  QBP
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" id="jbi" checked={shipmentInvoiceInfo.jbi} onChange={(e) => handleCheck(e)} />{' '}
                  JBI
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" id="hlc" checked={shipmentInvoiceInfo.hlc} onChange={(e) => handleCheck(e)} />{' '}
                  HLC
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" id="tgc" checked={shipmentInvoiceInfo.tgc} onChange={(e) => handleCheck(e)} />{' '}
                  TGC
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" id="olympic" checked={shipmentInvoiceInfo.olympic} onChange={(e) => handleCheck(e)} />{' '}
                  Olympic
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" id="bti" checked={shipmentInvoiceInfo.bti} onChange={(e) => handleCheck(e)} />{' '}
                  BTI
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" id="mpn" checked={shipmentInvoiceInfo.mpn} onChange={(e) => handleCheck(e)} />{' '}
                  MPN
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" id="upc" checked={shipmentInvoiceInfo.upc} onChange={(e) => handleCheck(e)} />{' '}
                  UPC
                </Label>
              </FormGroup>
            </Col>
          }
        </Row>
        <Row>
          <Col lg={12}>
            <table>
              <tbody>
                <tr>
                  <td className="category-titles">Shipment Name:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(shipmentInvoiceInfo.shipment_name)}>
                    <span>{shipmentInvoiceInfo.shipment_name}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Shipment Status:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(shipmentInvoiceInfo.shipment_status)}>
                    <span>{shipmentInvoiceInfo.shipment_status}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Shipment TS:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(shipmentInvoiceInfo.shipment_ts)}>
                    <span>{shipmentInvoiceInfo.shipment_ts}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Updated TS:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(shipmentInvoiceInfo.updated_ts)}>
                    <span>{shipmentInvoiceInfo.updated_ts}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          { shipmentDetailsRows.length > 0 &&
            <Col lg={12} style={{marginLeft: '350px', marginTop: '40px'}}>
              <InvoiceUploader shipmentName={shipmentInvoiceInfo.shipment_name} refreshRecord={refreshRecord} shipmentDetailsRows={shipmentDetailsRows}/>
            </Col>
          }
          <Col lg={12} style={{marginTop: '40px'}}>
            { shipmentDetailsColumns &&
              <ToolkitProvider keyField="seller_sku" data={shipmentDetailsRows} columns={shipmentDetailsColumns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} condensed bordered={false} noDataIndication={() => (<div>There are currently no shipments</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" disabled={shipmentInvoiceInfo.invoices_uploaded === 1} style={{float: 'left', marginRight: '5px'}} color="outline-success" onClick={() => markShipmentComplete()}><FaRegCheckCircle style={{fontSize: '14px', marginBottom: '3px'}}/> Mark Complete</Button>
          <Button size="sm" disabled={shipmentInvoiceInfo.invoices_uploaded === 0} style={{float: 'left', marginRight: '5px'}} color="outline-primary" onClick={() => downloadInvoice()}><FaDownload style={{fontSize: '14px', marginBottom: '3px'}}/> Invoices</Button>
          <Button size="sm" style={{float: 'left', marginRight: '5px'}} color="outline-primary" onClick={() => shipmentInvoicesService.downloadPackingSlip(shipmentInvoiceInfo.shipment_name, shipmentInvoiceInfo.lookup_shipment_id)}><FaDownload style={{fontSize: '14px', marginBottom: '3px'}}/> Packing Slip</Button>
          <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default CreateEditShipmentInvoice;