import moment from 'moment';

export default class RemovalItem {
  constructor(data) {
    this.id = data && data.id ? data.id : 0;
    this.removal_id = data && data.removal_id ? data.removal_id : 0;
    this.lpn = data && data.lpn ? data.lpn : '';
    this.sku = data && data.sku ? data.sku : '';
    this.asin = data && data.asin ? data.asin : '';
    this.fnsku = data && data.fnsku ? data.fnsku : '';
    this.upc_ean = data && data.upc_ean ? data.upc_ean : '';
    this.product_description = data && data.product_description ? data.product_description : '';
    this.image = data && data.image ? data.image : '';
    this.brand = data && data.brand ? data.brand : '';
    this.cost = data && data.cost ? data.cost : 0;
    this.price = data && data.price ? data.price : 0;
    this.manufacturer_part_no = data && data.manufacturer_part_no ? data.manufacturer_part_no : '';
    this.tracking_number = data && data.tracking_number ? data.tracking_number : '';
    this.vendor = data && data.vendor ? data.vendor : '';
    this.vendor_name = data && data.vendor_name ? data.vendor_name : '';
    this.vendor_mpn = data && data.vendor_mpn ? data.vendor_mpn : '';
    this.not_found = data && data.not_found ? data.not_found : 0;
    this.qty = data && data.qty ? data.qty : 1;
    this.product_inspection = data && data.product_inspection ? data.product_inspection : '';
    this.created_ts = data && data.created_ts ?  moment(new Date(data.created_ts)).format('MM/DD/YY hh:mm:ss A') : '';
  } 
}