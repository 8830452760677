import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let removalsService = {};

removalsService.getRemovalPallets = async (startDate, endDate, status) => {
  try {
    const results = await axios.get(`${endpoints.api}/removals?start_date=${startDate}&end_date=${endDate}&status=${status}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

removalsService.palletSearch = async (palletName, palletType) => {
  try {
    const results = await axios.get(`${endpoints.api}/removals/pallet-search?pallet_name=${palletName}&pallet_type=${palletType}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

removalsService.createNewPallet = async (palletType, user) => {
  try {
    const results = await axios.post(`${endpoints.api}/removals`, {pallet_type: palletType, user}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

removalsService.productSearch = async (product) => {
  try {
    const results = await axios.get(`${endpoints.api}/removals/product-search?product=${product}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

removalsService.addToPallet = async (product, pallet, user) => {
  try {
    const results = await axios.post(`${endpoints.api}/removals/add-to-pallet`, {product, pallet, user}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

removalsService.updatePallet = async (pallet, updateType) => {
  try {
    const results = await axios.put(`${endpoints.api}/removals`, {pallet, update_type: updateType}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

// removalsService.archivePallet = async (pallet) => {
//   try {
//     const results = await axios.put(`${endpoints.api}/removals/archive`, pallet, { withCredentials: true });
//     return results.data;
//   } catch (e) {
//     console.log(e);
//     if (e.response.status == 403 || e.response.status == 401) {
//       window.location.href = '/';
//     }
//   }
// };

removalsService.batchDetail = async (id) => {
  try {
    const results = await axios.get(`${endpoints.api}/removals/batch-detail?id=${id}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

removalsService.removeProduct = async (id) => {
  try {
    const results = await axios.delete(`${endpoints.api}/removals/remove-product?id=${id}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default removalsService;