import { compose, createStore } from 'redux';
import rootReducer from '../reducers/index';

const storeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  storeEnhacers()
);

export default store;