import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let ordersService = {};

ordersService.getOrder = async (orderId) => {
  try {
    const results = await axios.get(`${endpoints.api}/orders/order?order_id=${orderId}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

ordersService.updateOrderNumber = async (order) => {
  try {
    const results = await axios.put(`${endpoints.api}/orders/order-number`, order, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

ordersService.updateDistributor = async (order) => {
  try {
    const results = await axios.put(`${endpoints.api}/orders/distributor`, order, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

ordersService.updateItemCost = async (orderId, item) => {
  try {
    const results = await axios.put(`${endpoints.api}/orders/item-cost`, {order_id: orderId, item}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

ordersService.clearError = async (order) => {
  try {
    const results = await axios.put(`${endpoints.api}/orders/clear-error`, order, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

ordersService.cancelOrder = async (order) => {
  try {
    const results = await axios.put(`${endpoints.api}/orders/cancel-order`, order, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

ordersService.udpateTracking = async (order) => {
  try {
    const results = await axios.post(`${endpoints.api}/orders/update-tracking`, order, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default ordersService;