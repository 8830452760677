import React, { useCallback, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import viewsService from '../../services/views.service';
import { TiExportOutline } from 'react-icons/ti';
import { FaSyncAlt } from 'react-icons/fa';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import Select from 'react-select';
import './Views.css';
import { useDispatch } from 'react-redux';
import { openOrderDetail } from '../../redux/actions';
import ErrorReportModel from '../../models/error_report.model';
import { BiErrorCircle } from 'react-icons/bi';

const { SearchBar } = Search;

const ErrorReport = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  // const viewOptions = useSelector(state => state.view_options);
  // const selectedView = useSelector(state => state.selected_view);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const results = await viewsService.errorReport();
      const newRows = results.map((r, i) => new ErrorReportModel(r, i));
      setRows(newRows);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'ID',
        dataField: 'order_id',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Dist',
        dataField: 'distributor',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Product',
        dataField: 'internal_product_id',
        style: {textAlign: 'center'},
        headerStyle: () => {
          return { width: '8%' };
        },
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Qty',
        dataField: 'qty',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Channel ID',
        dataField: 'channel_order_id',
        style: {textAlign: 'center'},
        headerStyle: () => {
          return { width: '10%' };
        },
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Customer',
        dataField: 'customer',
        headerStyle: {width: '230px'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Address1',
        dataField: 'street_line1',
        headerStyle: () => {
          return { width: '10%' };
        },
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Address2',
        dataField: 'street_line2',
        headerStyle: () => {
          return { width: '10%' };
        },
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'City',
        dataField: 'city',
        headerStyle: () => {
          return { width: '8%' };
        },
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'State',
        dataField: 'state_code',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Zip',
        dataField: 'zip',
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Order#',
        dataField: 'order_number',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'R',
        dataField: 'received',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'C',
        dataField: 'completed',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'S',
        dataField: 'shipped',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'T',
        dataField: 'seller_cloud_tracking',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      }
    ];

    setColumns(newColumns);
    getData();
    return () => getData();
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      dispatch(openOrderDetail(row.order_id));
    }
  };

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <BiErrorCircle className="text-danger" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Errors Orders</span>
                        </div>
                        {/* <Select className="dropdown" name="view_option" options={viewOptions} value={selectedView} onChange={(e) => dispatch(setSelectedView(e))}/> */}
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" color="outline-success" onClick={() => getData()}><FaSyncAlt style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} pagination={pagination} condensed bordered={false} rowClasses="selectable-row" rowEvents={rowEvents} filter={filterFactory()} noDataIndication={() => (<div>There are currently no order errors</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default ErrorReport;