import moment from 'moment';

export default class BackstockItem {
  constructor(data) {
    this.id = data && data.id ? data.id : 0;
    this.backstock_id = data && data.backstock_id ? data.backstock_id : 0;
    this.lpn = data && data.lpn ? data.lpn : '';
    this.sku = data && data.sku ? data.sku : '';
    this.asin = data && data.asin ? data.asin : '';
    this.fnsku = data && data.fnsku ? data.fnsku : '';
    this.upc_ean = data && data.upc_ean ? data.upc_ean : '';
    this.product_description = data && data.product_description ? data.product_description : '';
    this.prep_notes = data && data.prep_notes ? data.prep_notes : '';
    this.image = data && data.image ? data.image : '';
    this.brand = data && data.brand ? data.brand : '';
    this.cost = data && data.cost ? data.cost : 0;
    this.price = data && data.price ? data.price : 0;
    this.barcode_created = data && data.barcode_created ? data.barcode_created : 0;
    this.barcode_printed = data && data.barcode_printed ? data.barcode_printed : 0;
    this.manufacturer_part_no = data && data.manufacturer_part_no ? data.manufacturer_part_no : '';
    this.po_name = data && data.po_name ? data.po_name : '';
    this.shelf_location = data && data.shelf_location ? data.shelf_location : '';
    this.case_qty = data && data.case_qty ? data.case_qty : '';
    this.num_cases = data && data.num_cases ? data.num_cases : '';
    this.case_weight = data && data.case_weight ? data.case_weight : '';
    this.case_length = data && data.case_length ? data.case_length : '';
    this.case_width = data && data.case_width ? data.case_width : '';
    this.case_height = data && data.case_height ? data.case_height : '';
    this.notes = data && data.notes ? data.notes : '';
    this.status = data && data.status ? data.status : '';
    this.active = data && data.active ? data.active : 0;
    this.total_amazon_inventory = data && data.total_amazon_inventory ? data.total_amazon_inventory : 0;
    this.qoh = data && data.qoh ? data.qoh : 0;
    this.demand = data && data.demand ? data.demand : 0;
    this.overweight = data && data.overweight ? data.overweight : 0;
    this.qty_to_move = data && data.qty_to_move ? data.qty_to_move : 0;
    this.thirty_day_total_sales = data && data.thirty_day_total_sales ? data.thirty_day_total_sales : 0;
    this.one_hundred_eighty_day_total_sales = data && data.one_hundred_eighty_day_total_sales ? data.one_hundred_eighty_day_total_sales : 0;
    this.label_url = data && data.label_url ? data.label_url : '';
    this.label_printed = data && data.label_printed ? data.label_printed : 0;
    this.backstock_group_ids = data && data.backstock_group_ids ? data.backstock_group_ids.split(',').map(Number) : [];
    this.updated_ts = data && data.updated_ts ? moment(new Date(data.updated_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.created_ts = data && data.created_ts ? moment(new Date(data.created_ts)).format('MM/DD/YY hh:mm:ss A') : '';
  }
}