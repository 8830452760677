import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import endpoints from '../../endpoints/endpoints';
import axios from 'axios';

const InvoiceUploader = (props) => {
  const onDrop = useCallback(async acceptedFiles => {
    // create a FormData instance
    const formData = new FormData();

    // append the files to the FormData instance
    acceptedFiles.forEach(file => {
      formData.append('files', file);
    });

    // append additional data to formData
    formData.append('shipment_name', props.shipmentName);
    if (props.bulk) {
      formData.append('qbp', props.shipmentInvoiceInfo.qbp ? 1 : 0);
      formData.append('jbi', props.shipmentInvoiceInfo.jbi ? 1 : 0);
      formData.append('hlc', props.shipmentInvoiceInfo.hlc ? 1 : 0);
      formData.append('tgc', props.shipmentInvoiceInfo.tgc ? 1 : 0);
      formData.append('olympic', props.shipmentInvoiceInfo.olympic ? 1 : 0);
      formData.append('bti', props.shipmentInvoiceInfo.bti ? 1 : 0);
      formData.append('mpn', props.shipmentInvoiceInfo.mpn ? 1 : 0);
      formData.append('upc', props.shipmentInvoiceInfo.upc ? 1 : 0);
    }

    // send a POST request with the files
    if (props.bulk) {
      props.setUploading(true);
      await axios.post(`${endpoints.api}/shipment-invoices/upload-bulk-invoices`, formData);
      props.setUploading(false);
    } else {
      await axios.post(`${endpoints.api}/shipment-invoices/upload-invoices`, formData);
      props.refreshRecord(props.shipmentDetailsRows[0].shipment_id);
    }
  }, [props.shipmentInvoiceInfo]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true });

  const dropzoneStyle = {
    border: '1px solid lightgray',
    borderRadius: '1px',
    paddingTop: '10px',
    width: '400px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor:'pointer'
  };

  return (
    <div {...getRootProps()} style={dropzoneStyle}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag & Drop Invoice Files Here</p>
      }
    </div>
  );
};

export default InvoiceUploader;