import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let shipmentInvoicesService = {};

shipmentInvoicesService.getShipments = async (startDate, endDate, status, shipmentOption, user) => {
  try {
    const results = await axios.get(`${endpoints.api}/shipment-invoices?start_date=${startDate}&end_date=${endDate}&status=${status}&shipment_option=${shipmentOption}&admin=${user.admin}&user_id=${user.id}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

shipmentInvoicesService.getShipmentDetails = async (shipmentName) => {
  try {
    const results = await axios.get(`${endpoints.api}/shipment-invoices/shipment-details?shipment_name=${shipmentName}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

shipmentInvoicesService.updateShipment = async (shipmentInfo) => {
  try {
    await axios.put(`${endpoints.api}/shipment-invoices`, shipmentInfo, { withCredentials: true });
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

shipmentInvoicesService.markShipmentComplete = async (shipmentInfo) => {
  try {
    await axios.put(`${endpoints.api}/shipment-invoices/mark-complete`, shipmentInfo, { withCredentials: true });
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

shipmentInvoicesService.shipmentLookup = async (shipmentId) => {
  try {
    const results = await axios.get(`${endpoints.api}/shipment-invoices/shipment-lookup?shipment_id=${shipmentId}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

shipmentInvoicesService.downloadPackingSlip = async (shipmentName, lookupShipmentId) => {
  try {
    const results = await axios.get(`${endpoints.api}/shipment-invoices/packing-slip?shipment_name=${shipmentName}&lookup_shipment_id=${lookupShipmentId}`, { withCredentials: true, responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([results.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${lookupShipmentId !== '' ? `${lookupShipmentId}_packing_slip` : shipmentName}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default shipmentInvoicesService;