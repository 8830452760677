import moment from 'moment';

export default class ErrorModel {
  constructor(data) {
    this.id = data.id;
    this.service = data.service;
    this.module = data.module;
    this.error_output = data.error_output;
    this.created_ts = moment(new Date(data.created_ts)).format('MM/DD/YY hh:mm:ss A');
  } 
}