import React, { useCallback, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import viewsService from '../../services/views.service';
import { TiExportOutline } from 'react-icons/ti';
import { FaSyncAlt } from 'react-icons/fa';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import Select from 'react-select';
import './Views.css';
import { BiErrorCircle } from 'react-icons/bi';
import FeedvisorErrorsModel from '../../models/feedvisor_errors_report.model';

const { SearchBar } = Search;

const FeedvisorErrorReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const pagination = pagionationFactory({
    sizePerPage: 33,
    hideSizePerPage: true
  });

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const results = await viewsService.feedvisorErrors();
      const newRows = results.map((r, i) => new FeedvisorErrorsModel(r, i));
      setRows(newRows);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'ID',
        dataField: 'id',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Vendor',
        dataField: 'vendor_name',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Brand',
        dataField: 'brand',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Sku',
        dataField: 'sku',
        style: {textAlign: 'center'},
        headerStyle: {width: '130px'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'UPC',
        dataField: 'upc_ean',
        style: {textAlign: 'center'},
        headerStyle: {width: '130px'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Prep',
        dataField: 'prep',
        style: {textAlign: 'right'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Price',
        dataField: 'price',
        style: {textAlign: 'right'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Reprice',
        dataField: 'reprice',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'ASIN',
        dataField: 'asin',
        style: {textAlign: 'center'},
        headerStyle: {width: '100px'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Description',
        dataField: 'description',
        style: {textAlign: 'left'},
        sort: true,
        filter: textFilter({className: 'filter-box'}),
        headerStyle: {width: '300px'}
      },
      {
        text: 'Cost',
        dataField: 'cost',
        style: {textAlign: 'right'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Map',
        dataField: 'map',
        style: {textAlign: 'right'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Special Price',
        dataField: 'special_price',
        style: {textAlign: 'right'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Do Not Load',
        dataField: 'do_not_load',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Order Exclude',
        dataField: 'order_exclude',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Discontinued',
        dataField: 'discontinued',
        style: {textAlign: 'center'},
        headerStyle: {width: '100px'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Prep Required',
        dataField: 'prep_required',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Min Stock',
        dataField: 'min_stock',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Floor Price',
        dataField: 'floor_price',
        style: {textAlign: 'right'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Ceiling Price',
        dataField: 'ceiling_price',
        style: {textAlign: 'right'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Feedvisor Updated TS',
        dataField: 'feedvisor_updated_ts',
        style: {textAlign: 'center'},
        headerStyle: {width: '130px'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Error',
        dataField: 'error_response',
        style: {textAlign: 'left'},
        sort: true,
        filter: textFilter({className: 'filter-box'}),
        headerStyle: {width: '400px'}
      }
    ];

    setColumns(newColumns);
    getData();
    return () => getData();
  }, []);

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <BiErrorCircle className="text-danger" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Feedvisor Errors</span>
                        </div>
                        {/* <Select className="dropdown" name="view_option" options={viewOptions} value={selectedView} onChange={(e) => dispatch(setSelectedView(e))}/> */}
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" color="outline-success" onClick={() => getData()}><FaSyncAlt style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} pagination={pagination} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no feedvisor errors</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default FeedvisorErrorReport;