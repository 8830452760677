import moment from 'moment';

export default class UnshippedReportModel {
  constructor(data, index) {
    this.id = index;
    this.order_id = data.order_id;
    this.distributor = data.distributor.toUpperCase();
    this.channel_order_id = data.channel_order_id;
    this.order_number = data.order_number;
    this.completed_ts = data.completed_ts ? moment(new Date(data.completed_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.shipped_ts = data.shipped_ts ? moment(new Date(data.shipped_ts)).format('MM/DD/YY hh:mm:ss A') : '';
  } 
}