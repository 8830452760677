import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let backstockService = {};

backstockService.productSearch = async (product) => {
  try {
    const results = await axios.get(`${endpoints.api}/backstock/product-search?product=${product}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

backstockService.getBackstock = async (status) => {
  try {
    const results = await axios.get(`${endpoints.api}/backstock?status=${status}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

backstockService.createBackstock = async (product) => {
  try {
    const results = await axios.post(`${endpoints.api}/backstock`, {product}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

backstockService.updateBackstock = async (product, updateType) => {
  try {
    const results = await axios.put(`${endpoints.api}/backstock`, {product, update_type: updateType}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

backstockService.submitBackstockInboundShipmentSingle = async (shipmentItems) => {
  try {
    const results = await axios.post(`${endpoints.api}/backstock/backstock-inbound-shipment-single`, {shipment_items: shipmentItems}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default backstockService;