import moment from 'moment';

export default class ReturnModel {
  constructor(data) {
    this.id = data && data.id ? data.id : 0;
    this.sku = data && data.sku ? data.sku : '';
    this.asin = data && data.asin ? data.asin : '';
    this.fnsku = data && data.fnsku ? data.fnsku : '';
    this.order_id = data && data.order_id ? data.order_id : '';
    this.return_date = data && data.return_date ? moment(new Date(data.return_date)).format('MM/DD/YY hh:mm:ss A') : '';
    this.upc_ean = data && data.upc_ean ? data.upc_ean : '';
    this.actual_upc_ean = data && data.actual_upc_ean ? data.actual_upc_ean : '';
    this.product_description = data && data.product_description ? data.product_description : '';
    this.image = data && data.image ? data.image : '';
    this.blacklist_brand = data && data.blacklist_brand ? data.blacklist_brand : 0;
    this.blacklist_upc = data && data.blacklist_upc ? data.blacklist_upc : 0;
    this.brand = data && data.brand ? data.brand : '';
    this.cost = data && data.cost ? data.cost : 0;
    this.price = data && data.price ? data.price : 0;
    this.customer_comments = data && data.customer_comments ? data.customer_comments : '';
    this.liquidation_product = data && data.liquidation_product ? data.liquidation_product : 0;
    this.lpn = data && data.lpn ? data.lpn : '';
    this.manufacturer_part_no = data && data.manufacturer_part_no ? data.manufacturer_part_no : '';
    this.reason = data && data.reason ? data.reason : '';
    this.removal_id = data && data.removal_id ? data.removal_id : '';
    this.tracking_number = data && data.tracking_number ? data.tracking_number : '';
    this.shipment_id = data && data.shipment_id ? data.shipment_id : '';
    this.vendor = data && data.vendor ? data.vendor : '';
    this.vendor_name = data && data.vendor_name ? data.vendor_name : '';
    this.vendor_mpn = data && data.vendor_mpn ? data.vendor_mpn : '';
    this.created_ts = data && data.created_ts ? moment(new Date(data.created_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.product_inspection = data && data.product_inspection ? data.product_inspection : '';
    this.packaging_inspection = data && data.packaging_inspection ? data.packaging_inspection : '';
    this.label_packing_slip_image = data && data.label_packing_slip_image ? data.label_packing_slip_image : '';
    this.lpn_upc_image = data && data.lpn_upc_image ? data.lpn_upc_image : '';
    this.issue_image_1 = data && data.issue_image_1 ? data.issue_image_1 : '';
    this.issue_image_2 = data && data.issue_image_2 ? data.issue_image_2 : '';
    this.issue_image_3 = data && data.issue_image_3 ? data.issue_image_3 : '';
    this.issue_image_4 = data && data.issue_image_4 ? data.issue_image_4 : '';
    this.complete = data && data.complete ? data.complete : 0;
    this.not_found = data && data.not_found ? data.not_found : 0;
    this.fraud_missing = data && data.fraud_missing ? data.fraud_missing : 0;
    this.single_upc_ean = data && data.single_upc_ean ? data.single_upc_ean : 0;
    this.case_id = data && data.case_id ? data.case_id : '';
    this.reimbursement_id = data && data.reimbursement_id ? data.reimbursement_id : '';
    this.issue_type = data && data.issue_type ? data.issue_type : '';
  } 
}