export default class ReturnTrackingActivity {
  constructor(data) {
    this.id = data && data.id ? data.id : 0;
    this.tracking_number = data && data.tracking_number ? data.tracking_number : '';
    this.request_ts = data && data.request_ts ? data.request_ts : '';
    this.ninety_day_ts = data && data.ninety_day_ts ? data.ninety_day_ts : '';
    this.activity_ts = data && data.activity_ts ? data.activity_ts : '';
    this.city = data && data.city ? data.city : '';
    this.state = data && data.state ? data.state : '';
    this.zip = data && data.zip ? data.zip : '';
    this.country = data && data.country ? data.country : '';
    this.status_type = data && data.status_type ? data.status_type : '';
    this.status_code = data && data.status_code ? data.status_code : '';
    this.status = data && data.status ? data.status : '';
    this.internal_status = data && data.internal_status ? data.internal_status : '';
    this.days_old = data && data.days_old ? data.days_old : 0;
    this.age = data && data.age ? data.age : 0;
    this.shipment_id = data && data.shipment_id ? data.shipment_id : '';
    this.case_id = data && data.case_id ? data.case_id : '';
    this.reimbursement_id = data && data.reimbursement_id ? data.reimbursement_id : '';
    this.image_link = data && data.image_link ? data.image_link : '';
    this.complete = data && data.complete ? data.complete : 0;
  } 
}