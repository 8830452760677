export const DEFINE_USER = 'DEFINE_USER';
export const OPEN_PRODUCT_LOOKUP = 'OPEN_PRODUCT_LOOKUP';
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const SET_VIEW_OPTIONS = 'SET_VIEW_OPTIONS';
export const SET_TITAN_VIEW_OPTIONS = 'SET_TITAN_VIEW_OPTIONS';
export const SET_ADMIN_VIEW_OPTIONS = 'SET_ADMIN_VIEW_OPTIONS';
export const SET_SHIPPING_VENDOR_OPTIONS = 'SET_SHIPPING_VENDOR_OPTIONS';
export const SET_PRICE_TRACKER_VENDOR_OPTIONS = 'SET_PRICE_TRACKER_VENDOR_OPTIONS';
export const SET_PRICE_TRACKER_BRAND_OPTIONS = 'SET_PRICE_TRACKER_BRAND_OPTIONS';
export const SET_PRICE_TRACKER_BRAND_ALL_OPTIONS = 'SET_PRICE_TRACKER_BRAND_ALL_OPTIONS';
export const OPEN_ORDER_DETAIL = 'OPEN_ORDER_DETAIL';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_MOBILE = 'SET_MOBILE';
export const SIDEBAR_COLLAPSE = 'SIDEBAR_COLLAPSE';
export const OPEN_RETURN_TRACKING_HISTORY = 'OPEN_RETURN_TRACKING_HISTORY';
export const OPEN_REIMBURSEMENT_DETAIL = 'OPEN_REIMBURSEMENT_DETAIL';
export const OPEN_RETURN_TRACKING_IMAGE_UPLOAD = 'OPEN_RETURN_TRACKING_IMAGE_UPLOAD';
export const OPEN_CREATE_EDIT_RETURN = 'OPEN_CREATE_EDIT_RETURN';
export const OPEN_IMAGE_CAPTURE = 'OPEN_IMAGE_CAPTURE';
export const OPEN_RETURN_REIMBURSEMENT_DETAIL = 'OPEN_RETURN_REIMBURSEMENT_DETAIL';
export const SET_RETURN_STATUS_FILTER = 'SET_RETURN_STATUS_FILTER';
export const SET_RETURN_INSPECTION_FILTER = 'SET_RETURN_INSPECTION_FILTER';
export const SET_REMOVALS_STATUS_FILTER = 'SET_REMOVALS_STATUS_FILTER';
export const OPEN_CREATE_EDIT_REMOVAL = 'OPEN_CREATE_EDIT_REMOVAL';
export const OPEN_REMOVALS_BATCH_DETAIL = 'OPEN_REMOVALS_BATCH_DETAIL';
export const OPEN_CREATE_EDIT_SINGLE_SKU_SHIPMENT = 'OPEN_CREATE_EDIT_SINGLE_SKU_SHIPMENT';
export const SET_SINGLE_SKU_SHIPMENTS_STATUS_FILTER = 'SET_SINGLE_SKU_SHIPMENTS_STATUS_FILTER';
export const SET_SHIPMENT_INVOICES_STATUS_FILTER = 'SET_SHIPMENT_INVOICES_STATUS_FILTER';
export const SET_SHIPMENT_OPTIONS_FILTER = 'SET_SHIPMENT_OPTIONS_FILTER';
export const OPEN_CREATE_EDIT_SHIPMENT_INVOICE = 'OPEN_CREATE_EDIT_SHIPMENT_INVOICE';
export const OPEN_BULK_SHIPMENT_UPLOAD = 'OPEN_BULK_SHIPMENT_UPLOAD';
export const SET_PRICE_TRACKER_VENDOR = 'SET_PRICE_TRACKER_VENDOR';
export const SET_PRICE_TRACKER_BRAND = 'SET_PRICE_TRACKER_BRAND';
export const OPEN_VENDOR_DETAIL = 'OPEN_VENDOR_DETAIL';
export const OPEN_PRICE_TRACKER_PRODUCT_DETAIL = 'OPEN_PRICE_TRACKER_PRODUCT_DETAIL';
export const SET_CATEGORY_OPTIONS_FILTER = 'SET_CATEGORY_OPTIONS_FILTER';
export const OPEN_CREATE_EDIT_DIMS = 'OPEN_CREATE_EDIT_DIMS';
export const OPEN_CREATE_EDIT_SINGLE = 'OPEN_CREATE_EDIT_SINGLE';
export const OPEN_CREATE_EDIT_BATCH = 'OPEN_CREATE_EDIT_BATCH';
export const OPEN_CREATE_EDIT_CUSTOMER = 'OPEN_CREATE_EDIT_CUSTOMER';
export const OPEN_CREATE_EDIT_BACKSTOCK = 'OPEN_CREATE_EDIT_BACKSTOCK';
export const SET_BACKSTOCK_OPTIONS_FILTER = 'SET_BACKSTOCK_OPTIONS_FILTER';
export const OPEN_CREATE_EDIT_BACKSTOCK_SINGLE = 'OPEN_CREATE_EDIT_BACKSTOCK_SINGLE';
