import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let optionsService = {};

optionsService.getOptions = async (userId) => {
  try {
    const results = await axios.get(`${endpoints.api}/options?user_id=${userId}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default optionsService;