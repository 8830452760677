import React from 'react';
import moment from 'moment';

export default class RecentProcessModel {
  constructor(data) {
    this.id = data.id;
    this.service_name = data.service_name;
    this.process_url = data.process_url;
    this.process_job = data.process_job;
    this.process_batch = data.process_batch;
    if (data.processing === 1) {
      this.processing = 'Processing';
    } else if (data.complete === 1) {
      this.processing = 'Complete';
    } else if (data.failed === 1) {
      this.processing = 'Failed';
    }  else {
      this.processing = 'Queued';
    }
    this.status = () => {
      if (data.processing === 1) {
        return <div className="text-warning">Processing</div>;
      } else if (data.complete === 1) {
        return <div className="text-success">Complete</div>;
      } else if (data.failed === 1) {
        return <div className="text-danger">Failed</div>;
      }  else {
        return <div className="text-primary">Queued</div>;
      }
    };
    this.response = data.response;
    this.job_received_ts = moment(new Date(data.job_received_ts)).format('MM/DD/YY hh:mm:ss A');
    this.job_complete_ts = data.job_complete_ts && data.job_complete_ts !== '' ? moment(new Date(data.job_complete_ts)).format('MM/DD/YY hh:mm:ss A') : '';
  } 
}