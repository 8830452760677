import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TiExportOutline } from 'react-icons/ti';
import { FaRegCheckCircle, FaSyncAlt, FaRegEdit } from 'react-icons/fa';
import { FiTruck } from 'react-icons/fi';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Select from 'react-select';
import returnTrackingService from '../../services/return_tracking.service';
import ReturnTrackingActivity from '../../models/return_tracking_activity.model';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { openReturnTrackingHistory } from '../../redux/actions';
import moment from 'moment';
import ReturnTrackingHistory from './ReturnTrackingHistory';
import './ReturnTracking.css';

const { SearchBar } = Search;

const ReturnTracking = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [statusOptions] = useState([
    { value: 'all', label: 'All Statuses' },
    { value: 'complete', label: 'Complete' },
    { value: 'created', label: 'Created' },
    { value: 'delivered', label: 'Delivered' },
    { value: 'transit', label: 'In Transit' }
  ]);
  const [selectedStatus, setSelectedStatus] = useState({ value: 'all', label: 'All Statuses' });
  const [startDate, setStartDate] = useState(new Date(moment().subtract(5, 'months').startOf('month')));
  const [endDate, setEndDate] = useState(new Date());
  const openReturnTrackingHistoryToggle = useSelector(state => state.open_return_tracking_history);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = async () => {
    try {
      const results = await returnTrackingService.getReturnTracking(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), selectedStatus);
      const newRows = results.map((r, i) => new ReturnTrackingActivity(r, i));
      setRows(newRows);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'Tracking Number',
        dataField: 'tracking_number',
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Request TS',
        dataField: 'request_ts',
        style: {textAlign: 'center'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Activity TS',
        dataField: 'activity_ts',
        style: {textAlign: 'center'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: '90 Day TS',
        dataField: 'ninety_day_ts',
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Age',
        dataField: 'age',
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'City',
        dataField: 'city',
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'State',
        dataField: 'state',
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Carrier Status',
        dataField: 'status',
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'}),
      },
      {
        text: 'Current Status',
        dataField: 'internal_status',
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.internal_status === 'delivered' || row.complete === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          } else if (row.internal_status === 'transit') {
            return <FiTruck style={{fontSize: '18px', marginBottom: '2px', color: 'orange'}}/>;
          } else {
            return <FaRegEdit style={{fontSize: '18px', marginBottom: '2px', color: 'blue'}}/>;
          }

        }
      },
      {
        text: 'Shipment ID',
        dataField: 'shipment_id',
        headerStyle: {width: '110px'},
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openReturnTrackingHistory(row.tracking_number))
        },
        filter: textFilter({className: 'filter-box'}),
      },
      {
        text: 'Case ID',
        dataField: 'case_id',
        headerStyle: {width: '105px'},
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://sellercentral.amazon.com/cu/case-dashboard/view-case?caseID=${row.case_id}`, '_blank')
        },
        filter: textFilter({className: 'filter-box'}),
      },
      {
        text: 'Reimbursement ID',
        dataField: 'reimbursement_id',
        headerStyle: {width: '145px'},
        style: {textAlign: 'center'},
        sort: true,
        events: {
          onClick: () => window.open(`https://sellercentral.amazon.com/reportcentral/REIMBURSEMENTS/0`, '_blank')
        },
        filter: textFilter({className: 'filter-box'}),
      }
    ];

    setColumns(newColumns);
  }, [rows, setColumns]);

  useEffect(async () => {
    setIsLoading(true);
    getData();
  }, [selectedStatus]);

  const handleStatusChange = (e) => {
    try {
      setSelectedStatus(e);
    } catch (e) {
      console.log(e);
    }
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 34,
      minHeight: 34,
    }),
    singleValue: (provided) => {
      return { ...provided, paddingBottom: '5px' };
    }
  };

  const defaultSort = [{
    dataField: 'age',
    order: 'desc'
  }];

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        { openReturnTrackingHistoryToggle &&
          <ReturnTrackingHistory isOpen={openReturnTrackingHistoryToggle} refresh={getData} />
        }
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <FiTruck className="text-primary" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Return Tracking</span>
                        </div>
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" color="outline-success" onClick={() => getData()}><FaSyncAlt style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font" 
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font"
                          style={{ fontSize: '13px' }}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '150px'}} >
                        <Select height={10} className="dropdown" styles={customStyles} name="view_option" options={statusOptions} value={selectedStatus} onChange={(e) => handleStatusChange(e)}/>
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} defaultSorted={defaultSort} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no return shipments</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default ReturnTracking;