import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FaAmazon, FaRegTimesCircle } from 'react-icons/fa';
import { openCreateEditDims } from '../../redux/actions';
import inboundShipmentsService from '../../services/inbound_shipments.service';
import { Store } from 'react-notifications-component';
import { PulseLoader } from 'react-spinners';

const { SearchBar } = Search;

const CreateEditDimsModal = (props) => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.open_create_edit_dims_info);
  const [rows, setRows] = useState(info);
  const [columns, setColumns] = useState(info);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const populateShipmentIDs = () => {
    const newRows = JSON.parse(JSON.stringify(rows));
    newRows.forEach(row => {
      row.shipment_id = row.package_reference_no_4;
    });

    setRows(newRows);
  };

  useEffect(() => {
    populateShipmentIDs();
  }, []);

  useEffect(() => {
    const newColumns = [
      {
        text: 'Shipment ID',
        dataField: 'shipment_id',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Prep Notes',
        dataField: 'prep_notes',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Delivery Date',
        dataField: 'date_delivered',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        editable: false
      },
      {
        text: 'Weight',
        dataField: 'package_weight',
        sort: true,
        style: (cell, row) => {
          if (row.package_weight > 48) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_weight}</span>;
        }
      },
      {
        text: 'L',
        dataField: 'package_length',
        sort: true,
        style: (cell, row) => {
          if (row.package_length > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_length}</span>;
        }
      },
      {
        text: 'W',
        dataField: 'package_width',
        sort: true,
        style: (cell, row) => {
          if (row.package_width > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_width}</span>;
        }
      },
      {
        text: 'H',
        dataField: 'package_height',
        sort: true,
        style: (cell, row) => {
          if (row.package_height > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_height}</span>;
        }
      },
      {
        text: 'OWT',
        dataField: 'overweight',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        editable: false,
        formatter: (cell, row) => {
          if (row.overweight === 1) {
            return <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
          }
        }
      },
      {
        text: 'Inbound Tracking Number',
        dataField: 'inbound_tracking_number',
        headerStyle: {width: '140px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        editable: false
      },
      {
        text: 'Outbound Tracking Number',
        dataField: 'outbound_tracking_number',
        headerStyle: {width: '140px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        editable: false
      },
    ];

    setColumns(newColumns);
  }, [rows, setColumns]);

  const afterSaveCell = (oldValue, newValue, row, column) => {
    newValue = newValue && column.dataField === 'package_length' || column.dataField === 'package_width' || column.dataField === 'package_height' || column.dataField === 'qty_to_move' || column.dataField === 'package_weight' || column.dataField === 'quantity' ? parseFloat(newValue) : newValue;
    setRows(currentRows => {
      const rowIndex = currentRows.findIndex(r => r.inbound_tracking_number === row.inbound_tracking_number);
      if (rowIndex === -1) return currentRows; // Row not found, no update needed.
  
      let newRow;

      if (column.dataField === 'package_weight') {
        newRow = {
          ...currentRows[rowIndex],
          [column.dataField]: newValue,
          overweight: newValue > 48 ? 1 : 0 // Assuming this logic is correct.
        };
      } else if (column.dataField === 'package_length' || column.dataField === 'package_width' || column.dataField === 'package_height' || column.dataField === 'shipment_id' || column.dataField === 'prep_notes') {
        newRow = {
          ...currentRows[rowIndex],
          [column.dataField]: newValue
        };
      }

      // Create a new array with the updated row.
      return [
        ...currentRows.slice(0, rowIndex),
        newRow,
        ...currentRows.slice(rowIndex + 1)
      ];
    });
  };

  const cellEdit = cellEditFactory({
    mode: 'click',
    afterSaveCell: afterSaveCell,
    blurToSave: true
  });

  const handleClose = () => {
    dispatch(openCreateEditDims());
    props.refresh();
  };

  const validateRows = () => {
    // Check each row for required fields
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      // Check if any of the required fields are missing or invalid (e.g., not filled in)
      if (!row.shipment_id || row.package_weight <= 0 || row.package_length <= 0 || row.package_width <= 0 || row.package_height <= 0) {
        // If any field is missing, return an object indicating failure and the row index
        return { valid: false, rowIndex: i + 1 }; // Adding 1 to make it human-readable (counting starts at 1)
      }
    }
    // If all rows are valid, return an object indicating success
    return { valid: true };
  };

  const handleSubmit = async () => {
    const validation = validateRows();
    if (!validation.valid) {
      Store.addNotification({
        title: 'Dims Submission Error',
        message: 'Shipment ID, Weight, Length, Width, and Height are required to submit.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: false
        }
      });
      return; // Stop the submission since validation failed
    }

    setIsSubmitting(true); // Start loading

    try {
      setTimeout(() => {
        inboundShipmentsService.submitInboundShipmentDims(rows);
        Store.addNotification({
          title: 'Dims Submission',
          message: 'Inbound shipment dims submitted successfully.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        setIsSubmitting(false); // Stop loading
        props.clearSelectedRows(); // Assuming you have a function to clear selected rows
        handleClose(); // Close the modal
      }, 1500);
    } catch (error) {
      console.error('Failed to submit inbound shipment dims:', error);
      setIsSubmitting(false); // Stop loading
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="xl" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FaAmazon style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Inbound Shipments Dims
      </ModalHeader>
      <ModalBody className="create-edit-shipment-invoices">
        <Row>
          <Col lg={12}>
            {isSubmitting ? (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <PulseLoader sizeUnit={'px'} size={10} color={'#123abc'} loading={isSubmitting} />
              </div>
            ) : (
              columns && (
                <ToolkitProvider keyField="inbound_tracking_number" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                  {
                    props => (
                      <div>
                        <div style = {{ float: 'right', width: '200px' }}>
                          <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                        </div>
                        <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" condensed bordered={false} cellEdit={cellEdit} noDataIndication={() => (<div>There are currently no shipments</div>)}/>
                      </div>
                    )
                  }
                </ToolkitProvider>
              )
            )}
          </Col>
        </Row>
      </ModalBody>
      { !isSubmitting &&
        <ModalFooter>
          <Col lg={12} style={{width: '100%'}}>
            <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
            <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleSubmit()}>Submit</Button>
          </Col>
        </ModalFooter>
      }

    </Modal>
  );
};

export default CreateEditDimsModal;