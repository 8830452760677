import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import './PriceTracker.css';
import { openVendorDetail } from '../../redux/actions';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import priceTrackerService from '../../services/price_tracker.service';
import PriceTrackerProductModel from '../../models/price_tracker_product.model';
import { TiExportOutline } from 'react-icons/ti';
import { FaDownload, FaRegCheckCircle } from 'react-icons/fa';

const { SearchBar } = Search;

const VendorDetail = (props) => {
  const dispatch = useDispatch();
  const vendorInfo = useSelector(state => state.open_vendor_detail_info);
  const selectedVendor = useSelector(state => state.price_tracker_vendor);
  const selectedBrand = useSelector(state => state.price_tracker_brand);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [authorized, setAuthorized] = useState(vendorInfo.is_authorized ? true : false);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });
  
  const getData = async () => {
    const results = await priceTrackerService.violationDetail(selectedVendor?.value, selectedBrand?.value, vendorInfo?.seller_id);
    const newRows = results.map((r, i) => new PriceTrackerProductModel(r, i));
    setRows(newRows);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const newColumns = [
      {
        text: 'Sku',
        dataField: 'part_no',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        headerStyle: {width: '80px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'UPC/EAN',
        dataField: 'upc_ean',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '80px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'ASIN',
        dataField: 'asin',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        headerStyle: {width: '80px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Brand',
        dataField: 'brand',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '80px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Description',
        dataField: 'description',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        headerStyle: {width: '250px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'FBA',
        dataField: 'is_fulfilled_by_amazon',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '40px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.is_fulfilled_by_amazon === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'Prime',
        dataField: 'is_prime',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '40px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.is_prime === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'Listing Price',
        dataField: 'listing_price_amount',
        style: {textAlign: 'right', verticalAlign: 'middle'},
        headerStyle: {width: '55px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'MAP',
        dataField: 'map',
        style: {textAlign: 'right', verticalAlign: 'middle'},
        headerStyle: {width: '55px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Variance',
        dataField: 'variance',
        style: {textAlign: 'right', verticalAlign: 'middle'},
        headerStyle: {width: '55px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/dp/${row.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      }
    ];

    setColumns(newColumns);
  }, [rows, setColumns]);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  const handleAuthorize = async (e) => {
    try {
      const checked = e.target.checked;
      setAuthorized(checked);
      await priceTrackerService.authorizeSeller(selectedVendor?.value, vendorInfo?.seller_id, checked);
      props.refresh(selectedVendor.value, selectedBrand.value);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => dispatch(openVendorDetail())} backdrop="static" size="xl">
      <ModalHeader toggle={() => dispatch(openVendorDetail())}>
        Vendor Detail
      </ModalHeader>
      <ModalBody className="vendor-detail">
        <Row>
          <Col lg={8}>
            <table>
              <tbody>
                <tr>
                  <td className="category-titles">Seller Name:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(vendorInfo.seller_name)}>
                    <span>{vendorInfo.seller_name}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Seller ID:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(vendorInfo.seller_id)}>
                    <span>{vendorInfo.seller_id}</span>
                    <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open(`https://www.amazon.com/sp?seller=${vendorInfo.seller_id}`, '_blank')} /></span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Address: </td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(vendorInfo.address)}>
                    <span>{vendorInfo.address}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">City:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(vendorInfo.city)}>
                    <span>{vendorInfo.city}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">State:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(vendorInfo.state)}>
                    <span>{vendorInfo.state}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Zip:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(vendorInfo.zip)}>
                    <span>{vendorInfo.zip}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Country:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(vendorInfo.country)}>
                    <span>{vendorInfo.country}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Last Updated:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(vendorInfo.created_ts)}>
                    <span>{vendorInfo.created_ts}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Active Listings:</td>
                  <td className="category-text pointer" onClick={() => priceTrackerService.activeListingList(selectedVendor.value, selectedBrand.value, vendorInfo.seller_id, vendorInfo.seller_name)}>
                    <span>{vendorInfo.asin_cnt} <FaDownload className='active-listing-download' style={{marginBottom: '2px', fontSize: '15px'}} /> </span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Authorized:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(vendorInfo.authorized)}>
                    <input style={{marginTop: '4px'}} type="checkbox" checked={authorized} onChange={(e) => handleAuthorize(e)} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="section-header" style={{marginTop: '20px'}}> Map Violations</div>
            <hr style={{marginTop: '5px'}}/>
          </Col>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no removals</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button size="sm" color="outline-secondary" onClick={() => dispatch(openVendorDetail())}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default VendorDetail;