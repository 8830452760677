import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';
import authService from '../../services/auth.service';
import endpoints from '../../endpoints/endpoints';
import './Login.css';

export default function OneTimePassword({ enabled }) {
  const [verificationCode, setVerificationCode] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);
  const user = useSelector(state => state.user);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const result = await authService.verifyOtp(verificationCode, user);

      if (result) return (window.location = '/');

      setInvalidCode(true);
    },
    [verificationCode]
  );
  
  const logout = async () => {
    try {
      await authService.logout();
      window.location.href = '/';
    } catch (e) { 
      console.log(e);
    }
  };

  return (
    <Row>
      <Col lg={12} className="top-margin" align="center">
        <Card style={{width: '400px'}}>
          <CardBody>
            <CardTitle tag="h3" style={{textAlign: 'center'}}>
              <img src={'./apple-touch-icon.png'} style={{width: '80px', height: '80px'}}></img>
            </CardTitle>
            <FormGroup>
              {!enabled && 
                <div>
                  <p className="qr-text">Scan the QR code on your authenticator app</p>
                  <img src={`${endpoints.api}/auth/mfa_qr_code?username=${user.email}`} />
                </div>
              }
              {enabled && 
                <div>
                  <p className="qr-text">Please enter verification code</p>
                </div>
              }
            </FormGroup>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Input id="verificationCode" placeholder="Verification code" type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
              </FormGroup>
              <Button type="submit" color="outline-primary" style={{width: '100%', marginBottom: '5px'}}>Confirm</Button>
              <Button onClick={() => logout()} color="outline-secondary" style={{width: '100%'}}>Back</Button>
              { invalidCode && 
                <p className="undertext text-danger">Invalid verification code</p>
              }
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
