import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FaRegCheckCircle, FaRegEdit, FaRegPlusSquare, FaCheck } from 'react-icons/fa';
import { FiTruck } from 'react-icons/fi';
import { IoIosImage } from 'react-icons/io';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';
import { openReimbursementDetail, openReturnTrackingHistory, openReturnTrackingImageUpload } from '../../redux/actions';
import returnTrackingService from '../../services/return_tracking.service';
import ReturnTrackingActivity from '../../models/return_tracking_activity.model';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import ReturnInfo from '../../models/return_info.model';
import ReimbursementItem from '../../models/reimbursement_item.model';
import ReimbursementDetail from './ReimbursementDetail';
import CommsDataItem from '../../models/comms_data_item.model';
import Comms from './Comms';
import ReturnTrackingImageUpload from './ReturnTrackingImageUpload';

const ReturnTrackingHistory = (props) => {
  const dispatch = useDispatch();
  const trackingNumber = useSelector(state => state.open_return_tracking_history_info);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [showInputShipmentId, setShowInputShipmentId] = useState(false);
  const [shipmentId, setShipmentId] = useState('');
  const [showInputCaseId, setShowInputCaseId] = useState(false);
  const [caseId, setCaseId] = useState('');
  const [complete, setComplete] = useState(0);
  const [showInputReimbursementId, setShowInputReimbursementId] = useState(false);
  const [reimbursementId, setReimbursementId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [requestTs, setRequestTs] = useState('');
  const [daysOld, setDaysOld] = useState(0);
  const [reimbursementData, setReimbursementData] = useState([]);
  const [commsData, setCommsData] = useState([]);
  const [imageLink, setImageLink] = useState('');
  const openReimbursementDetailToggle = useSelector(state => state.open_reimbursement_detail);
  const openReturnTrackingImageUploadToggle = useSelector(state => state.open_return_tracking_image_upload);

  const getData = async () => {
    try {
      const results = await returnTrackingService.getReturnData(trackingNumber);
      const newRows = results.history.map((r, i) => new ReturnTrackingActivity(r, i));
      const newReturnInfo = new ReturnInfo(results.return_info);
      const newReimbursementData = results.reimbursement_data.map((r, i) => new ReimbursementItem(r, i));
      const newCommsData = results.comms_data.map((r, i) => new CommsDataItem(r, i));
      setImageLink(newReturnInfo.image_link);
      setComplete(newReturnInfo.complete);
      setShipmentId(newReturnInfo.shipment_id);
      setCaseId(newReturnInfo.case_id);
      setReimbursementId(newReturnInfo.reimbursement_id);
      setOrderId(newReturnInfo.order_id);
      setRequestTs(newReturnInfo.request_ts);
      setDaysOld(newReturnInfo.days_old);
      setReimbursementData(newReimbursementData);
      setCommsData(newCommsData);
      setRows(newRows);
    } catch (e) {
      console.log(e);
    }
  };

  const updateReturnInfo = async (name) => {
    try {
      if (name === 'shipment_id') {
        setShowInputShipmentId(false);
      } else if (name === 'case_id') {
        setShowInputCaseId(false);
      } else if (name === 'reimbursement_id') {
        setShowInputReimbursementId(false);
      }

      await returnTrackingService.updateReturnData(trackingNumber, shipmentId, caseId, reimbursementId);
      await getData();

    } catch (e) {
      console.log(e);
    }
  };

  const completeReturn = async () => {
    try {
      await returnTrackingService.completeReturn(trackingNumber);
      dispatch(openReturnTrackingHistory());
      props.refresh();
    } catch (e) {
      console.log(e);
    }
  };

  const clearComplete = async () => {
    try {
      await returnTrackingService.clearComplete(trackingNumber);
      dispatch(openReturnTrackingHistory());
      props.refresh();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'Activity TS',
        dataField: 'activity_ts',
        headerStyle: {width: '140px'},
        style: {textAlign: 'left'},
        sort: true
      },
      {
        text: 'City',
        dataField: 'city',
        headerStyle: {width: '100px'},
        style: {textAlign: 'left'},
        sort: true
      },
      {
        text: 'State',
        dataField: 'state',
        headerStyle: {width: '70px'},
        style: {textAlign: 'left'},
        sort: true
      },
      {
        text: 'Carrier Status',
        dataField: 'status',
        style: {textAlign: 'left'},
        sort: true
      },
      {
        text: 'Status',
        dataField: 'internal_status',
        headerStyle: {width: '70px'},
        style: {textAlign: 'center'},
        sort: true,
        formatter: (cell, row) => {
          if (row.internal_status === 'delivered') {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          } else if (row.internal_status === 'transit') {
            return <FiTruck style={{fontSize: '18px', marginBottom: '2px', color: 'orange'}}/>;
          } else {
            return <FaRegEdit style={{fontSize: '18px', marginBottom: '2px', color: 'blue'}}/>;
          }

        }
      }
    ];

    setColumns(newColumns);
  }, [rows]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => dispatch(openReturnTrackingHistory())} backdrop="static" size="lg">
      <ModalHeader toggle={() => dispatch(openReturnTrackingHistory())}>
        <FiTruck style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Return Tracking History - { trackingNumber }
      </ModalHeader>
      <ModalBody className="return-tracking-history">
        { openReimbursementDetailToggle &&
          <ReimbursementDetail isOpen={openReimbursementDetailToggle} />
        }
        { openReturnTrackingImageUploadToggle &&
          <ReturnTrackingImageUpload isOpen={openReturnTrackingImageUploadToggle} refresh={getData} />
        }
        <Row>
          <Col lg={6}>
            <table>
              <tbody>
                <tr>
                  <td className="category-titles">Shipment ID:</td>
                  <td className="category-text pointer">
                    { showInputShipmentId &&
                      <React.Fragment>
                        <input type="number" name="shipment_id" style={{fontSize: '12px', width: '100px'}} value={shipmentId} onChange={(e) => setShipmentId(e.target.value)}/>
                        <FaCheck className="edit-icon"style={{marginTop: '3px'}} onClick={() => updateReturnInfo('shipment_id')} />
                      </React.Fragment>
                    }
                    { (shipmentId && shipmentId !== '' && !showInputShipmentId) &&
                      <React.Fragment>
                        <span onClick={() => navigator.clipboard.writeText(shipmentId)}>{shipmentId}</span>
                        <span>
                          <FaRegEdit className="edit-icon" onClick={() => setShowInputShipmentId(true)} />
                        </span>
                      </React.Fragment>
                    }
                    { ((!shipmentId || shipmentId === '') && !showInputShipmentId) &&
                      <FaRegPlusSquare className="edit-icon" onClick={() => setShowInputShipmentId(true)} />
                    }
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Case ID:</td>
                  <td className="category-text pointer">
                    { showInputCaseId &&
                      <React.Fragment>
                        <input type="number" name="case_id" style={{fontSize: '12px', width: '100px'}} value={caseId} onChange={(e) => setCaseId(e.target.value)}/>
                        <FaCheck className="edit-icon" style={{marginTop: '3px'}} onClick={() => updateReturnInfo('case_id')} />
                      </React.Fragment>
                    }
                    { (caseId && caseId !== '' && !showInputCaseId) &&
                      <React.Fragment>
                        <span onClick={() => navigator.clipboard.writeText(caseId)}>{caseId}</span>
                        <span>
                          <FaRegEdit className="edit-icon" onClick={() => setShowInputCaseId(true)} />
                          <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://sellercentral.amazon.com/cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${caseId}`, '_blank')} />
                        </span>
                      </React.Fragment>
                    }
                    { ((!caseId || caseId === '') && !showInputCaseId) &&
                      <FaRegPlusSquare className="edit-icon" onClick={() => setShowInputCaseId(true)} />
                    }
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Reimbursement ID:</td>
                  <td className="category-text pointer">
                    { showInputReimbursementId &&
                      <React.Fragment>
                        <input type="number" name="reimbursement_id" style={{fontSize: '12px', width: '100px'}} value={reimbursementId} onChange={(e) => setReimbursementId(e.target.value)}/>
                        <FaCheck className="edit-icon" style={{marginTop: '3px'}} onClick={() => updateReturnInfo('reimbursement_id')} />
                      </React.Fragment>
                    }
                    { (reimbursementId && reimbursementId !== '' && !showInputReimbursementId) &&
                      <React.Fragment>
                        <span onClick={() => navigator.clipboard.writeText(reimbursementId)}>{reimbursementId}</span>
                        <span>
                          <FaRegEdit className="edit-icon" onClick={() => setShowInputReimbursementId(true)} />
                          <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://sellercentral.amazon.com/reportcentral/REIMBURSEMENTS/0`, '_blank')} />
                        </span>
                      </React.Fragment>
                    }
                    { ((!reimbursementId || reimbursementId === '') && !showInputReimbursementId) &&
                      <FaRegPlusSquare className="edit-icon" onClick={() => setShowInputReimbursementId(true)} />
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={6}>
            { imageLink && imageLink != '' &&
              <IoIosImage className="image-icon" onClick={() => window.open(imageLink)}/>
            }
            { reimbursementData.length > 0 &&
              <HiOutlineCurrencyDollar className="reimburse-icon" onClick={() => dispatch(openReimbursementDetail({tracking_number: trackingNumber, shipment_id: shipmentId, case_id: caseId, reimbursement_id: reimbursementId, reimbursement_data: reimbursementData, order_id: orderId, request_ts: requestTs, days_old: daysOld, comms_data: commsData}))}/>
            }
            { complete === 1 &&
              <div className="text-primary complete-text">***Complete***</div>
            }
          </Col>
          <Col lg={12}>
            <div className="section-header" style={{marginTop: '20px'}}> Tracking History</div>
            <hr style={{marginTop: '5px'}}/>
          </Col>
          <Col lg={12} style={{marginBottom: '10px'}}>
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} condensed bordered={false} noDataIndication={() => (<div>There is no activity for this tracking number</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
          <Comms trackingNumber={trackingNumber} shipmentId={shipmentId} caseId={caseId} reimbursementId={reimbursementId} reimbursementData={reimbursementData} orderId={orderId} requestTs={requestTs} daysOld={daysOld} commsData={commsData} />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          { complete === 0 &&
            <Button size="sm" style={{float: 'left'}} color="outline-primary" onClick={() => completeReturn()}>Mark Complete</Button>
          }
          { complete === 1 &&
            <Button size="sm" style={{float: 'left'}} color="outline-warning" onClick={() => clearComplete()}>Clear Complete</Button>
          }
          <Button size="sm" style={{float: 'left', marginLeft: '5px'}} color="outline-info" onClick={() => dispatch(openReturnTrackingImageUpload(trackingNumber))}>Upload Image</Button>
          <Button size="sm" style={{float: 'right'}} color="outline-secondary" onClick={() => dispatch(openReturnTrackingHistory())}>Close</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default ReturnTrackingHistory;