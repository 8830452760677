import React, { useState } from 'react';
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, UncontrolledDropdown } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import './Navbar.css';
import productsService from '../../services/products.service';
import ProductInfo from '../../models/product_info.model';
import ProductLookup from '../ProductLookup/ProductLookup';
import { openOrderDetail, openProductLookup, sidebarCollapse } from '../../redux/actions';
import { ImSpinner10 } from 'react-icons/im';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import OrderDetail from '../OrderDetail/OrderDetail';
import authService from '../../services/auth.service';

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [isOpen, setisOpen] = useState(false);
  const [lookupProduct, setLookupProduct] = useState(null);
  const [lookupOrder, setLookupOrder] = useState(null);
  const [looking, setLooking] = useState(false);
  const [lookingOrder, setLookingOrder] = useState(false);
  const openProductLookupState = useSelector(state => state.open_product_lookup);
  const openOrderDetailState = useSelector(state => state.open_order_detail);
  const viewOptions = useSelector(state => state.view_options);
  const titanViewOptions = useSelector(state => state.titan_view_options);
  const adminViewOptions = useSelector(state => state.admin_view_options);
  const navigate = useNavigate();

  const toggle = () => {
    setisOpen(!isOpen);
  };

  const handleKeypressProductSearch = e => {
    if (e.keyCode === 13) {
      checkPart();
    }
  };

  const handleKeypressOrderSearch = e => {
    if (e.keyCode === 13) {
      if (lookupOrder && lookupOrder !== '') {
        dispatch(openOrderDetail(lookupOrder));
      }
    }
  };

  const checkOrder = async () => {
    try {
      if (!lookingOrder && lookupOrder && lookupOrder !== '') {
        setLookingOrder(true);
        dispatch(openOrderDetail(lookupOrder));
        setLookingOrder(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  
  const checkPart = async () => {
    try {
      if (!looking && lookupProduct && lookupProduct !== '') {
        setLooking(true);
        const productResults = await productsService.productLookup(lookupProduct);
        const productInfo = productResults.product_info.map(r => new ProductInfo(r));
        setLooking(false);
        dispatch(openProductLookup(productInfo));
      }
    } catch (e) { 
      console.log(e);
    }
  };

  const logout = async () => {
    try {
      await authService.logout();
      window.location.href = '/';
    } catch (e) { 
      console.log(e);
    }
  };

  return (
    <div style={{marginBottom: '91px'}}>
      { openProductLookupState &&
        <ProductLookup isOpen={openProductLookupState}/>
      }
      { openOrderDetailState &&
        <OrderDetail isOpen={openOrderDetailState}/>
      }
      <Navbar className="background-nav" color="dark" dark expand="lg" fixed="top">
        <NavbarBrand className="d-none d-md-block d-lg-block d-xl-block"><AiOutlineMenu className="sidebar-toggle-icon" onClick={() => dispatch(sidebarCollapse())}/></NavbarBrand>
        <NavbarBrand href="/"><img width={'120px'} style={{marginBottom: '3px'}} src={'./ba-logo.png'} /><span style={{marginLeft: '15px', fontStyle: 'italic'}}></span></NavbarBrand>
        <NavbarToggler onClick={toggle}/>
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar>
            { user.admin === 1 &&
              <UncontrolledDropdown className="d-md-none d-lg-none d-xl-none" inNavbar nav >
                <DropdownToggle caret nav style={{fontSize: '1rem'}}> Retail Fulfillment</DropdownToggle>
                { viewOptions && 
                  <DropdownMenu right>
                    { viewOptions.map(v =>
                      <DropdownItem key={v.id} onClick={() => navigate(v.url)}>{v.label}</DropdownItem>
                    )}
                  </DropdownMenu>
                }
              </UncontrolledDropdown>
            }
            { (user.admin === 1 || user.removals === 1 || user.single_sku_shipments === 1 || user.returns === 1 || user.shipment_invoices === 1 || user.comp_pricing === 1 || user.backstock === 1) &&
              <UncontrolledDropdown className="d-md-none d-lg-none d-xl-none" inNavbar nav >
                <DropdownToggle caret nav style={{fontSize: '1rem'}}> Titan</DropdownToggle>
                { titanViewOptions && 
                  <DropdownMenu right>
                    { titanViewOptions.map(v =>
                      <React.Fragment key={v.id} >
                        { user.admin === 1 && 
                          <DropdownItem key={v.id} onClick={() => navigate(v.url)}>{v.label}</DropdownItem>
                        }
                        { user.admin === 0 && user.removals === 1 && v.id === 14 && 
                          <DropdownItem key={v.id} onClick={() => navigate(v.url)}>{v.label}</DropdownItem>
                        }
                        { user.admin === 0 && user.single_sku_shipments === 1 && v.id === 16 && 
                          <DropdownItem key={v.id} onClick={() => navigate(v.url)}>{v.label}</DropdownItem>
                        }
                        { user.admin === 0 && user.returns === 1 && v.id === 13 && 
                          <DropdownItem key={v.id} onClick={() => navigate(v.url)}>{v.label}</DropdownItem>
                        }
                        { user.admin === 0 && user.shipment_invoices === 1 && v.id === 17 && 
                          <DropdownItem key={v.id} onClick={() => navigate(v.url)}>{v.label}</DropdownItem>
                        }
                        { user.admin === 0 && user.comp_pricing === 1 && v.id === 18 && 
                          <DropdownItem key={v.id} onClick={() => navigate(v.url)}>{v.label}</DropdownItem>
                        }
                        { user.admin === 0 && user.backstock === 1 && v.id === 20 && 
                          <DropdownItem key={v.id} onClick={() => navigate(v.url)}>{v.label}</DropdownItem>
                        }
                      </React.Fragment>
                    )}
                  </DropdownMenu>
                }
              </UncontrolledDropdown>
            }
            { user.admin === 1 &&
              <UncontrolledDropdown className="d-md-none d-lg-none d-xl-none" inNavbar nav >
                <DropdownToggle caret nav style={{fontSize: '1rem'}}> Admin</DropdownToggle>
                { adminViewOptions && 
                  <DropdownMenu right>
                    { adminViewOptions.map(v =>
                      <DropdownItem key={v.id} onClick={() => navigate(v.url)}>{v.label}</DropdownItem>
                    )}
                  </DropdownMenu>
                }
              </UncontrolledDropdown>
            }
          </Nav>
          <Nav navbar className="ml-auto">
            <NavItem className="product-lookup-search" hidden={user.admin === 0}>
              <div className="input-group">
                <Input type="text" name="part_lookup" id="part_lookup" placeholder="Product Search" style={{fontSize: '12px'}} onInput={e => setLookupProduct(e.target.value)} onKeyDown={(e) => handleKeypressProductSearch(e)}/>
                <div className="input-group-append">
                  <button className="btn btn-secondary btn-sm" type="button" onClick={() => checkPart()}>
                    { looking &&
                      <ImSpinner10 icon="spinner" className="spinner" />
                    }
                    { !looking &&
                      <FaSearch />
                    }
                  </button>
                </div>
              </div>
            </NavItem>
            <NavItem className="product-lookup-search" hidden={user.admin === 0}>
              <div className="input-group">
                <Input type="text" name="part_lookup" id="part_lookup" placeholder="Order Search" style={{fontSize: '12px'}} onInput={e => setLookupOrder(e.target.value)} onKeyDown={(e) => handleKeypressOrderSearch(e)}/>
                <div className="input-group-append">
                  <button className="btn btn-secondary btn-sm" type="button" onClick={() => checkOrder()}>
                    { lookingOrder &&
                      <ImSpinner10 icon="spinner" className="spinner" />
                    }
                    { !lookingOrder &&
                      <FaSearch />
                    }
                  </button>
                </div>
              </div>
            </NavItem>
            <NavItem>
              <span className="nav-link active">Hi, {user.first_name}</span>
            </NavItem>
            <NavItem>
              <span className="nav-link" onClick={() => logout()}><FiLogOut style={{marginBottom: '3px', cursor: 'pointer', fontSize: '20px'}}/></span>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;