export default class ReturnInfo {
  constructor(data) {
    this.tracking_number = data && data.tracking_number ? data.tracking_number : '';
    this.order_id = data && data.order_id ? data.order_id : '';
    this.shipment_id = data && data.shipment_id ? data.shipment_id : '';
    this.case_id = data && data.case_id ? data.case_id : '';
    this.reimbursement_id = data && data.reimbursement_id ? data.reimbursement_id : '';
    this.complete = data && data.complete ? data.complete : 0;
    this.days_old = data && data.days_old ? data.days_old : 0;
    this.request_ts = data && data.request_ts ? data.request_ts : '';
    this.image_link = data && data.image_link ? data.image_link : '';
  } 
}