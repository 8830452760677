export default class ReimbursementItem {
  constructor(data, index) {
    this.id = index;
    this.sku = data && data.sku ? data.sku : '';
    this.asin = data && data.asin ? data.asin : '';
    this.fnsku = data && data.fnsku ? data.fnsku : '';
    this.amount_per_unit = data && data.amount_per_unit ? data.amount_per_unit : 0;
    this.amount_total = data && data.amount_total ? data.amount_total : 0;
    this.approval_date = data && data.approval_date ? data.approval_date : '';
    this.product_name = data && data.product_name ? data.product_name : '';
    this.quantity_reimbursed_cash = data && data.quantity_reimbursed_cash ? data.quantity_reimbursed_cash : 0;
    this.quantity_reimbursed_inventory = data && data.quantity_reimbursed_inventory ? data.quantity_reimbursed_inventory : 0;
  }
}