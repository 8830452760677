import React, { useCallback, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TiExportOutline } from 'react-icons/ti';
import { FaAmazon, FaDownload  } from 'react-icons/fa';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { openCreateEditBatch, openCreateEditDims, setCategoryOptionsFilter, openCreateEditCustomer, openCreateEditSingle } from '../../redux/actions';
// import moment from 'moment';
import './InboundShipments.css';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import inboundShipmentsService from '../../services/inbound_shipments.service';
import InbounShipmentItem from '../../models/inbound_shipment_item.model';
import CreateEditDimsModal from './CreateEditDimsModal';
import CreateEditSingleModal from './CreateEditSingleModal';
import CreateEditBatchModal from './CreateEditBatchModal';
import CreateEditCustomerModal from './CreateEditCustomerModal';

const { SearchBar } = Search;

const InboundShipments = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  // const [startDate, setStartDate] = useState(new Date(moment().subtract(12, 'months').startOf('month')));
  // const [endDate, setEndDate] = useState(new Date());
  const [selectedRows, setSelectedRows] = useState([]);
  const [categoryOptions] = useState([
    { value: 'unprocessed', label: 'Unprocessed' },
    { value: 'processing', label: 'Processing' },
    { value: 'dims', label: 'Dims' },
    { value: 'single', label: 'Single' },
    { value: 'customer', label: 'Customer' },
    { value: 'return', label: 'Return' },
    { value: 'batch', label: 'Batch' },
    { value: 'mike', label: 'Mike' },
    { value: 'mix', label: 'Mix' },
    { value: 'warranty', label: 'Warranty' }
  ]);

  const categoryOptionsFilter = useSelector(state => state.category_options_filter);
  const openCreateEditDimsToggle = useSelector(state => state.open_create_edit_dims);
  const openCreateEditSingleToggle = useSelector(state => state.open_create_edit_single);
  const openCreateEditBatchToggle = useSelector(state => state.open_create_edit_batch);
  const openCreateEditCustomerToggle = useSelector(state => state.open_create_edit_customer);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = useCallback(async () => {
    try {
      const results = await inboundShipmentsService.getInboundShipments(categoryOptionsFilter.value);
      const newRows = results.map(r => new InbounShipmentItem(r));
      const rowsWithColors = groupAndColorRows(newRows);
      setRows(rowsWithColors);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [categoryOptionsFilter]);

  const handleMarkCategory = useCallback(async (category) => {
    try {
      await inboundShipmentsService.markCategroy(selectedRows, category);
      clearSelectedRows();
      getData();
    } catch (e) {
      console.log(e);
    }
  }, [selectedRows]);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
  };

  const groupAndColorRows = (data) => {
    // Initial color assignments
    const colors = [
      '#FFCCCC', // Light Red
      '#FFE5CC', // Light Orange
      '#FFFFCC', // Light Yellow
      '#E5FFCC', // Light Green
      '#CCFFE5', // Light Mint
      '#CCFFFF', // Light Cyan
      '#CCE5FF', // Light Sky Blue
      '#CCCCFF', // Light Purple
      '#E5CCFF', // Light Lavender
      '#FFCCE5', // Light Pink
      '#D2FAE1', // Soft Mint
      '#E1FAD2', // Soft Lime
      '#D2E1FA', // Soft Blue
      '#FAD2FA', // Soft Lavender
      '#FADAC2', // Peach
      '#C2FADA', // Aqua Green
      '#A2C2FA', // Soft Periwinkle
      '#FAC2FA', // Mauve
      '#FAD9C2', // Sand
      '#C2FAD9', // Seafoam
      '#D9FAC2', // Light Lime
      '#C2D9FA', // Soft Blue Gray
      '#FAC2D9', // Soft Rose
      '#FFDFBF', // Melon
      '#BFFFD9', // Mint Cream
      '#D9FFBF', // Tea Green
      '#BFD9FF', // Periwinkle Blue
      '#FFBFD9', // Pale Pink
      '#FFFACD', // Lemon Chiffon
      '#CDFFFA', // Pale Turquoise
      '#FAFFCD', // Light Goldenrod Yellow
      '#CDFAFF', // Pale Blue
      '#FFCDFA', // Plum
    ];
    let colorIndex = 0;
  
    // Function to generate unique key based on conditions
    const generateKey = (row) => row.package_reference_no_4 || row.reference_numbers;
  
    // Group rows by the generated key
    const grouped = data.reduce((acc, row) => {
      const key = generateKey(row);
      if (!acc[key]) {
        acc[key] = {
          color: colors[colorIndex % colors.length],
          rows: []
        };
        colorIndex++; // Move to the next color for the next group
      }
      acc[key].rows.push(row);
      return acc;
    }, {});
  
    // Map rows back to their color based on the group
    const rowColors = data.map(row => {
      const key = generateKey(row);
      return { ...row, color: grouped[key].color };
    });
  
    return rowColors;
  };

  useEffect(() => {
    // Use this function to find duplicates and assign them colors based on your rows data
    const newColumns = [
      {
        text: 'Tracking Number',
        dataField: 'inbound_tracking_number',
        headerStyle: {width: '140px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.inbound_tracking_number)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Reference Number(s)',
        dataField: 'reference_numbers',
        headerStyle: {width: '350px'},
        style: { textAlign: 'left', verticalAlign: 'middle', },
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.reference_numbers)
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          return(
            <div style={{whiteSpace: 'normal', maxWidth: '300px', wordWrap: 'break-word'}}>
              {row.reference_numbers}
            </div>
          );
        }
      },
      {
        text: 'Status',
        dataField: 'status',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.status)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Scheduled Date',
        dataField: 'scheduled_delivery',
        headerStyle: {width: '120px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.scheduled_delivery)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Date',
        dataField: 'date_delivered',
        headerStyle: {width: '120px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.date_delivered)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Shipper Name',
        dataField: 'shipper_name',
        headerStyle: {width: '200px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.shipper_name)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'City',
        dataField: 'shipper_city',
        headerStyle: {width: '100px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.shipper_city)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'State',
        dataField: 'shipper_state',
        headerStyle: {width: '60px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.shipper_state)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Country',
        dataField: 'shipper_country',
        headerStyle: {width: '60px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.shipper_country)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Service',
        dataField: 'service',
        headerStyle: {width: '150px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.service)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Package Ref 4',
        dataField: 'package_reference_no_4',
        headerStyle: {width: '200px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.package_reference_no_4)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Weight',
        dataField: 'package_weight',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.package_weight)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'H',
        dataField: 'package_height',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.package_height)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'L',
        dataField: 'package_length',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.package_length)
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'W',
        dataField: 'package_width',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.package_width)
        },
        filter: textFilter({className: 'filter-box'})
      }
    ];

    // add batch name to beginning if category is batch

    if (categoryOptionsFilter && categoryOptionsFilter.value === 'batch') {
      newColumns.unshift({
        text: 'Batch Name',
        dataField: 'batch_name',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.batch_name)
        },
        filter: textFilter({className: 'filter-box'})
      });
    }

    if (categoryOptionsFilter && categoryOptionsFilter.value === 'customer') {
      newColumns.unshift({
        text: 'Order#',
        dataField: 'order_id',
        headerStyle: {width: '70px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => navigator.clipboard.writeText(row.order_id)
        },
        filter: textFilter({className: 'filter-box'})
      });

      newColumns.push({
        text: 'Lbl',
        dataField: 'download',
        style: {textAlign: 'center', verticalAlign: 'middle', fontSize: '20px'},
        sort: true,
        formatter: (cell, row) => {
          return (
            <FaDownload className="download-icon" style={{marginBottom: '5px'}} onClick={() => window.open(row.shipping_label_link, '_blank')} />
          );
        },
        filter: textFilter({className: 'filter-box'})
      });
    }

    ReactTooltip.rebuild();
    setColumns(newColumns);
  }, [rows, setColumns]);

  useEffect(() => {
    if (!categoryOptionsFilter) {
      // Assume this action updates categoryOptionsFilter in the redux store
      dispatch(setCategoryOptionsFilter(categoryOptions[0]));
    } else {
      getData(); // Call getData only after setting the initial categoryOptionsFilter
    }
  }, [dispatch, categoryOptionsFilter, categoryOptions, getData]);

  const selectCategoryOption = (e) => {
    dispatch(setCategoryOptionsFilter(e));
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 34,
      minHeight: 34,
    }),
    singleValue: (provided) => {
      return { ...provided, paddingBottom: '5px' };
    }
  };

  const selectRow = {
    mode: 'checkbox',
    selected: selectedRows.map(row => row.inbound_tracking_number),
    hideSelectColumn: categoryOptionsFilter && categoryOptionsFilter.value === 'unprocessed' ? false : true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRows(prev => [...prev, row]);
      } else {
        setSelectedRows(prev => prev.filter(r => r.inbound_tracking_number !== row.inbound_tracking_number));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedRows(rows);
      } else {
        setSelectedRows([]);
      }
    }
  };

  const rowStyle = (row) => {
  // Check if the current category filter is 'unprocessed'
    if (categoryOptionsFilter && categoryOptionsFilter.value === 'unprocessed') {
    // If the row has a color assigned and the filter is 'unprocessed', apply it to the background
      if (row.color) {
        return { backgroundColor: row.color };
      }
    }
    // Default style if no color is assigned or if the category is not 'unprocessed'
    return {};
  };

  const defaultSort = [{
    dataField: 'created_ts',
    order: 'desc'
  }];

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        { openCreateEditDimsToggle &&
          <CreateEditDimsModal isOpen={openCreateEditDimsToggle} refresh={getData} clearSelectedRows={clearSelectedRows} />
        }
        { openCreateEditSingleToggle &&
          <CreateEditSingleModal isOpen={openCreateEditSingleToggle} refresh={getData} clearSelectedRows={clearSelectedRows} />
        }
        { openCreateEditBatchToggle &&
          <CreateEditBatchModal isOpen={openCreateEditBatchToggle} refresh={getData} clearSelectedRows={clearSelectedRows} />
        }
        { openCreateEditCustomerToggle &&
          <CreateEditCustomerModal isOpen={openCreateEditCustomerToggle} refresh={getData} clearSelectedRows={clearSelectedRows} />
        }
        <ReactTooltip place="bottom"/>
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="inbound_tracking_number" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <FaAmazon className="text-primary" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Inbound Shipments</span>
                        </div>
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      {/* <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font" 
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font"
                          style={{ fontSize: '13px' }}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div> */}
                      <div style={{float: 'right', marginLeft: '10px', width: '160px'}} >
                        <Select height={10} className="dropdown" value={categoryOptionsFilter} styles={customStyles} name="category_options" options={categoryOptions} onChange={(e) => selectCategoryOption(e)}/>
                      </div>
                      <div style={{float: 'right', width: '200px'}}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <div style={{float: 'right', marginRight: '5px'}}>
                        <Button size="sm" disabled={selectedRows.length === 0} style={{fontSize: '16px'}} color="outline-primary" onClick={() => handleMarkCategory('warranty')}>Warranty</Button>
                      </div>
                      <div style={{float: 'right', marginRight: '5px'}}>
                        <Button size="sm" disabled={selectedRows.length === 0} style={{fontSize: '16px'}} color="outline-primary" onClick={() => handleMarkCategory('mix')}>Mix</Button>
                      </div>
                      <div style={{float: 'right', marginRight: '5px'}}>
                        <Button size="sm" disabled={selectedRows.length === 0} style={{fontSize: '16px'}} color="outline-primary" onClick={() => handleMarkCategory('mike')}>Mike</Button>
                      </div>
                      <div style={{float: 'right', marginRight: '5px'}}>
                        <Button size="sm" disabled={selectedRows.length === 0} style={{fontSize: '16px'}} color="outline-primary" onClick={() => dispatch(openCreateEditBatch(selectedRows))}>Batch</Button>
                      </div>
                      <div style={{float: 'right', marginRight: '5px'}}>
                        <Button size="sm" disabled={selectedRows.length === 0} style={{fontSize: '16px'}} color="outline-primary" onClick={() => handleMarkCategory('return')}>Return</Button>
                      </div>
                      <div style={{float: 'right', marginRight: '5px'}}>
                        <Button size="sm" disabled={selectedRows.length === 0} style={{fontSize: '16px'}} color="outline-primary" onClick={() => dispatch(openCreateEditCustomer(selectedRows))}>Customer</Button>
                      </div>
                      <div style={{float: 'right', marginRight: '5px'}}>
                        <Button size="sm" disabled={selectedRows.length === 0} style={{fontSize: '16px'}} color="outline-primary" onClick={() => dispatch(openCreateEditSingle(selectedRows))}>Single</Button>
                      </div>
                      <div style={{float: 'right', marginRight: '5px'}}>
                        <Button size="sm" disabled={selectedRows.length === 0} style={{fontSize: '16px'}} color="outline-primary" onClick={() => dispatch(openCreateEditDims(selectedRows))}>Dims</Button>
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} defaultSorted={defaultSort} condensed bordered={false} selectRow={selectRow} rowStyle={rowStyle} filter={filterFactory()} noDataIndication={() => (<div>There are currently no shipments</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default InboundShipments;