import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TiExportOutline } from 'react-icons/ti';
import { PiMagnifyingGlassLight } from 'react-icons/pi';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { useDispatch, useSelector } from 'react-redux';
import { openPriceTrackerProductDetail, openVendorDetail, setPriceTrackerBrand, setPriceTrackerBrandOptions, setPriceTrackerVendor } from '../../redux/actions';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import priceTrackerService from '../../services/price_tracker.service';
import PriceTrackerSellerViolationsModel from '../../models/price_tracker_seller_violations.model';
import VendorDetail from './VendorDetail';
import PriceTrackerProductModel from '../../models/price_tracker_product.model';
import { FaDownload, FaRegCheckCircle } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import ProductDetail from './ProductDetail';

const { SearchBar } = Search;

const PriceTracker = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [viewOptions] = useState([
    { value: 'seller_summary', label: 'Seller Summary' },
    { value: 'sku_summary', label: 'Sku Summary' },
  ]);

  const [selectedView, setSelectedView] = useState(viewOptions[0]);

  const priceTrackerVendorOptions = useSelector(state => state.price_tracker_vendor_options);
  const priceTrackerBrandOptions = useSelector(state => state.price_tracker_brand_options);
  const priceTrackerBrandAllOptions = useSelector(state => state.price_tracker_brand_all_options);
  const selectedPriceTrackerVendor = useSelector(state => state.price_tracker_vendor);
  const selectedPriceTrackerBrand = useSelector(state => state.price_tracker_brand);
  const openVendorDetailToggle = useSelector(state => state.open_vendor_detail);
  const openPriceTrackerProductDetailToggle = useSelector(state => state.open_price_tracker_product_detail);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = async (vendor, brand) => {
    try {
      if (selectedView.value === 'seller_summary') {
        const results = await priceTrackerService.sellerViolations(vendor, brand);
        const newRows = results.map((r, i) => new PriceTrackerSellerViolationsModel(r, i));
        setRows(newRows);
      } else if (selectedView.value === 'sku_summary') {
        const results = await priceTrackerService.skuViolations(vendor, brand);
        const newRows = results.map((r, i) => new PriceTrackerProductModel(r, i));
        setRows(newRows);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const defineBrandOptions = (vendor) => {
    let newBrandOptions = priceTrackerBrandAllOptions.filter(v => v.value === vendor);
    newBrandOptions = newBrandOptions.map(b => ({ value: b.brand, label: b.brand }));
    newBrandOptions.unshift({ value: 'all', label: 'All Brands' });
    dispatch(setPriceTrackerBrandOptions(newBrandOptions));
    dispatch(setPriceTrackerBrand(newBrandOptions[0]));
    getData(vendor, newBrandOptions[0].value);
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" data-tip="Export"  color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    if (selectedView.value === 'seller_summary') {
      const newColumns = [
        {
          text: 'Seller',
          dataField: 'seller_name',
          style: {textAlign: 'left', verticalAlign: 'middle'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'Address',
          dataField: 'address',
          style: {textAlign: 'left', verticalAlign: 'middle'},
          headerStyle: {width: '300px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'City',
          dataField: 'city',
          style: {textAlign: 'left', verticalAlign: 'middle'},
          headerStyle: {width: '150px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'State',
          dataField: 'state',
          style: {textAlign: 'center', verticalAlign: 'middle'},
          headerStyle: {width: '100px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'Zip',
          dataField: 'zip',
          style: {textAlign: 'right', verticalAlign: 'middle'},
          headerStyle: {width: '100px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'Country',
          dataField: 'country',
          style: {textAlign: 'center', verticalAlign: 'middle'},
          headerStyle: {width: '90px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'Authroized Seller',
          dataField: 'is_authorized',
          style: {textAlign: 'center', verticalAlign: 'middle'},
          headerStyle: {width: '90px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'}),
          formatter: (cell, row) => {
            if (row.is_authorized === 1) {
              return <FaRegCheckCircle data-tip="Authorized" style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
            } else {
              return <ImCancelCircle data-tip="Unauthorized" style={{fontSize: '18px', marginBottom: '2px', color: 'red'}}/>;
            }
          }
        },
        {
          text: 'Active Listings',
          dataField: 'asin_cnt',
          style: {textAlign: 'right', verticalAlign: 'middle'},
          headerStyle: {width: '90px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'Catalog Coverage',
          dataField: 'asin_pct',
          style: {textAlign: 'right', verticalAlign: 'middle'},
          headerStyle: {width: '90px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'}),
          sortFunc: (a, b, order) => {
            if (order === 'desc') {
              return parseFloat(b) - parseFloat(a);
            }
            return parseFloat(a) - parseFloat(b);
          },
        },
        {
          text: 'Price Violations',
          dataField: 'map_violations',
          style: {textAlign: 'right', verticalAlign: 'middle'},
          sort: true,
          headerStyle: {width: '90px'},
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'}),
          formatter: (cell, row) => {
            if (row.map_violations >= 10) {
              return <div className="text-danger">{row.map_violations}</div>;
            } else if (row.map_violations < 10 && row.map_violations > 0) {
              return <div className="dark-orange">{row.map_violations}</div>;
            } else {
              return <div className="text-success">{row.map_violations}</div>;
            }
          }
        },
        {
          text: 'Last Updated',
          dataField: 'created_ts',
          style: {textAlign: 'center', verticalAlign: 'middle'},
          sort: true,
          headerStyle: {width: '140px'},
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'}),
          sortFunc: (a, b, order) => {
            if (order === 'desc') {
              return new Date(b) - new Date(a);
            }
            return new Date(a) - new Date(b);
          }
        }
      ];
  
      ReactTooltip.rebuild();
      setColumns(newColumns);
    } else if (selectedView.value === 'sku_summary') {
      const newColumns = [
        {
          text: 'Sku',
          dataField: 'part_no',
          style: {textAlign: 'left', verticalAlign: 'middle'},
          headerStyle: {width: '100px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openPriceTrackerProductDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'UPC/EAN',
          dataField: 'upc_ean',
          style: {textAlign: 'center', verticalAlign: 'middle'},
          headerStyle: {width: '100px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) =>  dispatch(openPriceTrackerProductDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'ASIN',
          dataField: 'asin',
          style: {textAlign: 'left', verticalAlign: 'middle'},
          headerStyle: {width: '80px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) =>  dispatch(openPriceTrackerProductDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'Brand',
          dataField: 'brand',
          style: {textAlign: 'center', verticalAlign: 'middle'},
          headerStyle: {width: '80px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) =>  dispatch(openPriceTrackerProductDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'Description',
          dataField: 'description',
          style: {textAlign: 'left', verticalAlign: 'middle'},
          headerStyle: {width: '250px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) =>  dispatch(openPriceTrackerProductDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'MAP',
          dataField: 'map',
          style: {textAlign: 'right', verticalAlign: 'middle'},
          headerStyle: {width: '50px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) =>  dispatch(openPriceTrackerProductDetail(row))
          },
          filter: textFilter({className: 'filter-box'})
        },
        {
          text: 'Unauthroized Sellers',
          dataField: 'unauthorized',
          style: {textAlign: 'right', verticalAlign: 'middle'},
          headerStyle: {width: '50px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) =>  dispatch(openPriceTrackerProductDetail(row))
          },
          filter: textFilter({className: 'filter-box'}),
          formatter: (cell, row) => {
            if (row.unauthorized > 0) {
              return <div className="text-danger">{row.unauthorized}</div>;
            } else {
              return <div className="text-success">{row.unauthorized}</div>;
            }
          }
        },
        {
          text: 'Price Violations',
          dataField: 'map_violations',
          style: {textAlign: 'right', verticalAlign: 'middle'},
          headerStyle: {width: '50px'},
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row) =>  dispatch(openPriceTrackerProductDetail(row))
          },
          filter: textFilter({className: 'filter-box'}),
          formatter: (cell, row) => {
            if (row.map_violations >= 10) {
              return <div className="text-danger">{row.map_violations}</div>;
            } else if (row.map_violations < 10 && row.map_violations > 0) {
              return <div className="dark-orange">{row.map_violations}</div>;
            } else {
              return <div className="text-success">{row.map_violations}</div>;
            }
          }
        },
        {
          text: 'Last Updated',
          dataField: 'created_ts',
          style: {textAlign: 'center', verticalAlign: 'middle'},
          sort: true,
          headerStyle: {width: '140px'},
          events: {
            onClick: (e, column, columnIndex, row) => dispatch(openVendorDetail(row))
          },
          filter: textFilter({className: 'filter-box'}),
          sortFunc: (a, b, order) => {
            if (order === 'desc') {
              return new Date(b) - new Date(a);
            }
            return new Date(a) - new Date(b);
          }
        }
      ];
  
      ReactTooltip.rebuild();
      setColumns(newColumns);
    }
  }, [rows, setColumns]);

  useEffect(() => {
    setIsLoading(true);
    getData(selectedPriceTrackerVendor?.value, selectedPriceTrackerBrand?.value);
  }, [selectedView]);

  const selectVendor = (e) => {
    dispatch(setPriceTrackerVendor(e));
    defineBrandOptions(e.value);
  };

  const selectBrand = (e) => {
    dispatch(setPriceTrackerBrand(e));
    getData(selectedPriceTrackerVendor?.value, e.value);
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 34,
      minHeight: 34,
    }),
    singleValue: (provided) => {
      return { ...provided, paddingBottom: '5px' };
    }
  };

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid price-tracker" style={{marginTop: '10px'}}>
        { openPriceTrackerProductDetailToggle &&
          <ProductDetail isOpen={openPriceTrackerProductDetailToggle} refresh={getData} />
        }
        { openVendorDetailToggle &&
          <VendorDetail isOpen={openVendorDetailToggle} refresh={getData} />
        }
        <ReactTooltip place={'bottom'}/>
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <PiMagnifyingGlassLight className="text-primary" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Seller Verify</span>
                        </div>
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button outline color="secondary" id="bulk_download" size="sm" onClick={() => priceTrackerService.bulkViolationList(selectedPriceTrackerVendor.value, selectedPriceTrackerBrand.value)}><FaDownload data-tip="Bulk Download Violations" style={{marginBottom: '2px', fontSize: '20px'}} /></Button>
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '150px'}} >
                        <Select height={10} className="dropdown" value={selectedPriceTrackerBrand} styles={customStyles} name="brand" options={priceTrackerBrandOptions} onChange={(e) => selectBrand(e)}/>
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '150px'}} >
                        <Select height={10} className="dropdown" value={selectedPriceTrackerVendor} styles={customStyles} name="vendor" options={priceTrackerVendorOptions} onChange={(e) => selectVendor(e)}/>
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '150px'}} >
                        <Select height={10} className="dropdown" value={selectedView} styles={customStyles} name="view" options={viewOptions} onChange={(e) => setSelectedView(e)}/>
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no removals</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default PriceTracker;