import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import OrderStatusModel from '../../models/order_status.model';
import OrderTracking from '../../models/order_tracking.model';
import { openOrderDetail, openProductLookup } from '../../redux/actions';
import {FaCheck, FaEdit, FaSearch, FaRegPlusSquare, FaRegListAlt} from 'react-icons/fa';
import ordersService from '../../services/orders.service';
import './OrderDetail.css';
import productsService from '../../services/products.service';
import ProductInfo from '../../models/product_info.model';
import { ImSpinner10 } from 'react-icons/im';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import { Store } from 'react-notifications-component';
import ProfitReportModel from '../../models/profit_report.model';

const OrderDetail = (props) => {
  const dispatch = useDispatch();
  const orderId = useSelector(state => state.open_order_detail_info);
  const shippingVendorOptions = useSelector(state => state.shipping_vendor_options);
  const [orderInfo, setOrderInfo] = useState(null);
  const [orderStatus, setOrderStatus] = useState([]);
  const [orderTracking, setOrderTracking] = useState([]);
  const [orderProfitInfo, setOrderProfitInfo] = useState([]);
  const [inputField, setInputField] = useState(null);
  const [inputFieldDistributor, setInputFieldDistributor] = useState(null);
  const [inputFieldItemCost, setInputFieldItemCost] = useState(null);
  const [inputFieldTracking, setInputFieldTracking] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectVendorValue, setSelectVendorValue] = useState(null);
  const [selectDistributorValue, setSelectDistributorValue] = useState(null);
  const [looking, setLooking] = useState(false);

  const getData = useCallback(async () => {
    try {
      const newOrderInfo = await ordersService.getOrder(orderId);
      setOrderInfo(newOrderInfo.order_info);
      const newOrderStatus = newOrderInfo.order_status.map(o => new OrderStatusModel(o));
      setOrderStatus(newOrderStatus);
      const newOrderTracking = newOrderInfo.order_tracking.map(o => new OrderTracking(o));
      setOrderTracking(newOrderTracking);
      const newOrderProfitInfo = newOrderInfo.order_profit_info.map((o, i) => new ProfitReportModel(o, i));
      setOrderProfitInfo(newOrderProfitInfo);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const showInputField = useCallback(async (index) => {
    try {
      setInputFieldItemCost(null);
      setInputFieldDistributor(null);
      setInputValue('');
      setInputField(index);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const showInputFieldDistributor = useCallback(async (index) => {
    try {
      setInputFieldItemCost(null);
      setInputField(null);
      setInputValue('');
      setInputFieldDistributor(index);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const showInputFieldItemCost = useCallback(async (index) => {
    try {
      setInputFieldDistributor(null);
      setInputField(null);
      setInputValue('');
      setInputFieldItemCost(index);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const showInputFieldTracking = useCallback(async () => {
    try {
      setInputFieldDistributor(null);
      setInputField(null);
      setInputFieldItemCost(null);
      setInputValue('');
      setSelectVendorValue(shippingVendorOptions[0].value);
      setSelectDistributorValue(orderStatus[0].distributor);
      setInputFieldTracking(true);
    } catch (e) {
      console.log(e);
    }
  }, [shippingVendorOptions, orderStatus]);

  const determineShippingVendor = useCallback((trackingNumber) => {
    let carrier;
    if (trackingNumber.slice(0, 2) === '1Z') {
      carrier = 'UPS';
    } else if (trackingNumber.slice(0, 3) === 'TBA') {
      carrier = 'Amazon';
    } else if (trackingNumber.length === 12) {
      carrier = 'FedEx';
    } else {
      carrier = 'USPS';
    }

    setSelectVendorValue(carrier);
    setInputValue(trackingNumber);
  }, [setSelectVendorValue]);

  const saveDistributorOrderNumber = useCallback(async (order, index) => {
    try {
      if (!orderStatus[index].distributor || orderStatus[index].distributor === null || orderStatus[index].items.filter(i => !i.cost || i.cost === '').length > 0) {
        Store.addNotification({
          title: 'Error',
          message: 'Please make sure the distributor is selected and item costs are filled in for all items for this distributor.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
      } else {
        orderStatus[index].order_number = inputValue;
        order.order_number = inputValue;
        setOrderStatus(orderStatus);
        setInputFieldDistributor(null);
        await ordersService.updateOrderNumber(order);
        Store.addNotification({
          title: 'Confirmation',
          message: 'Order number has been saved.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        getData();
      }
    } catch (e) {
      console.log(e);
    }
  }, [inputValue, orderStatus, setOrderStatus, setInputField]);

  const saveDistributor = useCallback(async (order, index) => {
    try {
      orderStatus[index].distributor = inputValue;
      order.distributor = inputValue;
      setOrderStatus(orderStatus);
      setInputField(null);
      await ordersService.updateDistributor(order);
      Store.addNotification({
        title: 'Confirmation',
        message: 'Distributor has been saved.',
        type: 'default',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      getData();
    } catch (e) {
      console.log(e);
    }
  }, [inputValue, orderStatus, setOrderStatus, setInputField]);

  const saveItemCost = useCallback(async (order, orderIndex, itemIndex) => {
    try {
      if (inputValue && inputValue !== '') {
        orderStatus[orderIndex].items[itemIndex].cost = inputValue;
        order.items[itemIndex].cost = inputValue;
        setOrderStatus(orderStatus);
        setInputFieldItemCost(false);
        await ordersService.updateItemCost(order.order_id, order.items[itemIndex]);
        Store.addNotification({
          title: 'Confirmation',
          message: 'Item cost has been saved.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        getData();
      } else {
        setInputFieldItemCost(false);
      }
    } catch (e) {
      console.log(e);
    }
  }, [inputValue, orderStatus, setOrderStatus, setInputField]);

  const saveTracking = useCallback(async () => {
    try {
      if (inputValue && inputValue !== '') {
        setInputFieldTracking(null);
        const newTrackingInfo = new OrderTracking({ distributor: selectDistributorValue, order_id: orderInfo.order_id, carrier: selectVendorValue, tracking_number: inputValue });
        orderTracking.push(newTrackingInfo);
        await ordersService.udpateTracking(newTrackingInfo);
        Store.addNotification({
          title: 'Confirmation',
          message: 'Tracking has been saved.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        getData();
      } else {
        setInputFieldTracking(false);
      }

    } catch (e) {
      console.log(e);
    }
  }, [inputValue, selectDistributorValue, selectVendorValue, orderStatus, setOrderStatus, setInputField]);

  const clearError = useCallback(async (order) => {
    try {
      await ordersService.clearError(order);
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const cancelOrder = useCallback(async () => {
    try {
      await ordersService.cancelOrder(orderInfo);
      Store.addNotification({
        title: 'Cancellation',
        message: 'Order has been cancelled.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      getData();
    } catch (e) {
      console.log(e);
    }
  }, [orderInfo]);

  useEffect(() => {
    getData();
  },[]);

  return (
    <Modal  isOpen={props.isOpen} toggle={() => dispatch(openOrderDetail())} backdrop="static" size="xl">
      <ModalHeader toggle={() => dispatch(openOrderDetail())}>
        <FaRegListAlt style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Order Detail
      </ModalHeader>
      <ModalBody className="order-detail">
        <Row>
          <Col lg={6}>
            { orderInfo &&
              <table>
                <tbody>
                  <tr>
                    <td className="category-titles">Order ID:</td>
                    <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(orderInfo.order_id)}>
                      <span>{orderInfo.order_id}</span>
                      <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://bal.cwa.sellercloud.com/Orders/Orders_details.aspx?id=${orderInfo.order_id}`, '_blank')} /></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="category-titles">Channel Order ID:</td>
                    <td className="category-text pointer">
                      <span onClick={() => navigator.clipboard.writeText(orderInfo.channel_order_id)}>{orderInfo.channel_order_id}</span>
                      <span>
                        { orderInfo.channel === 'Amazon' &&
                          <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://sellercentral.amazon.com/orders-v3/order/${orderInfo.channel_order_id}`, '_blank')} />
                        }
                        { orderInfo.channel === 'eBay' &&
                          <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://www.ebay.com/sh/ord/details?orderid=${orderInfo.channel_order_id}`, '_blank')} />
                        }
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="category-titles">Customer:</td>
                    <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(`${orderInfo.first_name} ${orderInfo.last_name}`)}>{orderInfo.first_name} {orderInfo.last_name}</td>
                  </tr>
                  <tr>
                    <td className="category-titles">Address 1:</td>
                    <td className="category-text pointer">
                      <span onClick={() => navigator.clipboard.writeText(orderInfo.street_line1)}>{orderInfo.street_line1}</span>
                      <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://www.google.com/search?q=${orderInfo.street_line1} ${orderInfo.street_line2} ${orderInfo.city}, ${orderInfo.state_code} ${orderInfo.postal_code}`, '_blank')} /></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="category-titles">Address 2:</td>
                    <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(orderInfo.street_line2)}>{orderInfo.street_line2}</td>
                  </tr>
                  <tr>
                    <td className="category-titles">City/State/Zip:</td>
                    <td className="category-text pointer"><span onClick={() => navigator.clipboard.writeText(orderInfo.city)}>{orderInfo.city}</span>, <span onClick={() => navigator.clipboard.writeText(orderInfo.state_code)}>{orderInfo.state_code}</span> <span onClick={() => navigator.clipboard.writeText(orderInfo.postal_code)}>{orderInfo.postal_code}</span></td>
                  </tr>
                </tbody>
              </table>
            }
          </Col>
          <Col lg={6}>
            { orderStatus.length > 0 && orderStatus[0].error === 1 &&
              <div className="error-text">***Error***'</div>
            }
            { orderStatus.length > 0 && orderStatus[0].cancelled === 1 &&
              <div className="error-text">***Cancelled***'</div>
            }
            { orderStatus.length > 0 && orderStatus[0].refunded === 1 &&
              <div className="error-text">***Refunded***'</div>
            }
            <div className="category-titles">Tracking:
              { !inputFieldTracking &&
                <FaRegPlusSquare className="edit-icon" style={{marginBottom: '3px'}} onClick={() => showInputFieldTracking()} />
              }
            </div>
            { inputFieldTracking &&
              <React.Fragment>
                <div className="form-row">
                  <select className="form-control" style={{fontSize: '12px', marginRight: '10px', width: '100px'}} onChange={(e) => setSelectDistributorValue(e.target.value)}>
                    { orderStatus.map(s => 
                      <option value={s.distributor} key={s.distributor}>{s.distributor}</option>
                    )}
                  </select>
                  <select className="form-control" style={{fontSize: '12px', marginRight: '10px', width: '100px'}} value={selectVendorValue} onChange={(e) => setSelectVendorValue(e.target.value)}>
                    { shippingVendorOptions.map(s => 
                      <option value={s.value} key={s.value}>{s.label}</option>
                    )}
                  </select>
                  <input type="text" className="form-control" style={{fontSize: '12px', width: '200px'}} onChange={(e) => determineShippingVendor(e.target.value)}/>
                  <FaCheck className="edit-icon" style={{marginTop: '8px'}} onClick={() => saveTracking()} />
                </div>

              </React.Fragment> 
            }
            { orderTracking.length === 0 &&
              <div className="carrier">None</div>
            }
            { orderTracking.length > 0 &&
              <table>
                <tbody>
                  { orderTracking.map((t, i) =>
                    <tr key={i}>
                      <React.Fragment>
                        <td className="carrier">{t.carrier}</td>
                        <td className="category-text pointer">
                          <span onClick={() => navigator.clipboard.writeText(t.tracking_number)}>{t.tracking_number}</span>
                          <span>
                            { t.carrier === 'FedEx' &&
                              <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://fedex.com/fedextrack/?trknbr=${t.tracking_number}`, '_blank')} />
                            }
                            { t.carrier === 'UPS' &&
                              <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${t.tracking_number}`, '_blank')} />
                            }
                            { t.carrier === 'USPS' &&
                              <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${t.tracking_number}`, '_blank')} />
                            }
                          </span>
                        </td>
                      </React.Fragment>
                    </tr>
                  )}
                </tbody>
              </table>
            }
          </Col>
          <Col lg={12}>
            <div className="section-header" style={{marginTop: '20px'}}> Parts Breakdown</div>
          </Col>
          <hr style={{marginTop: '5px'}}/>
          { orderStatus.length > 0 && orderStatus.map((o, i) =>
            <React.Fragment key={i}>
              { o.error === 1 &&
                <Col lg={12} style={{textAlign: 'right'}}>
                  <Button size="sm" color="outline-danger" onClick={() => clearError(o)}>Clear Error</Button>
                </Col>
              }
              <Col lg={12} style={{marginBottom: '10px'}}>
                <div><span className="distributor-header">{
                  o.distributor ? o.distributor : 
                    <React.Fragment>
                      { inputFieldDistributor === i &&
                        <React.Fragment>
                          <input type="text" style={{fontSize: '12px', width: '100px'}} onChange={(e) => setInputValue(e.target.value)}/>
                          <FaCheck className="edit-icon" onClick={() => saveDistributor(o, i)} />
                        </React.Fragment> 
                      }
                      { inputFieldDistributor !== i &&
                        <React.Fragment>
                          <span style={{color: 'red'}}>Needs distributor</span>
                          { !inputField && !inputFieldItemCost &&
                            <span><FaEdit className="edit-icon" onClick={() => showInputFieldDistributor(i)} /></span>
                          }
                        </React.Fragment> 
                      }
                    </React.Fragment> 
                } - {
                  o.order_number ? o.order_number : 
                    <React.Fragment>
                      { inputField === i &&
                        <React.Fragment>
                          <input type="text" style={{fontSize: '12px', width: '100px'}} onChange={(e) => setInputValue(e.target.value)}/>
                          <FaCheck className="edit-icon" onClick={() => saveDistributorOrderNumber(o, i)} />
                        </React.Fragment> 
                      }
                      { inputField !== i &&
                        <React.Fragment>
                          <span style={{color: 'red'}}>Needs order number</span>
                          { !inputFieldDistributor && !inputFieldItemCost &&
                            <span><FaEdit className="edit-icon" onClick={() => showInputField(i)} /></span>
                          }
                        </React.Fragment> 
                      }
                    </React.Fragment> 
                }</span></div>
                <table>
                  <tbody>
                    <tr>
                      <td className="category-titles">Received:</td>
                      <td className="category-text">{o.received === 1 ? o.received_ts : ''}</td>
                      <td className="category-titles">Completed:</td>
                      <td className="category-text">{o.completed === 1 ? o.completed_ts : ''}</td>
                      <td className="category-titles">Shipped:</td>
                      <td className="category-text">{o.shipped === 1 ? o.shipped_ts : ''}</td>
                      <td className="category-titles">Tracking:</td>
                      <td className="category-text">{o.seller_cloud_tracking === 1 ? o.seller_cloud_tracking_ts : ''}</td>
                    </tr>
                  </tbody>
                </table>  
              </Col>
              <Col lg={12}>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th style={{width: '120px'}}>Part No</th>
                      <th>Description</th>
                      <th>Qty</th>
                      <th>Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    { o.items.map((item, ii) =>
                      <tr key={item.product_id}>
                        <td style={{width: '155px', cursor: 'pointer'}} onClick={() => navigator.clipboard.writeText(item.product_id)}><span className="category-blue">{item.product_id}</span> {looking ? <ImSpinner10 icon="spinner" className="spinner" style={{marginBottom: '4px'}} /> : <FaSearch className="edit-icon" style={{marginBottom: '4px'}} onClick={async () => {
                          setLooking(true);
                          const productResults = await productsService.productLookup(item.product_id);
                          const productInfo = productResults.product_info.map(r => new ProductInfo(r));
                          setLooking(false);
                          dispatch(openProductLookup(productInfo));
                        }} />}</td>
                        <td>{item.sales_description}</td>
                        <td style={{textAlign: 'center'}}>{item.qty}</td>
                        <td style={{textAlign: 'center'}}>{ 
                          item.cost ? item.cost : 
                            <React.Fragment>
                              { inputFieldItemCost === ii &&
                                <React.Fragment>
                                  <input type="number" style={{fontSize: '12px', width: '100px'}} onChange={(e) => setInputValue(e.target.value)}/>
                                  <FaCheck className="edit-icon" onClick={() => saveItemCost(o, i, ii)} />
                                </React.Fragment> 
                              }
                              { inputFieldItemCost !== ii &&
                                <React.Fragment>
                                  <span style={{color: 'red'}}>Needs cost</span>
                                  { !inputFieldDistributor && !inputField &&
                                    <FaEdit className="edit-icon" style={{marginTop: '2px'}} onClick={() => showInputFieldItemCost(i)} />
                                  }
                                </React.Fragment> 
                              }
                            </React.Fragment>
                        }</td>
                      </tr>
                    )}
                  </tbody>
                </Table>  
              </Col>
            </React.Fragment>
          )}
          <Col lg={12}>
            <div className="section-header">Sales Breakdown</div>
          </Col>
          <hr style={{marginTop: '5px'}}/>
          <Col lg={12}>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Distributor</th>
                  <th>Time of Order</th>
                  <th style={{width: '55px'}}>Total</th>
                  <th style={{width: '55px'}}>Tax</th>
                  <th style={{width: '55px'}}>Cost</th>
                  <th style={{width: '55px'}}>Shipping</th>
                  <th style={{width: '55px'}}>Fees</th>
                  <th style={{width: '55px'}}>Channel</th>
                  <th style={{width: '55px'}}>Net</th>
                </tr>
              </thead>
              <tbody>
                { orderProfitInfo.map(o =>
                  <tr key={o.id}>
                    <td style={{textAlign: 'center'}}>{o.distributor}</td>
                    <td style={{textAlign: 'center'}}>{o.time_of_order}</td>
                    <td style={{textAlign: 'right'}}>{o.total}</td>
                    <td style={{textAlign: 'right'}}>{o.total_tax}</td>
                    <td style={{textAlign: 'right'}}>{o.product_cost}</td>
                    <td style={{textAlign: 'right'}}>{o.shipping_cost}</td>
                    <td style={{textAlign: 'right'}}>{o.extra_fees}</td>
                    <td style={{textAlign: 'right'}}>{o.channel_fee}</td>
                    <td style={{textAlign: 'right'}}>{o.net_profit}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col lg={12}>
          { orderStatus.length > 0 && orderStatus[0].cancelled === 0 &&
            <Button size="sm" className="float-left" color="outline-danger" onClick={() => cancelOrder()}>Cancel Order</Button>
          }
          <Button size="sm" className="float-right" color="outline-secondary" onClick={() => dispatch(openOrderDetail())}>Close</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default OrderDetail;