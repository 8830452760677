import moment from 'moment';
import React from 'react';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';

export default class FeedvisorErrorsModel {
  constructor(data) {
    this.id = data.id;
    this.vendor_name = data.vendor_name;
    this.brand = data.brand;
    this.sku = data.sku;
    this.upc_ean = data.upc_ean;
    this.prep = data.prep;
    this.price = data.price;
    this.reprice = data.reprice == 1 ? <FaRegCheckCircle style={{fontSize: '15px', marginBottom: '2px', color: 'green'}}/> : <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
    this.asin = data.asin;
    this.cost = data.cost;
    this.map = data.map;
    this.special_price = data.special_price;
    this.description = data.description;
    this.do_not_load = data.do_not_load == 1 ? <FaRegCheckCircle style={{fontSize: '15px', marginBottom: '2px', color: 'green'}}/> : <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
    this.order_exclude = data.order_exclude == 1 ? <FaRegCheckCircle style={{fontSize: '15px', marginBottom: '2px', color: 'green'}}/> : <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
    this.discontinued = data.discontinued == 1 ? <FaRegCheckCircle style={{fontSize: '15px', marginBottom: '2px', color: 'green'}}/> : <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
    this.prep_required = data.prep_required == 1 ? <FaRegCheckCircle style={{fontSize: '15px', marginBottom: '2px', color: 'green'}}/> : <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
    this.min_stock = data.min_stock;
    this.floor_price = data.floor_price;
    this.ceiling_price = data.ceiling_price;
    this.feedvisor_updated_ts = data.feedvisor_updated_ts ? moment(new Date(data.feedvisor_updated_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    const error = data.error_response ? JSON.parse(data.error_response) : null;
    if (error && error.error) {
      this.error_response = error.error;
    } else {
      this.error_response = error;
    }
  }
}