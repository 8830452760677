import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let priceTrackerService = {};

priceTrackerService.sellerViolations = async (vendor, brand) => {
  try {
    const results = await axios.get(`${endpoints.api}/price-tracker/seller-violations?vendor=${vendor}&brand=${brand}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

priceTrackerService.violationDetail = async (vendor, brand, sellerId) => {
  try {
    const results = await axios.get(`${endpoints.api}/price-tracker/violation-detail?vendor=${vendor}&brand=${brand}&seller_id=${sellerId}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

priceTrackerService.skuViolations = async (vendor, brand) => {
  try {
    const results = await axios.get(`${endpoints.api}/price-tracker/sku-violations?vendor=${vendor}&brand=${brand}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

priceTrackerService.skuViolationDetail = async (vendor, brand, asin) => {
  try {
    const results = await axios.get(`${endpoints.api}/price-tracker/sku-violation-detail?vendor=${vendor}&brand=${brand}&asin=${asin}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

priceTrackerService.bulkViolationList = async (vendor, brand) => {
  try {
    const results = await axios.get(`${endpoints.api}/price-tracker/bulk-violation-list?vendor=${vendor}&brand=${brand}`, { withCredentials: true, responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([results.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Violation List.csv`); // or any other filename
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

priceTrackerService.activeListingList = async (vendor, brand, sellerId, sellerName) => {
  try {
    const results = await axios.get(`${endpoints.api}/price-tracker/active-listing-list?vendor=${vendor}&brand=${brand}&seller_id=${sellerId}`, { withCredentials: true, responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([results.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${sellerName} Active Listings.csv`); // or any other filename
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

priceTrackerService.authorizeSeller = async (vendor, sellerId, selection) => {
  try {
    const results = await axios.put(`${endpoints.api}/price-tracker/authrorized-seller`, {vendor, seller_id: sellerId, selection }, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default priceTrackerService;