import React from 'react';
import { Store } from 'react-notifications-component';
import { Button, Col } from 'reactstrap';
import { FaRegCopy } from 'react-icons/fa';

const Comms = (props) => {

  const copyToClipboard = () => {
    let r = document.createRange();
    const element = document.getElementById('email_text');
    r.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    Store.addNotification({
      title: 'Copy To Clipboard',
      message: 'Communication copied to clipboard.',
      type: 'default',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 2000,
        onScreen: false
      }
    });
  };

  return (
    <React.Fragment>
      <Col lg={6}>
        <div className="section-header"> Communication</div>
      </Col>
      <Col lg={6} style={{textAlign: 'right'}}>
        <Button size="sm" color="outline-primary" onClick={() => copyToClipboard()}><FaRegCopy style={{marginBottom: '3px'}} /></Button>
      </Col>
      <Col lg={12}>
        <hr style={{marginTop: '5px'}}/>
      </Col>
      <Col lg={12}>
        <div id="email_text">
          <p className="comms-text">We have not received our FBA removal return.  It has been over 90 days since it was shipped with the following details:</p>
          <div className="comms-text"><span className="comms-headers">Removal order ID</span><span>: {props.orderId}</span></div>
          <div className="comms-text"><span className="comms-headers">Shipment ID</span><span>: {props.shipmentId}</span></div>
          <div className="comms-text"><span className="comms-headers">Tracking Number</span><span>: {props.trackingNumber}</span></div>
          <div className="comms-text"><span className="comms-headers">Shipment date</span><span>: {props.requestTs}</span></div>
          <div className="comms-text"><span className="comms-headers">Days from Shipment</span><span>: {props.daysOld}</span></div>
          <div className="comms-text"><span className="comms-headers">Contents (ASINs) / Quantity of SKUs/units</span>: </div>
          <p></p>
          { props.commsData.map(r => 
            <div key={r.fnsku} className="comms-text">{r.sku} || {r.item_name} || {r.fnsku} || {r.shipped_quantity}</div>
          )}
          <p></p>
          
          <p className="comms-text">Please reimburse us for the lost shipment/units or advise us on further steps for the lost outbound FBA returns.  Attached is a screenshot showing shipment details.</p>
        </div>
      </Col>
    </React.Fragment>

  );
};

export default Comms;
