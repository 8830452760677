export default class ProfitReportDailyModel {
  constructor(data) {
    this.amazon_count = data.amazon_count;
    this.channel_fee = (Math.round(data.channel_fee * 100) / 100).toFixed(2);
    this.date = data.date;
    this.month = data.month;
    this.ebay_count = data.ebay_count;
    this.shopify_count = data.shopify_count;
    this.hlc_count = data.hlc_count;
    this.tgc_count = data.tgc_count;
    this.jbi_count = data.jbi_count;
    this.oh_count = data.oh_count;
    this.net_profit = (Math.round(data.net_profit * 100) / 100).toFixed(2);
    this.order_count = data.order_count;
    this.product_cost = (Math.round(data.product_cost * 100) / 100).toFixed(2);
    this.hlc_count = data.hlc_count;
    this.qbp_count = data.qbp_count;
    this.shipping_cost = (Math.round(data.shipping_cost * 100) / 100).toFixed(2);
    this.tax = (Math.round(data.tax * 100) / 100).toFixed(2);
    this.total = (Math.round(data.total * 100) / 100).toFixed(2);
    this.sale_total = (Math.round(data.sale_total * 100) / 100).toFixed(2);
  }
}