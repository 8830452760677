import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IoIosCloudUpload } from 'react-icons/io';
import { openReturnTrackingImageUpload } from '../../redux/actions';
import styled from 'styled-components';
import Uploady, { useItemStartListener, useItemFinalizeListener } from '@rpldy/uploady';
import UploadDropZone from '@rpldy/upload-drop-zone';
import withPasteUpload from '@rpldy/upload-paste';
import UploadPreview from '@rpldy/upload-preview';
import endpoints from '../../endpoints/endpoints';
import { Store } from 'react-notifications-component';

const PreviewContainer = styled.div`
  margin-top: 20px;

  img {
    max-width: 400px;
  }
`;

const StyledDropZone = styled(UploadDropZone)`
  width: 400px;
  height: 200px;
  border: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const StyledInput = styled.input`
  width: 400px;
  height: 30px;
  font-size: 16px;
  margin: 10px 0;
`;

const PasteUploadDropZone = withPasteUpload(StyledDropZone);
const PasteInput = withPasteUpload(StyledInput);

const ReturnTrackingImageUpload = (props) => {
  const dispatch = useDispatch();
  const trackingNumber = useSelector(state => state.open_return_tracking_image_upload_info);

  const UploadStatus = () => {
    const [status, setStatus] = useState(null);
  
    useItemStartListener(() => setStatus('Uploading...'));
    useItemFinalizeListener(() => {
      setStatus(null);
      Store.addNotification({
        title: 'File Upload',
        message: 'File uploaded successfully.',
        type: 'default',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      props.refresh();
    });
  
    return status;
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => dispatch(openReturnTrackingImageUpload())} backdrop="static" size="md">
      <ModalHeader toggle={() => dispatch(openReturnTrackingImageUpload())}>
        <IoIosCloudUpload style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Image Upload
      </ModalHeader>
      <ModalBody className="return-tracking-history">
        <Row>
          <Col lg={12} style={{marginLeft: '33px'}} >
            <Uploady multiple={false} destination={{ url: `${endpoints.api}/return-tracking/upload` }}>
              <PasteUploadDropZone params={{ tracking_number: trackingNumber }}>
                Drop & Drop File Here
              </PasteUploadDropZone>
              <div style={{marginLeft: '185px'}}>OR</div>
              <PasteInput className="form-control" params={{ tracking_number: trackingNumber }} extraProps={{ placeholder: 'Paste file here' }} />
              <UploadStatus />
              <PreviewContainer>
                <UploadPreview />
              </PreviewContainer>
            </Uploady>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" style={{float: 'right'}} color="outline-secondary" onClick={() => dispatch(openReturnTrackingImageUpload())}>Close</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default ReturnTrackingImageUpload;