import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let singleSkuShipmentsService = {};

singleSkuShipmentsService.getShipments = async (startDate, endDate, status, user) => {
  try {
    const results = await axios.get(`${endpoints.api}/single-sku-shipments/shipments?start_date=${startDate}&end_date=${endDate}&status=${status}&admin=${user.admin}&user_id=${user.id}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

singleSkuShipmentsService.productSearch = async (product, condition) => {
  try {
    const results = await axios.get(`${endpoints.api}/single-sku-shipments/product-search?product=${product}&condition=${condition}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

singleSkuShipmentsService.createUpdateShipment = async (product, submission, createSku, user) => {
  try {
    const results = await axios.post(`${endpoints.api}/single-sku-shipments/create-update-shipment`, {product, submission, create_sku: createSku, user}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

singleSkuShipmentsService.requestAdd = async (product, user) => {
  try {
    const results = await axios.post(`${endpoints.api}/single-sku-shipments/request-add`, {product, user}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

singleSkuShipmentsService.deleteShipment = async (shipmentIdInternal) => {
  try {
    const results = await axios.put(`${endpoints.api}/single-sku-shipments/delete-shipment`, {shipment_id_internal: shipmentIdInternal}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default singleSkuShipmentsService;