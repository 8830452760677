import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FaWarehouse, FaLockOpen, FaLock, FaRegListAlt } from 'react-icons/fa';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import { FiPrinter } from 'react-icons/fi';
import { TiExportOutline } from 'react-icons/ti';
import { openCreateEditRemoval, openRemovalsBatchDetail } from '../../redux/actions';
import ReactTooltip from 'react-tooltip';
import removalsService from '../../services/removals.service';
import RemovalPalletModel from '../../models/removal_pallet.model';
import { Store } from 'react-notifications-component';
import RemovalItem from '../../models/removal_item.model';
import Barcode from 'react-barcode';
import * as html2canvas from 'html2canvas';
import moment from 'moment';

const CreateEditRemoval = (props) => {
  const dispatch = useDispatch();
  // const info = useSelector(state => state.open_create_edit_removal_info);
  const user = useSelector(state => state.user);
  const removalStatusFilter = useSelector(state => state.removals_status_filter);
  const [palletSearching, setPalletSearching] = useState(false);
  const [searching, setSearching] = useState(false);
  const [changeTimer, setChangeTimer] = useState(null);
  const [goodPallet, setGoodPallet] = useState(new RemovalPalletModel());
  const [badPallet, setBadPallet] = useState(new RemovalPalletModel());
  const [product, setProduct] = useState(new RemovalItem());
  const [productSearchString, setProductSearchString] = useState('');
  const [notFound, setNotFound] = useState(false);
  const addPalletRef = useRef(null);

  const handleClose = () => {
    dispatch(openCreateEditRemoval());
    props.refresh(removalStatusFilter.value);
  };

  const handlePalletScan = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }

    if (name === 'good' && value !== goodPallet.pallet_name) {
      setGoodPallet(new RemovalPalletModel({pallet_name: value}));
      const timer = setTimeout(async () => {
        try {
          setPalletSearching(true);
          const results = await removalsService.palletSearch(value, name);
          if (results.pallet_name === 'not_found') {
            Store.addNotification({
              title: 'Pallet Search',
              message: 'Pallet not found.',
              type: 'warning',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                onScreen: false
              }
            });
          } else {
            setGoodPallet(new RemovalPalletModel(results));
          }
          setPalletSearching(false);
          ReactTooltip.rebuild();
        } catch (e) {
          console.log(e);
        }
      }, 400);
      setChangeTimer(timer);
    } else if (name === 'bad' && value !== badPallet.pallet_name) {
      setBadPallet(new RemovalPalletModel({pallet_name: value}));
      const timer = setTimeout(async () => {
        try {
          setPalletSearching(true);
          const results = await removalsService.palletSearch(value, name);
          if (results.pallet_name === 'not_found') {
            Store.addNotification({
              title: 'Pallet Search',
              message: 'Pallet not found.',
              type: 'warning',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                onScreen: false
              }
            });
          } else {
            setBadPallet(new RemovalPalletModel(results));
          }
          setPalletSearching(false);
          ReactTooltip.rebuild();
        } catch (e) {
          console.log(e);
        }
      }, 400);
      setChangeTimer(timer);
    }
  };

  const handleScan = (e) => {
    const value = e.target.value;

    setProductSearchString(value);
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }

    if (value !== productSearchString) {
      const timer = setTimeout(async () => {
        try {
          setSearching(true);
          setNotFound(false);
          const results = await removalsService.productSearch(value);
          if (results.id === 0) {
            setNotFound(true);
            const newProduct = new RemovalItem();
            newProduct.not_found = 1;
            newProduct.sku = productSearchString;
            e.target.select();
            setProduct(newProduct);
            setSearching(false);
          } else {
            results.product_inspection = 'plc';
            setProduct(new RemovalItem(results));
          }

          if (addPalletRef) addPalletRef.current.focus();
          
          setSearching(false);
        } catch (e) {
          console.log(e);
        }
      }, 400);
  
      setChangeTimer(timer);
    }
  };

  const createNewPallet = async (palletType) => {
    try {
      if (palletType === 'good') {
        const newPallet = await removalsService.createNewPallet(palletType, user);
        setGoodPallet(new RemovalPalletModel(newPallet));
      } else if (palletType === 'bad') {
        const newPallet = await removalsService.createNewPallet(palletType, user);
        setBadPallet(new RemovalPalletModel(newPallet));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const completePallet = async (palletType) => {
    try {
      if (palletType === 'good') {
        const newPallet = JSON.parse(JSON.stringify(goodPallet));
        newPallet.complete = newPallet.complete === 0 ? 1 : 0;
        removalsService.updatePallet(newPallet, 'complete');
        setGoodPallet(newPallet);
      } else if (palletType === 'bad') {
        const newPallet = JSON.parse(JSON.stringify(badPallet));
        newPallet.complete = newPallet.complete === 0 ? 1 : 0;
        removalsService.updatePallet(newPallet, 'complete');
        setBadPallet(newPallet);
      }

      Store.addNotification({
        title: 'Complete Pallet',
        message: 'Pallet completed successfully.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleProductInspection = (inspection) => {
    const newProduct = JSON.parse(JSON.stringify(product));
    newProduct.product_inspection = inspection;
    setProduct(newProduct);
  };

  const handleUpdateQty = (e) => {
    const value = e.target.value;
    const newProduct = JSON.parse(JSON.stringify(product));
    newProduct.qty = value;
    setProduct(newProduct);
  };

  const addToPallet = async (pallet) => {
    try {
      await removalsService.addToPallet(product, pallet, user);
      setNotFound(false);
      setProduct(new RemovalItem());
      setProductSearchString('');
      document.getElementById('scan_item').focus();
      Store.addNotification({
        title: 'Add To Pallet',
        message: 'Product Added successfully.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const printBarcodeLabel = (printId) => {    
    var element = document.getElementById(printId); 
    element.style.display = 'block';
    html2canvas(element).then((canvas) => {
      var imgData = canvas.toDataURL('image/png');
      var link = document.createElement('a');

      document.body.appendChild(link);
  
      link.setAttribute('href', imgData);

      let filename;
      if (printId === 'upc_ean_barcode') {
        filename = 'upc_ean.png';
      } else if (printId === 'good_pallet_label') {
        filename = 'good_batch_label.png';
      } else if (printId === 'bad_pallet_label') {
        filename = 'bad_batch_label.png';
      }

      link.setAttribute('download', filename);
      link.click();
      element.style.display = 'none';
    });
  };

  //function to update pallet description that does not make api call until no typing for 1 seconds
  const handleDescriptionChange = (e, palletType) => {
    const name = e.target.name;
    const value = e.target.value;
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }

    let newPallet;
    if (palletType === 'good') {
      newPallet = JSON.parse(JSON.stringify(goodPallet));
      if (name === 'good_pallet_description') {
        newPallet.pallet_description = value;
      } else if (name === 'good_pallet_notes') {
        newPallet.pallet_notes = value;
      }
      setGoodPallet(newPallet);
    } else if (palletType === 'bad') {
      newPallet = JSON.parse(JSON.stringify(badPallet));
      if (name === 'bad_pallet_description') {
        newPallet.pallet_description = value;
      } else if (name === 'bad_pallet_notes') {
        newPallet.pallet_notes = value;
      }
      setBadPallet(newPallet);
    }

    const timer = setTimeout(async () => {
      try {
        removalsService.updatePallet(newPallet, 'pallet_description');
      } catch (e) {
        console.log(e);
      }
    }, 1000);

    setChangeTimer(timer);
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="lg" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FaWarehouse style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Create / Edit Removals
      </ModalHeader>
      <ModalBody className="create-edit-removal">
        <ReactTooltip />
        <Row>
          <Col lg={6}>
            <div className="pallet-container">
              <div className="pallet-header text-success">Good Batch </div>
              { goodPallet.complete === 1 && goodPallet.id !== 0 &&
                <span className="complete-text">***Complete****</span>
              }
              { goodPallet.id !== 0 &&
                <span>
                  { goodPallet.complete === 1 &&
                    <FaLock className="complete-lock-locked" style={{marginRight: '10px'}} data-tip="Complete Pallet" onClick={() => completePallet('good')}/>
                  }
                  { goodPallet.complete === 0 &&
                    <FaLockOpen className="label-print" style={{marginRight: '10px'}} data-tip="Complete Pallet" onClick={() => completePallet('good')}/>
                  }
                  <FaRegListAlt className="label-print" style={{marginRight: '10px'}} data-tip="Removal Batch Detail" onClick={() => dispatch(openRemovalsBatchDetail(goodPallet))}/>
                  <FiPrinter className="label-print" data-tip="Print Box / Pallet Label" onClick={() => printBarcodeLabel('good_pallet_label')}/>
                </span>
              }
            </div>
            <hr style={{marginTop: '5px'}}/>
            <div className="pallet-container">
              <Input id="good" name="good" autoFocus={true} placeholder="Scan barcode" style={{fontSize: '12px', width: '150px'}} type="text" value={goodPallet.pallet_name} onChange={(e) => handlePalletScan(e)} />
              <span style={{marginTop: '2px'}}>or</span>
              <Button size="sm" style={{width: '150px'}} color="outline-primary" onClick={() => createNewPallet('good')}>Create New Pallet</Button>
            </div>
          </Col>
          <Col lg={6}>
            <div className="pallet-container">
              <div className="pallet-header text-secondary">Bad Batch</div>
              { badPallet.complete === 1 && badPallet.id !== 0 &&
                <span className="complete-text">***Complete****</span>
              }
              { badPallet.id !== 0 &&
                <span>
                  { badPallet.complete === 1 &&
                    <FaLock className="complete-lock-locked" style={{marginRight: '10px'}} data-tip="Complete Pallet" onClick={() => completePallet('bad')}/>
                  }
                  { badPallet.complete === 0 &&
                    <FaLockOpen className="label-print" style={{marginRight: '10px'}} data-tip="Complete Pallet" onClick={() => completePallet('bad')}/>
                  }
                  <FaRegListAlt className="label-print" style={{marginRight: '10px'}} data-tip="Removal Batch Detail" onClick={() => dispatch(openRemovalsBatchDetail(badPallet))}/>
                  <FiPrinter className="label-print" data-tip="Print Box / Pallet Label" onClick={() => printBarcodeLabel('bad_pallet_label')}/>
                </span>
              }
            </div>
            <hr style={{marginTop: '5px'}}/>
            <div className="pallet-container">
              <Input id="bad" name="bad" placeholder="Scan barcode" style={{fontSize: '12px', width: '150px'}} type="text" value={badPallet.pallet_name} onChange={(e) => handlePalletScan(e)} />
              <span style={{marginTop: '2px'}}>or</span>
              <Button size="sm" style={{width: '150px'}} color="outline-primary" onClick={() => createNewPallet('bad')}>Create New Pallet</Button>
            </div>
          </Col>
          <Col lg={12}>
            { palletSearching &&
              <div style={{fontSize: '14px', textAlign: 'center'}}>Searching...</div>
            }
          </Col>
          { goodPallet.id !== 0 &&
            <div style={{display: 'none', height: '6in', width: '4in'}} id="good_pallet_label">
              <div style={{textAlign: 'left', marginLeft: '15px', marginTop: '10px', fontSize: '20px', fontWeight: 'bold'}}>
                <div>Good Removals</div>
                <div>{moment().format('MM/DD/YYYY')}</div>
                <div>Bicycle Addiction</div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div style={{textAlign: 'center'}}>
                <Barcode value={`${goodPallet.pallet_name}`} />
              </div>
            </div>
          }
          { badPallet.id !== 0 &&
            <div style={{display: 'none', height: '6in', width: '4in'}} id="bad_pallet_label">
              <div style={{textAlign: 'left', marginLeft: '15px', marginTop: '10px', fontSize: '20px', fontWeight: 'bold'}}>
                <div>Bad Removals</div>
                <div>{moment().format('MM/DD/YYYY')}</div>
                <div>Bicycle Addiction</div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div style={{textAlign: 'center'}}>
                <Barcode value={`${badPallet.pallet_name}`} />
              </div>
            </div>
          }
        </Row>
        { goodPallet.id !== 0 &&  badPallet.id !== 0 &&
          <Row>
            <Col lg={12} style={{marginTop: '10px'}}>
              <div className="section-header">Batch Info</div>
              <hr style={{marginTop: '5px'}}/>
            </Col>
            <Col lg={6}>
              <div>
                <FormGroup>
                  <Label for="good_pallet_description" className="input-label">Description</Label>
                  <Input id="good_pallet_description" name="good_pallet_description" placeholder="Enter description" style={{fontSize: '12px'}} type="text" value={goodPallet.pallet_description} onChange={(e) => handleDescriptionChange(e, 'good')}/>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <Label for="good_pallet_notes" className="input-label">Notes</Label>
                  <Input id="good_pallet_notes" name="good_pallet_notes" placeholder="Enter notes" style={{fontSize: '12px'}} type="textarea" value={goodPallet.pallet_notes} onChange={(e) => handleDescriptionChange(e, 'good')}/>
                </FormGroup>
              </div>
            </Col>
            <Col lg={6}>
              <div >
                <FormGroup>
                  <Label for="bad_pallet_description" className="input-label">Description</Label>
                  <Input id="bad_pallet_description" name="bad_pallet_description" placeholder="Enter description" style={{fontSize: '12px'}} type="text" value={badPallet.pallet_description} onChange={(e) => handleDescriptionChange(e, 'bad')}/>
                </FormGroup>

              </div>
              <div>
                <FormGroup>
                  <Label for="bad_pallet_notes" className="input-label">Notes</Label>
                  <Input id="bad_pallet_notes" name="bad_pallet_notes" placeholder="Enter notes" style={{fontSize: '12px'}} type="textarea" value={badPallet.pallet_notes} onChange={(e) => handleDescriptionChange(e, 'bad')}/>
                </FormGroup>
              </div>
            </Col>
          </Row>
        }
        { goodPallet.id !== 0 &&  badPallet.id !== 0 && goodPallet.complete === 0 && badPallet.complete === 0 &&
          <Row>
            <Col lg={12} style={{marginTop: '10px'}}>
              <div className="section-header" style={{marginTop: '10px'}}> Item Scanning</div>
              <hr style={{marginTop: '5px'}}/>
            </Col>
            <Col lg={12} className="box">
              <FormGroup>
                <Label for="scan_item" className="input-label">UPC/LPN/ASIN/FNSKU </Label>
                <Input id="scan_item" name="scan_item" autoFocus={true} placeholder="Scan/Enter UPC/LPN/ASIN/FNSKU" style={{fontSize: '12px', width: '250px', textAlign: 'center'}} type="text" value={productSearchString} onChange={(e) => handleScan(e)}/>
                { searching &&
                  <div style={{fontSize: '14px'}}>Searching...</div>
                }
                { notFound &&
                  <div className="not-found">Item Not Found</div>
                }
              </FormGroup>
            </Col>
            { product.id !== 0 &&
              <React.Fragment>
                <Col lg={12}>
                  <div className="section-header" style={{marginTop: '5px'}}> {product.product_description}</div>
                  <hr style={{marginTop: '5px'}}/>
                </Col>
                <Col lg={8}>
                  <table>
                    <tbody>
                      <tr>
                        <td className="category-titles">Brand:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.brand)}>
                          <span>{product.brand}</span>
                        </td>
                      </tr>
                      { product.lpn !== '' &&
                        <tr>
                          <td className="category-titles">LPN:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.lpn)}>
                            <span>{product.lpn}</span>
                          </td>
                        </tr>
                      }
                      <tr>
                        <td className="category-titles">UPC/EAN:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.upc_ean)}>
                          <span>{product.upc_ean}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">MPN:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.manufacturer_part_no)}>
                          <span>{product.manufacturer_part_no}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">ASIN:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.asin)}>
                          <span>{product.asin}</span>
                          <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open(`https://www.amazon.com/gp/product/${product.asin}?psc=1`, '_blank')} /></span>
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">FNSKU:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.fnsku)}>
                          <span>{product.fnsku}</span>
                        </td>
                      </tr>
                      { user.admin === 1 &&
                        <React.Fragment>
                          <tr>
                            <td className="category-titles">Cost:</td>
                            <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.cost)}>
                              <span>{product.cost}</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="category-titles">Price:</td>
                            <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.price)}>
                              <span>{product.price}</span>
                            </td>
                          </tr>
                        </React.Fragment>
                      }
                    </tbody>
                  </table>
                </Col>
                <Col lg={4} style={{textAlign: 'center'}}>
                  <img src={product.image} className="image"  onClick={() => window.open(product.image)}/>
                </Col>
                <Col lg={12}>
                  <div className="section-header" style={{marginTop: '10px'}}> Product Inspection</div>
                  <hr style={{marginTop: '5px'}}/>
                </Col>
                <Col lg={12} style={{textAlign: 'center'}}>
                  <div className="info-text text-danger" style={{marginBottom: '20px'}}>Please inspect the product and select one of the below options</div>
                  { product.product_inspection === 'plc' && 
                    <Button size="md" style={{width: '130px', marginRight: '10px'}} color="primary">Product Looks Correct and New</Button>
                  }
                  { product.product_inspection !== 'plc' && 
                    <Button size="md" style={{width: '130px', marginRight: '10px'}} color="outline-primary" onClick={() => handleProductInspection('plc')}>Product Looks Correct and New</Button>
                  }
                  { product.product_inspection === 'pbnf' && 
                    <Button size="md" style={{width: '150px', marginRight: '10px'}} color="primary">Product or Packaging is Bad or Not Fixable</Button>
                  }
                  { product.product_inspection !== 'pbnf' && 
                    <Button size="md" style={{width: '150px', marginRight: '10px'}} color="outline-primary" onClick={() => handleProductInspection('pbnf')}>Product or Packaging is Bad or Not Fixable</Button>
                  }
                </Col>
                { (product.product_inspection === 'plc' || product.product_inspection === 'pbnf') &&
                  <React.Fragment>
                    <Col lg={12}>
                      <div className="section-header" style={{marginTop: '10px'}}> Placement Instructions</div>
                      <hr style={{marginTop: '5px'}}/>
                    </Col>
                    <Col lg={12} style={{textAlign: 'center'}}>
                      { product.product_inspection === 'plc' &&
                        <React.Fragment>
                          <div style={{textAlign: 'right', marginBottom: '5px'}}>
                            <Button size="sm" color="outline-secondary" style={{marginLeft: '5px'}} onClick={() => printBarcodeLabel('upc_ean_barcode')}><TiExportOutline style={{fontSize: '16px', marginBottom: '3px'}}/> Print UPC To Scan</Button>
                          </div>
                          <div className="info-text text-danger">Please place the item(s) on pallet <span style={{color: 'blue'}}>{goodPallet.pallet_name}</span>, input the quantity you are adding to the pallet, and click "Add to Pallet" below</div>
                          <div style={{display: 'none'}} id="upc_ean_barcode"><Barcode value={`${product.upc_ean}`} /></div>
                        </React.Fragment>
                      }
                      { product.product_inspection === 'pbnf' &&
                        <div className="info-text text-danger">Please place the item(s) on pallet <span style={{color: 'blue'}}>{badPallet.pallet_name}</span>, input the quantity you are adding to the pallet, and click "Add to Pallet" below</div>
                      }
                    </Col>
                    <Col lg={12} style={{marginTop: '10px'}}>
                      <div className="box">
                        <FormGroup>
                          <Input type="number" id="qty" name="qty" placeholder="Enter quantity" style={{fontSize: '12px', width: '120px'}} value={product.qty} onChange={(e) => handleUpdateQty(e)}/>
                          { product.product_inspection === 'plc' &&
                            <button ref={addPalletRef} style={{width: '120px', marginTop: '10px'}} type="button" className="btn btn-outline-primary btn-sm" onClick={() => addToPallet(goodPallet)}>Add to Pallet</button>
                          }
                          { product.product_inspection === 'pbnf' &&
                            <button ref={addPalletRef}  style={{width: '120px', marginTop: '10px'}} type="button" className="btn btn-outline-primary btn-sm" onClick={() => addToPallet(badPallet)}>Add to Pallet</button>
                          }
                        </FormGroup>
                      </div>
                    </Col>
                  </React.Fragment>
                }
              </React.Fragment>
            }
            { notFound &&
              <React.Fragment>
                <Col lg={12}>
                  <div className="section-header" style={{marginTop: '10px'}}> Placement Instructions</div>
                  <hr style={{marginTop: '5px'}}/>
                </Col>
                <Col lg={12} style={{textAlign: 'center'}}>
                  <div className="info-text text-danger">Please place the item(s) on pallet <span style={{color: 'blue'}}>{badPallet.pallet_name}</span>, input the quantity you are adding to the pallet, and click "Add to Pallet" below</div>
                </Col>
                <Col lg={12} style={{marginTop: '10px'}}>
                  <div className="box">
                    <FormGroup>
                      <Input type="number" id="qty" name="qty" placeholder="Enter quantity" style={{fontSize: '12px', width: '120px'}} value={product.qty} onChange={(e) => handleUpdateQty(e)}/>
                      <button style={{width: '120px', marginTop: '10px'}} type="button" className="btn btn-outline-primary btn-sm" onClick={() => addToPallet(badPallet)}>Add to Pallet</button>
                    </FormGroup>
                  </div>
                </Col>
              </React.Fragment>
            }
          </Row>
        }
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default CreateEditRemoval;