import moment from 'moment';
import OrderItem from './order_item.model';

export default class OrderStatusModel {
  constructor(data) {
    this.order_id = data.order_id;
    this.order_number = data.order_number;
    this.distributor = data.distributor ? data.distributor.toUpperCase() : null;
    this.received = data.received;
    this.received_ts = data.received_ts ? moment(new Date(data.received_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.completed = data.completed;
    this.completed_ts = data.completed_ts ? moment(new Date(data.completed_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.shipped = data.shipped;
    this.shipped_ts = data.shipped_ts ? moment(new Date(data.shipped_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.seller_cloud_tracking = data.seller_cloud_tracking;
    this.seller_cloud_tracking_ts = data.seller_cloud_tracking_ts ? moment(new Date(data.seller_cloud_tracking_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.error = data.error;
    this.cancelled = data.cancelled;
    this.cancelled_ts = data.cancelled_ts ? moment(new Date(data.cancelled_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.refunded = data.refunded;
    this.refunded_ts = data.refunded_ts ? moment(new Date(data.refunded_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.items = data.items.map(i => new OrderItem(i));
  } 
}