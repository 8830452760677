import React, { useCallback, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TiExportOutline } from 'react-icons/ti';
import { FaRegPlusSquare, FaCubes, FaRegPaperPlane, FaFilePdf  } from 'react-icons/fa';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {  openCreateEditBackstock, openCreateEditBackstockSingle, setBackstockOptionsFilter } from '../../redux/actions';
import moment from 'moment';
import './Backstock.css';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import CreateEditBackstockModal from './CreateEditBackstockModal';
import backstockService from '../../services/backstock.service';
import BackstockItem from '../../models/backstock_item.model';
import CreateEditBackstockSingleModal from './CreateEditBackstockSingleModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const { SearchBar } = Search;

const Backstock = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  // const [startDate, setStartDate] = useState(new Date(moment().subtract(12, 'months').startOf('month')));
  // const [endDate, setEndDate] = useState(new Date());
  const [selectedRows, setSelectedRows] = useState([]);
  const [backstockOptions] = useState([
    { value: 'shipment_created', label: 'Shipment Created' },
    { value: 'submitted', label: 'Submitted' },
    { value: 'processing', label: 'Processing' },
    { value: 'creating_shipment', label: 'Creating Shipment' },
    { value: 'complete', label: 'Complete' },
  ]);

  const backstockOptionsFilter = useSelector(state => state.backstock_options_filter);
  const openCreateEditBackstockToggle = useSelector(state => state.open_create_edit_backstock);
  const openCreateEditBackstockSingleToggle = useSelector(state => state.open_create_edit_backstock_single);
  const user = useSelector(state => state.user);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = useCallback(async (createNew) => {
    try {
      const results = await backstockService.getBackstock(backstockOptionsFilter.value);
      const newRows = results.map(r => new BackstockItem(r));
      if (backstockOptionsFilter.value === 'shipment_created') newRows.sort((a, b) => a.shelf_location.localeCompare(b.shelf_location));
      setRows(newRows);
      createNew ? dispatch(openCreateEditBackstock()) : null;
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [backstockOptionsFilter]);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
  };

  const generatePDF = () => {
    const doc = new jsPDF({ orientation: 'portrait', unit: 'in', format: 'letter' });
    doc.setFontSize(12);
    doc.text(`Pick List ${moment().format('MM/DD/YYYY h:mm A')}`, 0.5, 0.5);
  
    const tableColumns = [
      { header: 'Case #', dataKey: 'backstock_id' },
      { header: 'Shelf Loc', dataKey: 'shelf_location' },
      { header: 'Product Description', dataKey: 'product_description' },
      { header: 'UPC/EAN', dataKey: 'upc_ean' },
      { header: 'Check', dataKey: 'checkbox' }, // Checkbox column
    ];
  
    const data = rows.map(row => ({
      backstock_id: row.backstock_id,
      shelf_location: row.shelf_location,
      product_description: row.product_description.length > 40 ? `${row.product_description.substring(0, 40)}...` : row.product_description,
      upc_ean: row.upc_ean,
      checkbox: '', // Placeholder for checkbox
    })).sort((a, b) => a.shelf_location.localeCompare(b.shelf_location));
  
    doc.autoTable({
      head: [tableColumns.map(col => col.header)],
      body: data.map(row => tableColumns.map(col => row[col.dataKey])),
      startY: 1,
      theme: 'grid',
      styles: {
        lineWidth: 0.01, // Thinner borders
      },
      headStyles: {
        fillColor: [0, 123, 255], // Modern blue background
        textColor: [255, 255, 255], // White text
      },
      didDrawCell: (data) => {
        if (data.column.dataKey === 'checkbox' && data.cell.section === 'body') {
          doc.rect(data.cell.x + data.cell.padding('horizontal'), data.cell.y + data.cell.padding('vertical'), 0.2, 0.2); // Draw checkbox
        }
      },
    });
  
    doc.save(`pick_list ${moment().format()}.pdf`);
  };
  
  useEffect(() => {
    // Use this function to find duplicates and assign them colors based on your rows data
    const newColumns = [
      {
        text: 'Case #',
        dataField: 'backstock_id',
        headerStyle: {width: '65px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.backstock_id);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Shelf Loc',
        dataField: 'shelf_location',
        headerStyle: {width: '65px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.shelf_location);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'SKU',
        dataField: 'sku',
        headerStyle: {width: '140px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.sku);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'FNSKU',
        dataField: 'fnsku',
        headerStyle: {width: '140px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.fnsku);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'UPC/EAN',
        dataField: 'upc_ean',
        headerStyle: {width: '140px'},
        style: { textAlign: 'left', verticalAlign: 'middle', },
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.upc_ean);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Description',
        dataField: 'product_description',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.product_description);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Case Qty',
        dataField: 'case_qty',
        headerStyle: {width: '70px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.case_qty);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Weight',
        dataField: 'case_weight',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.case_weight);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Length',
        dataField: 'case_length',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.case_length);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Width',
        dataField: 'case_width',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.case_width);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Height',
        dataField: 'case_height',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.case_height);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'ToH',
        dataField: 'total_amazon_inventory',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.total_amazon_inventory);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'QoH',
        dataField: 'qoh',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.qoh);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Ord',
        dataField: 'demand',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.demand);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: '30d',
        dataField: 'thirty_day_total_sales',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.thirty_day_total_sales);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: '180d',
        dataField: 'one_hundred_eighty_day_total_sales',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (backstockOptionsFilter?.value === 'processing') {
              dispatch(openCreateEditBackstock(row));
            } else if (backstockOptionsFilter?.value === 'shipment_created' || backstockOptionsFilter?.value === 'complete') {
              dispatch(openCreateEditBackstock(row));
            } else {
              navigator.clipboard.writeText(row.one_hundred_eighty_day_total_sales);
            }
          }
        },
        filter: textFilter({className: 'filter-box'})
      }
    ];

    ReactTooltip.rebuild();
    setColumns(newColumns);
  }, [rows, setColumns]);

  useEffect(() => {
    if (!backstockOptionsFilter) {
      // Assume this action updates backstockOptionsFilter in the redux store
      if (user.admin === 1) {
        dispatch(setBackstockOptionsFilter(backstockOptions.filter(o => o.value === 'submitted')[0]));
      } else {
        dispatch(setBackstockOptionsFilter(backstockOptions.filter(o => o.value === 'shipment_created')[0]));
      }
    } else {
      getData(); // Call getData only after setting the initial backstockOptionsFilter
    }
  }, [dispatch, backstockOptionsFilter, backstockOptions, getData]);

  const selectBackstockOption = (e) => {
    dispatch(setBackstockOptionsFilter(e));
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 34,
      minHeight: 34,
    }),
    singleValue: (provided) => {
      return { ...provided, paddingBottom: '5px' };
    }
  };

  const selectRow = {
    mode: 'checkbox',
    selected: selectedRows.map(row => row.backstock_id),
    hideSelectColumn: backstockOptionsFilter && backstockOptionsFilter.value === 'submitted' ? false : true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRows(prev => [...prev, row]);
      } else {
        setSelectedRows(prev => prev.filter(r => r.backstock_id !== row.backstock_id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedRows(rows);
      } else {
        setSelectedRows([]);
      }
    }
  };

  // const rowStyle = (row) => {
  // // Check if the current category filter is 'unprocessed'
  //   if (backstockOptionsFilter && backstockOptionsFilter.value === 'unprocessed') {
  //   // If the row has a color assigned and the filter is 'unprocessed', apply it to the background
  //     if (row.color) {
  //       return { backgroundColor: row.color };
  //     }
  //   }
  //   // Default style if no color is assigned or if the category is not 'unprocessed'
  //   return {};
  // };

  const defaultSort = [{
    dataField: 'created_ts',
    order: 'desc'
  }];

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        { openCreateEditBackstockToggle &&
          <CreateEditBackstockModal isOpen={openCreateEditBackstockToggle} refresh={getData} clearSelectedRows={clearSelectedRows} />
        }
        { openCreateEditBackstockSingleToggle &&
          <CreateEditBackstockSingleModal isOpen={openCreateEditBackstockSingleToggle} refresh={getData} clearSelectedRows={clearSelectedRows} />
        }
        <ReactTooltip place="bottom"/>
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="backstock_id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <FaCubes className="text-primary" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Backstock</span>
                        </div>
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      { backstockOptionsFilter?.value === 'submitted' &&
                        <div style = {{ float: 'right', marginLeft: '5px' }}>
                          <Button size="sm" disabled={selectedRows.length === 0} style={{paddingRight: '10px', paddingLeft: '10px', paddingBottom: '5px', paddingTop: '5px'}} color="outline-success" onClick={() => dispatch(openCreateEditBackstockSingle(selectedRows))}><FaRegPaperPlane style={{fontSize: '15px', marginBottom: '3px'}}/></Button>
                        </div>
                      }
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" style={{paddingRight: '10px', paddingLeft: '10px', paddingBottom: '5px', paddingTop: '5px'}} color="outline-secondary" onClick={generatePDF}>
                          <FaFilePdf style={{ fontSize: '15px', marginBottom: '3px' }} />
                        </Button>
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" style={{paddingRight: '10px', paddingLeft: '10px', paddingBottom: '5px', paddingTop: '5px'}} color="outline-primary" onClick={() => dispatch(openCreateEditBackstock())}><FaRegPlusSquare style={{fontSize: '15px', marginBottom: '3px'}}/></Button>
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '200px'}} >
                        <Select 
                          height={10} 
                          className="dropdown" 
                          value={backstockOptionsFilter} 
                          styles={customStyles} 
                          name="backstock_options" 
                          options={backstockOptions} 
                          onChange={(e) => selectBackstockOption(e)}
                          isOptionDisabled={(option) => 
                            user.admin !== 1 && (option.value === 'submitted' || option.value === 'creating_shipment' || option.value === 'complete')
                          }
                        />
                      </div>
                      <div style={{float: 'right', width: '200px'}}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      {/* <div style={{float: 'right', marginRight: '5px'}}>
                        <Button size="sm" disabled={selectedRows.length === 0} style={{fontSize: '16px'}} color="outline-primary" onClick={() => dispatch(openCreateEditBackstockSingle(selectedRows))}>Ship</Button>
                      </div> */}
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} defaultSorted={defaultSort} condensed bordered={false} selectRow={selectRow}  filter={filterFactory()} noDataIndication={() => (<div>There are currently no shipments</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default Backstock;