import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FaRegListAlt, FaRegCheckCircle, FaRegQuestionCircle, FaRegTrashAlt } from 'react-icons/fa';
import { AiOutlineWarning } from 'react-icons/ai';
import { openRemovalsBatchDetail } from '../../redux/actions';
import removalsService from '../../services/removals.service';
import RemovalItem from '../../models/removal_item.model';
import ReactTooltip from 'react-tooltip';

const { SearchBar } = Search;

const RemovalsBatchDetail = (props) => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.open_removals_batch_detail_info);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const pagination = pagionationFactory({
    sizePerPage: 15,
    hideSizePerPage: true
  });

  const getData = async () => {
    try {
      const results = await removalsService.batchDetail(info.id);
      const newRows = results.map(r => new RemovalItem(r));
      setRows(newRows);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteProduct = async (row) => {
    try {
      await removalsService.removeProduct(row.id);
      getData();
    } catch (e) {
      console.log(e);
    }
  };

  const handleArchive = async () => {
    try {
      //set archive to 1 if archive = 0
      info.archive = info.archive === 0 ? 1 : 0;
      await removalsService.updatePallet(info, 'archive');
      props.refresh();
      dispatch(openRemovalsBatchDetail());
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const newColumns = [
      {
        text: 'SKU',
        headerStyle: {width: '120px'},
        dataField: 'sku',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'ASIN',
        dataField: 'asin',
        headerStyle: {width: '100px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'FNSKU',
        dataField: 'fnsku',
        headerStyle: {width: '100px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'LPN',
        dataField: 'lpn',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Vendor',
        dataField: 'vendor_name',
        headerStyle: {width: '60px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Product',
        dataField: 'product_description',
        headerStyle: {width: '200px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Image',
        dataField: 'image',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        formatter: (cell, row) => {
          return (
            <img src={row.image} className="table-image" />
          );
        }
      },
      {
        text: 'Qty',
        dataField: 'qty',
        headerStyle: {width: '50px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Inspection',
        dataField: 'product_inspection',
        headerStyle: {width: '70px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        formatter: (cell, row) => {
          if (row.product_inspection === 'plc') {
            return <FaRegCheckCircle data-tip="Product Looks Correct and New" style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          } else if (row.product_inspection === 'pbnf' && row.not_found === 0) {
            return <AiOutlineWarning data-tip="Product or Packaging is Bad or Not Fixable" style={{fontSize: '20px', marginBottom: '2px', color: 'orange'}}/>;
          } else if (row.not_found === 1) {
            return <FaRegQuestionCircle data-tip="Not Found" style={{fontSize: '18px', marginBottom: '2px', color: '#00008b'}}/>;
          }
        }
      },
      {
        text: 'Added TS',
        dataField: 'created_ts',
        headerStyle: {width: '90px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: '',
        dataField: 'id',
        headerStyle: {width: '50px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        formatter: (cell, row) => {
          if (info.complete === 0) {
            return <FaRegTrashAlt data-tip="Remove Product(s)" onClick={() => deleteProduct(row)} className="trash-can" />;
          } else {
            return <FaRegTrashAlt data-tip="Remove Product(s)" className="trash-can text-secondary" />;
          }
        }
      }
    ];

    setColumns(newColumns);
    ReactTooltip.rebuild();
  }, [rows]);

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => dispatch(openRemovalsBatchDetail())} backdrop="static" size="xl">
      <ModalHeader toggle={() => dispatch(openRemovalsBatchDetail())}>
        <FaRegListAlt style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Batch Detail - {info.pallet_name}
      </ModalHeader>
      <ReactTooltip />
      <ModalBody className="create-edit-removal">
        <Row>
          <Col lg={12}>
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} condensed pagination={pagination} bordered={false} noDataIndication={() => (<div>The batch is currently empty</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" style={{float: 'right'}} color="outline-secondary" onClick={() => dispatch(openRemovalsBatchDetail())}>Close</Button>
          { info.complete === 1 && info.archive === 0 &&
            <Button size="sm" style={{float: 'left'}} color="outline-primary" onClick={() => handleArchive()}>Archive</Button>
          }
          { info.archive === 1 &&
            <Button size="sm" style={{float: 'left'}} color="outline-primary" onClick={() => handleArchive()}>Un-Archive</Button>
          }
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default RemovalsBatchDetail;