import React, { useCallback, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import viewsService from '../../services/views.service';
import { TiExportOutline } from 'react-icons/ti';
import { FaSyncAlt } from 'react-icons/fa';
import { GiCycle } from 'react-icons/gi';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import Select from 'react-select';
import './Views.css';
// import { useDispatch, useSelector } from 'react-redux';
// import { setSelectedView } from '../../redux/actions';
import RecentProcessModel from '../../models/recent_process.model';

const { SearchBar } = Search;

const RecentProcesses = () => {
  // const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  // const viewOptions = useSelector(state => state.view_options);
  // const selectedView = useSelector(state => state.selected_view);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const results = await viewsService.recentProcesses();
      const newProcesses = results.map(r => new RecentProcessModel(r));
      setRows(newProcesses);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'Service',
        dataField: 'service_name',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Process URL',
        dataField: 'process_url',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Job',
        dataField: 'process_job',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Batch',
        dataField: 'process_batch',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Status',
        dataField: 'processing',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          return (
            <div>{row.status()}</div>
          );
        }
      },
      {
        text: 'Response',
        headerStyle: {width: '500px'},
        dataField: 'response',
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Received',
        dataField: 'job_received_ts',
        style: {textAlign: 'center'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Completed',
        dataField: 'job_complete_ts',
        style: {textAlign: 'center'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        filter: textFilter({className: 'filter-box'})
      }
    ];

    setColumns(newColumns);
    getData();
    return () => getData();
  }, []);

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <GiCycle className="text-warning" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Recent Processes</span>
                        </div>
                        {/* <Select className="dropdown" name="view_option" options={viewOptions} value={selectedView} onChange={(e) => dispatch(setSelectedView(e))}/> */}
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" color="outline-success" onClick={() => getData()}><FaSyncAlt style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable classes="table-scroll" {...props.baseProps} pagination={pagination} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no processes</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default RecentProcesses;