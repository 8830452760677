import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FaAmazon, FaRegTimesCircle } from 'react-icons/fa';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Store } from 'react-notifications-component';
import { openCreateEditBackstockSingle } from '../../redux/actions';
// import SingleSkuShipmentItem from '../../models/single_sku_shipment_item.model';
// import { BsBoxArrowInUpRight } from 'react-icons/bs';
import { PulseLoader } from 'react-spinners';
import backstockService from '../../services/backstock.service';
// import backstockService from '../../services/backstock.service';

const { SearchBar } = Search;

const CreateEditBackstockSingleModal = (props) => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.open_create_edit_backstock_single_info);
  const [rows, setRows] = useState(info);
  const [columns, setColumns] = useState(info);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const newColumns = [
      {
        text: 'Prep Notes',
        dataField: 'prep_notes',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Qty',
        dataField: 'case_qty',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Weight',
        dataField: 'case_weight',
        sort: true,
        style: (cell, row) => {
          if (row.case_weight > 48) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.case_weight}</span>;
        }
      },
      {
        text: 'L',
        dataField: 'case_length',
        sort: true,
        style: (cell, row) => {
          if (row.case_length > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.case_length}</span>;
        }
      },
      {
        text: 'W',
        dataField: 'case_width',
        sort: true,
        style: (cell, row) => {
          if (row.case_width > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.case_width}</span>;
        }
      },
      {
        text: 'H',
        dataField: 'case_height',
        sort: true,
        style: (cell, row) => {
          if (row.case_height > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.case_height}</span>;
        }
      },
      {
        text: 'OWT',
        dataField: 'overweight',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        editable: false,
        formatter: (cell, row) => {
          if (row.overweight === 1) {
            return <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
          }
        }
      },
      {
        text: 'Qty to Move',
        dataField: 'qty_to_move',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Case#',
        dataField: 'backstock_id',
        headerStyle: {width: '60px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        editable: false
      }
    ];

    setColumns(newColumns);
  }, [rows, setColumns]);

  const afterSaveCell = (oldValue, newValue, row, column) => {
    newValue = newValue && column.dataField === 'case_length' || column.dataField === 'case_width' || column.dataField === 'case_height' || column.dataField === 'qty_to_move'  || column.dataField === 'case_weight' || column.dataField === 'case_qty' ? parseFloat(newValue) : newValue;
    setRows(currentRows => {
      const rowIndex = currentRows.findIndex(r => r.backstock_id === row.backstock_id);
      console.log(rowIndex);
      if (rowIndex === -1) return currentRows; // Row not found, no update needed.
  
      let newRow;

      if (column.dataField === 'case_weight') {
        newRow = {
          ...currentRows[rowIndex],
          [column.dataField]: newValue,
          overweight: newValue > 48 ? 1 : 0 // Assuming this logic is correct.
        };
      } else if (column.dataField === 'case_length' || column.dataField === 'case_width' || column.dataField === 'case_height' || column.dataField === 'qty_to_move'  || column.dataField === 'prep_notes' || column.dataField === 'case_qty') {
        newRow = {
          ...currentRows[rowIndex],
          [column.dataField]: newValue
        };
      }

      // Create a new array with the updated row.
      return [
        ...currentRows.slice(0, rowIndex),
        newRow,
        ...currentRows.slice(rowIndex + 1)
      ];
    });
  };

  const cellEdit = cellEditFactory({
    mode: 'click',
    afterSaveCell: afterSaveCell,
    blurToSave: true
  });

  const handleClose = () => {
    dispatch(openCreateEditBackstockSingle());
    props.refresh();
  };

  const validateRows = () => {
    // Check each row for required fields
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      // Check if any of the required fields are missing or invalid (e.g., not filled in)
      if (row.case_weight <= 0 || row.case_length <= 0 || row.case_width <= 0 || row.case_height <= 0 || !row.case_qty || row.case_qty === 0) {
        // If any field is missing, return an object indicating failure and the row index
        return { valid: false, rowIndex: i + 1 }; // Adding 1 to make it human-readable (counting starts at 1)
      }
    }
    // If all rows are valid, return an object indicating success
    return { valid: true };
  };

  const handleSubmit = async () => {
    const validation = validateRows();
    if (!validation.valid) {
      Store.addNotification({
        title: 'Single Submission Error',
        message: 'SKU, Quantity, Weight, Length, Width, and Height are required to submit.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: false
        }
      });
      return; // Stop the submission since validation failed
    }

    setIsSubmitting(true); // Start loading

    try {
      setTimeout(() => {
        // inboundShipmentsService.submitInboundShipmentSingle(rows, product.sku);
        backstockService.submitBackstockInboundShipmentSingle(rows);
        Store.addNotification({
          title: 'Single Submission',
          message: 'Inbound shipment single submitted successfully.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        setIsSubmitting(false); // Stop loading
        props.clearSelectedRows(); // Assuming you have a function to clear selected rows
        handleClose(); // Close the modal
      }, 1500);
    } catch (error) {
      console.error('Failed to submit inbound shipment dims:', error);
      setIsSubmitting(false); // Stop loading
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="xl" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FaAmazon style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Ship Backstock
      </ModalHeader>
      <ModalBody className="create-edit-shipment-invoices">
        {isSubmitting ? (
          <Row>
            <Col lg={12}>
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <PulseLoader sizeUnit={'px'} size={10} color={'#123abc'} loading={isSubmitting} />
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={12}>
              { columns &&
                <ToolkitProvider keyField="backstock_id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                  {
                    props => (
                      <div>
                        <div style = {{ float: 'right', width: '200px' }}>
                          <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                        </div>
                        <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" condensed bordered={false} cellEdit={cellEdit} noDataIndication={() => (<div>There are currently no shipments</div>)}/>
                      </div>
                    )
                  }
                </ToolkitProvider>
              }
            </Col>
          </Row>
        )}
      </ModalBody>
      { !isSubmitting &&
        <ModalFooter>
          <Col lg={12} style={{width: '100%'}}>
            <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
            <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleSubmit()}>Submit</Button>
          </Col>
        </ModalFooter>
      }
    </Modal>
  );
};

export default CreateEditBackstockSingleModal;