import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let viewsService = {};

viewsService.profitReportDaily = async (startDate, endDate) => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/profit-report-daily?start_date=${startDate}&end_date=${endDate}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.profitReportMonthly = async () => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/profit-report-monthly`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.profitReportDailyLQ = async (startDate, endDate) => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/profit-report-daily-lq?start_date=${startDate}&end_date=${endDate}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.profitReportMonthlyLQ = async () => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/profit-report-monthly-lq`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.profitReport = async (startDate, endDate) => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/profit-report?start_date=${startDate}&end_date=${endDate}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.profitReportLQ = async (startDate, endDate) => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/profit-report-lq?start_date=${startDate}&end_date=${endDate}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.errorReport = async () => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/error-report`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.cancelledReport = async (startDate, endDate) => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/cancelled-report?start_date=${startDate}&end_date=${endDate}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.unshippedReport = async () => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/unshipped-report`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.recentProcesses = async () => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/recent-processes`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

viewsService.feedvisorErrors = async () => {
  try {
    const results = await axios.get(`${endpoints.api}/reports/feedvisor-errors`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default viewsService;