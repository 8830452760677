import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let returnsService = {};

returnsService.getReturns = async (startDate, endDate, status, inspection, user) => {
  try {
    const results = await axios.get(`${endpoints.api}/returns?start_date=${startDate}&end_date=${endDate}&status=${status}&inspection=${inspection}&admin=${user.admin}&user_id=${user.id}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.getReimbursementData = async (reimbursementId) => {
  try {
    const results = await axios.get(`${endpoints.api}/returns/reimbursement-info?reimbursement_id=${reimbursementId}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.deleteReturn = async (id, singleUPCEAN) => {
  try {
    const results = await axios.delete(`${endpoints.api}/returns?id=${id}&single_upc_ean=${singleUPCEAN}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.getReturnRecord = async (id) => {
  try {
    const results = await axios.get(`${endpoints.api}/returns/return-record?id=${id}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.lpnLookup = async (lpn, notFoundClear, userId) => {
  try {
    const results = await axios.get(`${endpoints.api}/returns/lpn-lookup?lpn=${lpn}&not_found_clear=${notFoundClear}&user_id=${userId}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.singleScanUPCLookup = async (upcEan, userId) => {
  try {
    const results = await axios.get(`${endpoints.api}/returns/single-scan-upc-lookup?upc_ean=${upcEan}&user_id=${userId}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.updateReturn = async (returnInfo) => {
  try {
    const results = await axios.put(`${endpoints.api}/returns`, returnInfo, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.markNotFound = async (returnInfo, user) => {
  try {
    const results = await axios.post(`${endpoints.api}/returns/mark-not-found`, {return_info: returnInfo, user}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.markFraudMissing = async (lpn, userId) => {
  try {
    const results = await axios.post(`${endpoints.api}/returns/mark-fraud-missing`, {lpn, user_id: userId}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.getRemovalId = async (trackingNumber) => {
  try {
    const results = await axios.get(`${endpoints.api}/returns/get-removal-id?tracking_number=${trackingNumber}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.postLabelPackingSlip = async (fileData) => {
  try {
    const results = await axios.post(`${endpoints.api}/returns/upload-label-packing-slip`, fileData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.postLPNUPC = async (fileData) => {
  try {
    const results = await axios.post(`${endpoints.api}/returns/upload-lpn-upc`, fileData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnsService.postCaptureIssueImage = async (fileData) => {
  try {
    const results = await axios.post(`${endpoints.api}/returns/upload-capture-issue-image`, fileData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default returnsService;