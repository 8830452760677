import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';
import { defineUser } from '../../redux/actions';
import authService from '../../services/auth.service';
import { Store } from 'react-notifications-component';
import './Login.css';
import User from '../../models/user.model';

const Login = (props) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signUp, setSignUp] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [checkingRefresh, setCheckingRefresh] = useState(true);

  const handleSubmit = useCallback( async (e) => {
    try {
      e.preventDefault();

      if (signUp && (password === confirmPassword)) {
        await authService.signUp({first_name: firstName, last_name: lastName, email: username, password});
        setSignUp(false);
        Store.addNotification({
          title: 'Success',
          message: 'Sign up complete, you will receive an email when approved.',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
        setFirstName('');
        setLastName('');
        setPassword('');
      } else {
        let authResult = await authService.login({username, password});
        authResult = new User(authResult);
        if (authResult.validated) {
          const mfaVerified = await authService.verifyOtpToken();
          if (mfaVerified) {
            authResult.requiredMfa = false;
          } else {
            authResult.requiredMfa = true;
          }
          dispatch(defineUser(authResult));
          props.setUser(authResult);
        }
      }
    } catch (e) {
      setInvalidCredentials(true);
      console.log(e);
    }
  }, [username, password, confirmPassword, firstName, lastName]);

  const checkRefreshToken = async () => {
    try {
      let authResult = await authService.checkRefresh();
      authResult = new User(authResult);
      if (authResult.validated) {
        const mfaVerified = await authService.verifyOtpToken();
        if (mfaVerified) {
          authResult.requiredMfa = false;
        } else {
          authResult.requiredMfa = true;
        }
        setCheckingRefresh(false);
        dispatch(defineUser(authResult));
        props.setUser(authResult);
      } else {
        setCheckingRefresh(false);
      }
    } catch (e) {
      setCheckingRefresh(false);
    }
  };

  useEffect(() => {
    checkRefreshToken();
  }, []);

  if (!checkingRefresh) {
    return (
      <Row>
        <Col lg={12} className="top-margin" align="center">
          <Card style={{width: '400px', backgroundColor: '#323334'}}>
            <CardBody>
              <CardTitle tag="h3" style={{textAlign: 'center'}}>
                <img src={'./ba-logo.png'} style={{width: '180px'}}></img>
              </CardTitle>
              <form onSubmit={handleSubmit}>
                <div className="card-text">
                  { signUp &&
                    <React.Fragment>
                      <FormGroup>
                        <Input id="first_name" placeholder="First Name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                      </FormGroup>
                      <FormGroup>
                        <Input id="last_name" placeholder="Last Name" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </FormGroup>
                    </React.Fragment>
                  }
                  <FormGroup>
                    <Input id="email" placeholder="Email" type="email" value={username} onChange={(e) => setUsername(e.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <Input id="password" placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </FormGroup>
                  { signUp &&
                    <FormGroup style={{textAlign: 'left'}}>
                      <Input id="confirm_password" placeholder="Confirm Password" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                      { confirmPassword !== password &&
                        <span className="undertext text-danger">Passwords do not match</span>
                      }
                    </FormGroup>
                  }
                </div>
                <Button type="submit" color="primary"  style={{width: '100%'}}>{signUp ? 'Sign up' : 'Sign in'}</Button>
                { invalidCredentials && 
                  <p className="undertext text-danger">Invalid credentials</p>
                }
              </form>
            </CardBody>
          </Card>
          { signUp ?
            <span className="undertext">Have an account? <a href="#" onClick={() => setSignUp(false)}>Log in</a></span>
            :
            <span className="undertext">No account? <a href="#" onClick={() => setSignUp(true)}>Sign up</a></span>
          }
        </Col>
      </Row>
  
    );
  } else{
    return(<div></div>);
  }
};

export default Login;