import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { AiOutlineCamera } from 'react-icons/ai';
import { openImageCapture } from '../../redux/actions';
import Webcam from 'react-webcam';

const ImageCapture = (props) => {
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgSrc2, setImgSrc2] = useState(null);
  const [imgSrc3, setImgSrc3] = useState(null);
  const [imgSrc4, setImgSrc4] = useState(null);
  const issueImage = useSelector(state => state.open_image_capture_info);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    props.capture(imageSrc);
  }, [imgSrc, setImgSrc]);

  const captureLPNUPC = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    props.captureLPNUPC(imageSrc);
  }, [imgSrc, setImgSrc]);

  const captureIssue = useCallback((imageNo) => {
    const imageSrc = webcamRef.current.getScreenshot();
    
    if (imageNo === 1) {
      setImgSrc(imageSrc);
      props.captureIssue(imageSrc, imageNo);
    } else if (imageNo === 2) {
      setImgSrc2(imageSrc);
      props.captureIssue(imageSrc, imageNo);
    } else if (imageNo === 3) {
      setImgSrc3(imageSrc);
      props.captureIssue(imageSrc, imageNo);
    } else if (imageNo === 4) {
      setImgSrc4(imageSrc);
      props.captureIssue(imageSrc, imageNo);
    }

  }, [imgSrc, setImgSrc]);

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => dispatch(openImageCapture())} backdrop="static" size="xl">
      <ModalHeader toggle={() => dispatch(openImageCapture())}>
        <AiOutlineCamera style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Image Capture
      </ModalHeader>
      <ModalBody>
        { !issueImage &&
          <Row>
            <Col lg={12}>
              <Webcam
                audio={false}
                ref={webcamRef}
                width="100%"
                videoConstraints={{
                  height: 1080,
                  width: 1920,
                  facingMode: 'environment'
                }}
                screenshotFormat="image/jpeg"
              />
              <Button size="sm" style={{float: 'right'}} color="outline-secondary" onClick={capture}>Capture Photo</Button>
            </Col>
            <Col lg={12}>
              {imgSrc && (
                <img src={imgSrc} width="100%"/>
              )}
            </Col>
          </Row>
        }
        { issueImage === 'lpn_upc' &&
          <Row>
            <Col lg={12}>
              <Webcam
                audio={false}
                ref={webcamRef}
                width="100%"
                forceScreenshotSourceSize
                videoConstraints={{
                  height: 1080,
                  width: 1920,
                  facingMode: 'environment'
                }}
                screenshotFormat="image/jpeg"
              />
              <Button size="sm" style={{float: 'right'}} color="outline-secondary" onClick={captureLPNUPC}>Capture Photo</Button>
            </Col>
            <Col lg={12}>
              {imgSrc && (
                <img src={imgSrc} width="100%"/>
              )}
            </Col>
          </Row>
        }
        { issueImage === 'issue' &&
          <Row>
            <Col lg={12}>
              <Webcam
                audio={false}
                ref={webcamRef}
                width="100%"
                videoConstraints={{
                  height: 1080,
                  width: 1920,
                  facingMode: 'environment'
                }}
                screenshotFormat="image/jpeg"
              />
              <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => captureIssue(4)}>Capture Photo 4</Button>
              <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => captureIssue(3)}>Capture Photo 3</Button>
              <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => captureIssue(2)}>Capture Photo 2</Button>
              <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => captureIssue(1)}>Capture Photo 1</Button>
            </Col>
            <Col lg={12}>
              {imgSrc && (
                <img src={imgSrc} width="100%"/>
              )}
            </Col>
            <Col lg={12}>
              {imgSrc2 && (
                <img src={imgSrc2} width="100%"/>
              )}
            </Col>
            <Col lg={12}>
              {imgSrc3 && (
                <img src={imgSrc3} width="100%"/>
              )}
            </Col>
            <Col lg={12}>
              {imgSrc4 && (
                <img src={imgSrc4} width="100%"/>
              )}
            </Col>
          </Row>
        }
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" style={{float: 'right'}} color="outline-secondary" onClick={() => dispatch(openImageCapture())}>Close</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default ImageCapture;