import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let errorTrackerService = {};

errorTrackerService.getErrors = async () => {
  try {
    const results = await axios.get(`${endpoints.api}/error-tracker/get-errors`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default errorTrackerService;