import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let inboundShipmentsService = {};

inboundShipmentsService.getInboundShipments = async (category) => {
  try {
    const results = await axios.get(`${endpoints.api}/inbound-shipments?category=${category}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

inboundShipmentsService.markCategroy = async (shipmentItems, category) => {
  try {
    const results = await axios.post(`${endpoints.api}/inbound-shipments/mark-category`, {shipment_items: shipmentItems, category}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

inboundShipmentsService.setBatchName = async (shipmentItems, batchName) => {
  try {
    const results = await axios.post(`${endpoints.api}/inbound-shipments/set-batch-name`, {shipment_items: shipmentItems, batch_name: batchName}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

inboundShipmentsService.submitInboundShipmentDims = async (shipmentItems) => {
  try {
    const results = await axios.post(`${endpoints.api}/inbound-shipments/inbound-shipment-dims`, {shipment_items: shipmentItems}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

inboundShipmentsService.submitInboundShipmentSingle = async (shipmentItems, sku) => {
  try {
    const results = await axios.post(`${endpoints.api}/inbound-shipments/inbound-shipment-single`, {shipment_items: shipmentItems, sku}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

inboundShipmentsService.productSearch = async (product) => {
  try {
    const results = await axios.get(`${endpoints.api}/inbound-shipments/product-search?product=${product}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

inboundShipmentsService.getCustomerInfo = async (orderId) => {
  try {
    const results = await axios.get(`${endpoints.api}/inbound-shipments/customer-info?order_id=${orderId}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

inboundShipmentsService.submitCustomerToShipstation = async (customer, shipmentDetail) => {
  try {
    const results = await axios.post(`${endpoints.api}/inbound-shipments/submit-customer-to-shipstation`, {customer, shipment_detail: shipmentDetail}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default inboundShipmentsService;