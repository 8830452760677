import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { openProductLookup } from '../../redux/actions';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import './ProductLookup.css';

const ProductLookup = (props) => {
  const dispatch = useDispatch();
  const productInfo = useSelector(state => state.open_product_lookup_info);

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => dispatch(openProductLookup())} backdrop="static" size="xl">
      <ModalHeader toggle={() => dispatch(openProductLookup())}>
        Product Lookup - { productInfo.length > 0 ? <React.Fragment><span>UPC: </span><span className="category-blue pointer" onClick={() => navigator.clipboard.writeText(productInfo[0].internal_upc)}>{productInfo[0].internal_upc}</span></React.Fragment> : '' }
      </ModalHeader>
      <ModalBody>
        <Table size="sm">
          <thead>
            <tr>
              <th>Dist</th>
              <th>Whs</th>
              <th>Item No</th>
              <th style={{width: '60%'}}>Description</th>
              <th>#</th>
              <th>Cost</th>
              <th>Amazon</th>
              <th>Ebay</th>
            </tr>
          </thead>
          <tbody>
            { productInfo.map((p, i) => 
              <tr key={i}>
                <td style={{textAlign: 'center'}}>{p.distributor}</td>
                <td style={{textAlign: 'center'}}>{p.warehouse}</td>
                <td>
                  <span className="category-blue pointer" onClick={() => navigator.clipboard.writeText(p.item_no)}>{p.item_no}</span>
                  <span>                        
                    { p.distributor === 'HLC' && p.item_no &&
                      <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://www.hlc.bike/us/Catalog/Search/?by=key&keyword=${p.item_no}`, '_blank')} />
                    }
                    { p.distributor === 'QBP' && p.item_no &&
                      <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://www.qbp.com/qbponlinestorefront/n/p/${p.item_no}`, '_blank')} />
                    }
                    { p.distributor === 'JBI' && p.item_no &&
                      <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://www.jbi.bike/site/product_details.php?part_number=${p.item_no}`, '_blank')} />
                    }
                    { p.distributor === 'TGC' && p.item_no &&
                      <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://www8.versaclouderp.com/party_portal/852/products?utf8=%E2%9C%93&filter%5Btext%5D=${p.item_no}`, '_blank')} />
                    }
                    { p.distributor === 'FBA' && p.item_no &&
                      <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://sellercentral.amazon.com/skucentral?mSku=${p.item_no}`, '_blank')} />
                    }
                  </span>
                </td>
                <td style={{width: '55%'}}>{p.sales_description}</td>
                <td style={{textAlign: 'center'}}>{p.qty}</td>
                <td style={{textAlign: 'right'}}>{p.cost}</td>
                <td style={{textAlign: 'right'}}>{p.map_profit_amazon}</td>
                <td style={{textAlign: 'right'}}>{p.map_profit_ebay}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button size="sm" color="outline-secondary" onClick={() => dispatch(openProductLookup())}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProductLookup;