import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import './PriceTracker.css';
import { openPriceTrackerProductDetail } from '../../redux/actions';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import priceTrackerService from '../../services/price_tracker.service';
import { TiExportOutline } from 'react-icons/ti';
import { FaRegCheckCircle } from 'react-icons/fa';
import PriceTrackerSellerViolationsModel from '../../models/price_tracker_seller_violations.model';
import { ImCancelCircle } from 'react-icons/im';

const { SearchBar } = Search;

const ProductDetail = (props) => {
  const dispatch = useDispatch();
  const productInfo = useSelector(state => state.open_price_tracker_product_detail_info);
  const selectedVendor = useSelector(state => state.price_tracker_vendor);
  const selectedBrand = useSelector(state => state.price_tracker_brand);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });
  
  const getData = async () => {
    const results = await priceTrackerService.skuViolationDetail(selectedVendor?.value, selectedBrand?.value, productInfo?.asin);
    const newRows = results.map((r, i) => new PriceTrackerSellerViolationsModel(r, i));
    setRows(newRows);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const newColumns = [
      {
        text: 'Seller',
        dataField: 'seller_name',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/sp?seller=${row.seller_id}`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Address',
        dataField: 'address',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        headerStyle: {width: '300px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/sp?seller=${row.seller_id}`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'City',
        dataField: 'city',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        headerStyle: {width: '100px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/sp?seller=${row.seller_id}`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'State',
        dataField: 'state',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '50px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/sp?seller=${row.seller_id}`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Zip',
        dataField: 'zip',
        style: {textAlign: 'right', verticalAlign: 'middle'},
        headerStyle: {width: '50px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/sp?seller=${row.seller_id}`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Country',
        dataField: 'country',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '50px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/sp?seller=${row.seller_id}`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Authroized Seller',
        dataField: 'is_authorized',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '90px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`https://www.amazon.com/sp?seller=${row.seller_id}`, '_blank')
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.is_authorized === 1) {
            return <FaRegCheckCircle data-tip="Authorized" style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          } else {
            return <ImCancelCircle data-tip="Unauthorized" style={{fontSize: '18px', marginBottom: '2px', color: 'red'}}/>;
          }
        }
      },
      {
        text: 'FBA',
        dataField: 'is_fulfilled_by_amazon',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '50px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`httphttps://www.amazon.com/sp?seller=${row.seller_id}s`, '_blank')
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.is_fulfilled_by_amazon === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'Prime',
        dataField: 'is_prime',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '50px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`httphttps://www.amazon.com/sp?seller=${row.seller_id}s`, '_blank')
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.is_prime === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'Listing Price',
        dataField: 'listing_price_amount',
        style: {textAlign: 'right', verticalAlign: 'middle'},
        headerStyle: {width: '55px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`httphttps://www.amazon.com/sp?seller=${row.seller_id}s`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'MAP',
        dataField: 'map',
        style: {textAlign: 'right', verticalAlign: 'middle'},
        headerStyle: {width: '55px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`httphttps://www.amazon.com/sp?seller=${row.seller_id}s`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Variance',
        dataField: 'variance',
        style: {textAlign: 'right', verticalAlign: 'middle'},
        headerStyle: {width: '55px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => window.open(`httphttps://www.amazon.com/sp?seller=${row.seller_id}s`, '_blank')
        },
        filter: textFilter({className: 'filter-box'})
      }
    ];

    setColumns(newColumns);
  }, [rows, setColumns]);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => dispatch(openPriceTrackerProductDetail())} backdrop="static" size="xl">
      <ModalHeader toggle={() => dispatch(openPriceTrackerProductDetail())}>
        Sku Detail
      </ModalHeader>
      <ModalBody className="vendor-detail">
        <Row>
          <Col lg={8}>
            <table>
              <tbody>
                <tr>
                  <td className="category-titles">Sku:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(productInfo.part_no)}>
                    <span>{productInfo.part_no}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">UPC/EAN:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(productInfo.upc_ean)}>
                    <span>{productInfo.upc_ean}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">ASIN: </td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(productInfo.asin)}>
                    <span>{productInfo.asin}</span>
                    <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open(`https://www.amazon.com/dp/${productInfo.asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=New&th=1`, '_blank')} /></span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Brand:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(productInfo.brand)}>
                    <span>{productInfo.brand}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Description:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(productInfo.description)}>
                    <span>{productInfo.description}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">MAP:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(productInfo.map)}>
                    <span>{productInfo.map}</span>
                  </td>
                </tr>
                <tr>
                  <td className="category-titles">Last Updated:</td>
                  <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(productInfo.created_ts)}>
                    <span>{productInfo.created_ts}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="section-header" style={{marginTop: '20px'}}> Map Violations</div>
            <hr style={{marginTop: '5px'}}/>
          </Col>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no removals</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button size="sm" color="outline-secondary" onClick={() => dispatch(openPriceTrackerProductDetail())}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProductDetail;