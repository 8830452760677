import moment from 'moment';
import React from 'react';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';

export default class ErrorReportModel {
  constructor(data, index) {
    this.id = index;
    this.city = data.City;
    this.zip = data.PostalCode;
    this.state_code = data.StateCode;
    this.state_name = data.StateName;
    this.street_line1 = data.StreetLine1;
    this.street_line2 = data.StreetLine2;
    this.carrier = data.carrier;
    this.channel_order_id = data.channel_order_id;
    this.completed = data.completed == 1 ? <FaRegCheckCircle style={{fontSize: '15px', marginBottom: '2px', color: 'green'}}/> : <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
    this.cost = data.cost;
    this.distributor = data.distributor.toUpperCase();
    this.customer = `${data.first_name} ${data.last_name}`;
    this.internal_product_id = data.internal_product_id;
    this.order_id = data.order_id;
    this.order_number = data.order_number;
    this.qty = data.qty;
    this.received = data.received == 1 ? <FaRegCheckCircle style={{fontSize: '15px', marginBottom: '2px', color: 'green'}}/> : <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
    this.refunded = data.refunded;
    this.refunded_ts = data.refunded_ts ? moment(new Date(data.refunded_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.cancelled = data.cancelled;
    this.cancelled_ts = data.cancelled_ts ? moment(new Date(data.cancelled_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.cancelled_refunded = data.cancelled_refunded; 
    this.cancelled_refunded_ts = data.cancelled_ts ? this.cancelled_ts : this.refunded_ts;
    this.time_of_order = data.time_of_order; 
    this.seller_cloud_tracking = data.seller_cloud_tracking == 1 ? <FaRegCheckCircle style={{fontSize: '15px', marginBottom: '2px', color: 'green'}}/> : <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
    this.shipped = data.shipped == 1 ? <FaRegCheckCircle style={{fontSize: '15px', marginBottom: '2px', color: 'green'}}/> : <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
    this.tracking_number = data.tracking_number;
  }
}