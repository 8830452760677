import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TiExportOutline } from 'react-icons/ti';
import { FaRegPlusSquare, FaRegCheckCircle, FaSync, FaRegQuestionCircle, FaBarcode } from 'react-icons/fa';
import { AiOutlineWarning } from 'react-icons/ai';
import { FiTruck } from 'react-icons/fi';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { openCreateEditSingleSkuShipment, setEndDate, setSingleSkuShipmentsStatusFilter, setStartDate } from '../../redux/actions';
import moment from 'moment';
import './SingleSkuShipments.css';
import ReactTooltip from 'react-tooltip';
import CreateEditSingleSkuShipment from './CreateEditSingleSkuShipment';
import singleSkuShipmentsService from '../../services/single_sku_shipments.service';
import SingleSkuShipmentItem from '../../models/single_sku_shipment_item.model';
import Select from 'react-select';

const { SearchBar } = Search;

const SingleSkuShipments = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [statusOptions] = useState([
    { value: 'all', label: 'All Statuses' },
    { value: 'error', label: 'Error' },
    { value: 'processing', label: 'In Process' },
    { value: 'not_found', label: 'Not Found' },
    { value: 'sku_create', label: 'SKU Creation' },
    { value: 'submitted', label: 'Submitted' }
  ]);

  const startDate = useSelector(state => state.start_date);
  const endDate = useSelector(state => state.end_date);
  const openCreateEditSingleSkuShipmentToggle = useSelector(state => state.open_create_edit_single_sku_shipment);
  const singleSkuShipmentsStatusFilter = useSelector(state => state.single_sku_shipments_status_filter);
  const user = useSelector(state => state.user);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = async (status) => {
    try {
      const results = await singleSkuShipmentsService.getShipments(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), status, user);
      const newRows = results.map(r => new SingleSkuShipmentItem(r));
      setRows(newRows);
      // createNew ? dispatch(openCreateEditSingleSkuShipment()) : null;
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'SKU',
        dataField: 'sku',
        headerStyle: {width: '160px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Shipment ID',
        dataField: 'shipment_name',
        headerStyle: {width: '200px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Reference No',
        dataField: 'shipment_id_internal',
        headerStyle: {width: '110px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Amazon ID',
        dataField: 'shipment_id',
        headerStyle: {width: '120px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Created TS',
        dataField: 'created_ts',
        headerStyle: {width: '150px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'ASIN',
        dataField: 'asin',
        headerStyle: {width: '110px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'UPC/EAN',
        dataField: 'upc_ean',
        headerStyle: {width: '140px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Brand',
        dataField: 'brand',
        headerStyle: {width: '90px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Description',
        dataField: 'product_description',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Qty',
        dataField: 'qty',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '60px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Wt',
        dataField: 'weight',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '70px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Len',
        dataField: 'length',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '70px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Wid',
        dataField: 'width',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '70px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Ht',
        dataField: 'height',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        headerStyle: {width: '70px'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Image',
        dataField: 'image',
        headerStyle: {width: '110px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          return (
            <img src={row.image} className="table-image" />
          );
        }
      },
      {
        text: 'Status',
        dataField: 'status',
        headerStyle: {width: '70px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        filter: textFilter({className: 'filter-box'}),
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditSingleSkuShipment(row))
        },
        formatter: (cell, row) => {
          if (row.status === 'submitted') {
            return <FaRegCheckCircle data-tip="Submitted" style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          } else if (row.status === 'error') {
            return <AiOutlineWarning data-tip="Error" style={{fontSize: '20px', marginBottom: '2px', color: 'red'}}/>;
          } else if (row.status === 'processing') {
            return <FaSync data-tip="In Process" style={{fontSize: '18px', marginBottom: '2px', color: 'orange'}}/>;
          } else if (row.status === 'not_found') {
            return <FaRegQuestionCircle data-tip="Not Found" style={{fontSize: '18px', marginBottom: '2px', color: 'blue'}}/>;
          } else if (row.status === 'sku_create') {
            return <FaBarcode data-tip="SKU Creation" style={{fontSize: '18px', marginBottom: '2px', color: 'blue'}}/>;
          }
        }
      }
    ];

    ReactTooltip.rebuild();
    setColumns(newColumns);
  }, [rows, setColumns]);

  const selectStatus = (e) => {
    dispatch(setSingleSkuShipmentsStatusFilter(e));
    getData(e.value);
  };

  useEffect(async () => {
    setIsLoading(true);
    getData();
  }, [startDate, endDate]);

  useEffect(async () => {
    dispatch(setSingleSkuShipmentsStatusFilter(statusOptions[0]));
  }, []);

  const defaultSort = [{
    dataField: 'shipment_id_internal',
    order: 'desc'
  }];

  const customStyles = {
    control: base => ({
      ...base,
      height: 34,
      minHeight: 34,
    }),
    singleValue: (provided) => {
      return { ...provided, paddingBottom: '5px' };
    }
  };

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        { openCreateEditSingleSkuShipmentToggle &&
          <CreateEditSingleSkuShipment isOpen={openCreateEditSingleSkuShipmentToggle} refresh={getData} />
        }
        <ReactTooltip />
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <FiTruck className="text-primary" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Single SKU Shipments</span>
                        </div>
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" style={{paddingRight: '10px', paddingLeft: '10px', paddingBottom: '5px', paddingTop: '5px'}} color="outline-primary" onClick={() => dispatch(openCreateEditSingleSkuShipment())}><FaRegPlusSquare style={{fontSize: '15px', marginBottom: '3px'}}/></Button>
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font" 
                          selected={endDate}
                          onChange={(date) => dispatch(setEndDate(date))}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font"
                          style={{ fontSize: '13px' }}
                          selected={startDate}
                          onChange={(date) => dispatch(setStartDate(date))}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '150px'}} >
                        <Select height={10} className="dropdown" value={singleSkuShipmentsStatusFilter} styles={customStyles} name="statuses" options={statusOptions} onChange={(e) => selectStatus(e)}/>
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} defaultSorted={defaultSort} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no single sku shipments</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default SingleSkuShipments;