import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Input, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TiExportOutline } from 'react-icons/ti';
import { FaFileInvoiceDollar, FaRegCheckCircle } from 'react-icons/fa';
import { ImUpload3 } from 'react-icons/im';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { openBulkShipmentUpload, openCreateEditShipmentInvoice, setShipmentInvoicesStatusFilter, setShipmentOptionsFilter } from '../../redux/actions';
import moment from 'moment';
import './ShipmentInvoices.css';
import ShipmentInvoiceItem from '../../models/shipment_invoice_item.model';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import shipmentInvoicesService from '../../services/shipment_invoices.service';
import CreateEditShipmentInvoice from './CreateEditShipmentInvoice';
import BulkShipmentUpload from './BulkShipmentUpload';

const { SearchBar } = Search;

const ShipmentInvoices = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [lookupShipment, setLookupShipment] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState(new Date(moment().subtract(36, 'months').startOf('month')));
  const [endDate, setEndDate] = useState(new Date());
  const [statusOptions] = useState([
    { value: 'all', label: 'All Statuses' },
    { value: 'CHECKED_IN', label: 'Checked In' },
    { value: 'CLOSED', label: 'Closed' },
    { value: 'DELIVERED', label: 'Delivered' },
    { value: 'IN_TRANSIT', label: 'In Transit' },
    { value: 'READY_TO_SHIP', label: 'Ready to Ship' },
    { value: 'RECEIVING', label: 'Receiving' },
    { value: 'SHIPPED', label: 'Shipped' },
    { value: 'WORKING', label: 'Working' },
  ]);

  const [shipmentOptions] = useState([
    { value: 3, label: 'Priority Shipments' },
    { value: 'all', label: 'All Shipments' },
    { value: 1, label: 'Invoices' },
    { value: 0, label: 'No Invoices' },
  ]);

  const shipmentInvoiceStatusFilter = useSelector(state => state.shipment_invoices_status_filter);
  const shipmentOptionsFilter = useSelector(state => state.shipment_options_filter);
  const user = useSelector(state => state.user);
  const openCreateEditShipmentInvoiceToggle = useSelector(state => state.open_create_edit_shipment_invoice);
  const openBulkShipmentUploadToggle = useSelector(state => state.open_bulk_shipment_upload);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = async (status, shipmentOption) => {
    try {
      const results = await shipmentInvoicesService.getShipments(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), status, shipmentOption, user);
      const newRows = results.map(r => new ShipmentInvoiceItem(r));
      setRows(newRows);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'Shipment Name',
        dataField: 'shipment_name_table',
        headerStyle: {width: '500px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Shipment Status',
        dataField: 'shipment_status',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'QBP',
        dataField: 'qbp',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.qbp === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'JBI',
        dataField: 'jbi',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.jbi === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'HLC',
        dataField: 'hlc',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.hlc === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'TGC',
        dataField: 'tgc',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.tgc === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'BTI',
        dataField: 'bti',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.bti === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'Olympic',
        dataField: 'olympic',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.olympic === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'UPC',
        dataField: 'upc',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.upc === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'MPN',
        dataField: 'mpn',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.mpn === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'Invoices',
        dataField: 'invoices_uploaded',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.invoices_uploaded === 1) {
            return <FaRegCheckCircle style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          }
        }
      },
      {
        text: 'Shipment TS',
        dataField: 'shipment_ts',
        headerStyle: {width: '150px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Updated TS',
        dataField: 'updated_ts',
        headerStyle: {width: '150px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditShipmentInvoice(row))
        },
        filter: textFilter({className: 'filter-box'})
      }
    ];

    ReactTooltip.rebuild();
    setColumns(newColumns);
  }, [rows, setColumns]);

  useEffect(async () => {
    setIsLoading(true);
    if (!shipmentInvoiceStatusFilter || !shipmentOptionsFilter) {
      getData(statusOptions[0].value, shipmentOptions[0].value);
    } else {
      getData(shipmentInvoiceStatusFilter.value, shipmentOptionsFilter.value);
    }
  }, [startDate, endDate]);

  useEffect(async () => {
    dispatch(setShipmentInvoicesStatusFilter(statusOptions[0]));
    dispatch(setShipmentOptionsFilter(shipmentOptions[0]));
  }, []);

  const selectStatus = (e) => {
    dispatch(setShipmentInvoicesStatusFilter(e));
    getData(e.value, shipmentOptionsFilter.value);
  };

  const selectShipmentOption = (e) => {
    dispatch(setShipmentOptionsFilter(e));
    getData(shipmentInvoiceStatusFilter.value, e.value);
  };

  const handleKeypressShipmentSearch = async e => {
    if (e.keyCode === 13) {
      const results = await shipmentInvoicesService.shipmentLookup(lookupShipment);
      const newShipment = new ShipmentInvoiceItem(results);
      dispatch(openCreateEditShipmentInvoice(newShipment));
    }
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 34,
      minHeight: 34,
    }),
    singleValue: (provided) => {
      return { ...provided, paddingBottom: '5px' };
    }
  };

  const defaultSort = [{
    dataField: 'created_ts',
    order: 'desc'
  }];

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        { openCreateEditShipmentInvoiceToggle &&
          <CreateEditShipmentInvoice isOpen={openCreateEditShipmentInvoiceToggle} refresh={getData} />
        }
        { openBulkShipmentUploadToggle &&
          <BulkShipmentUpload isOpen={openBulkShipmentUploadToggle} refresh={getData} />
        }
        <ReactTooltip place="bottom"/>
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="shipment_name" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <FaFileInvoiceDollar className="text-primary" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Shipment Invoices</span>
                        </div>
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" data-tip="Bulk Upload" color="outline-secondary" onClick={() => dispatch(openBulkShipmentUpload())}><ImUpload3 style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
                      </div>
                      {/* <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" style={{paddingRight: '10px', paddingLeft: '10px', paddingBottom: '5px', paddingTop: '5px'}} color="outline-primary" onClick={() => dispatch(openCreateEditShipmentInvoice())}><FaRegPlusSquare style={{fontSize: '15px', marginBottom: '3px'}}/></Button>
                      </div> */}
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font" 
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font"
                          style={{ fontSize: '13px' }}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '160px'}} >
                        <Select height={10} className="dropdown" value={shipmentOptionsFilter} styles={customStyles} name="shipment_options" options={shipmentOptions} onChange={(e) => selectShipmentOption(e)}/>
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '150px'}} >
                        <Select height={10} className="dropdown" value={shipmentInvoiceStatusFilter} styles={customStyles} name="statuses" options={statusOptions} onChange={(e) => selectStatus(e)}/>
                      </div>
                      <div style={{float: 'right', width: '200px'}}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <div style={{float: 'right', width: '200px', marginRight: '5px'}}>
                        <Input type="text" name="part_lookup" id="part_lookup" placeholder="Shipment ID Lookup" style={{fontSize: '13px'}} onInput={e => setLookupShipment(e.target.value)} onKeyDown={(e) => handleKeypressShipmentSearch(e)}/>
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} defaultSorted={defaultSort} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no shipments</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default ShipmentInvoices;