import moment from 'moment';

export default class RemovalPalletModel {
  constructor(data) {
    this.id = data && data.id ? data.id : 0;
    this.pallet_type = data && data.pallet_type ? data.pallet_type : '';
    this.pallet_name = data && data.pallet_name ? data.pallet_name : '';
    this.pallet_description = data && data.pallet_description ? data.pallet_description : '';
    this.pallet_notes = data && data.pallet_notes ? data.pallet_notes : '';
    this.created_ts = data && data.created_ts ?  moment(new Date(data.created_ts)).format('MM/DD/YY hh:mm:ss A') : '';
    this.qty = data && data.qty ?  data.qty : 0;
    this.complete = data && data.complete ? data.complete : 0;
    this.archive = data && data.archive ? data.archive : 0;
  } 
}