import React, { useCallback, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TiExportOutline } from 'react-icons/ti';
import { FaRegPlusSquare, FaRegCheckCircle, FaSync, FaWarehouse } from 'react-icons/fa';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { openCreateEditRemoval, openRemovalsBatchDetail, setRemovalsStatusFilter } from '../../redux/actions';
import moment from 'moment';
import './Removals.css';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import removalsService from '../../services/removals.service';
import RemovalPalletModel from '../../models/removal_pallet.model';
import CreateEditRemoval from './CreateEditRemoval';
import RemovalsBatchDetail from './RemovalsBatchDetail';

const { SearchBar } = Search;

const Removals = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState(new Date(moment().subtract(12, 'months').startOf('month')));
  const [endDate, setEndDate] = useState(new Date());
  const openCreateEditRemovalToggle = useSelector(state => state.open_create_edit_removal);
  const openRemovalsBatchDetailToggle = useSelector(state => state.open_removals_batch_detail);

  const [statusOptions] = useState([
    { value: 'all', label: 'All Statuses' },
    { value: 1, label: 'Complete' },
    { value: 0, label: 'In Process' },
    { value: 2, label: 'Archive' }
  ]);

  const removalsStatusFilter = useSelector(state => state.removals_status_filter);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = useCallback(async () => {
    try {
      const results = await removalsService.getRemovalPallets(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), removalsStatusFilter.value);
      const newRows = results.map(r => new RemovalPalletModel(r));
      setRows(newRows);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [removalsStatusFilter, startDate, endDate]);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'Pallet Name',
        dataField: 'pallet_name',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openRemovalsBatchDetail(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Pallet Description',
        dataField: 'pallet_description',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openRemovalsBatchDetail(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Pallet Type',
        dataField: 'pallet_type',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openRemovalsBatchDetail(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          if (row.pallet_type === 'good') {
            return 'Good Batch';
          } else if (row.pallet_type === 'bad') {
            return 'Bad Batch';
          }

        }
      },
      {
        text: 'Item Qty',
        dataField: 'qty',
        style: {textAlign: 'right', verticalAlign: 'middle'},
        sort: true,
        headerStyle: {width: '90px'},
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openRemovalsBatchDetail(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Created TS',
        dataField: 'created_ts',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openRemovalsBatchDetail(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Complete',
        dataField: 'internal_status',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        filter: textFilter({className: 'filter-box'}),
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openRemovalsBatchDetail(row))
        },
        formatter: (cell, row) => {
          if (row.complete === 1) {
            return <FaRegCheckCircle data-tip="Complete" style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          } else if (row.complete === 0) {
            return <FaSync data-tip="In Process" style={{fontSize: '18px', marginBottom: '2px', color: 'orange'}}/>;
          }

        }
      }
    ];

    ReactTooltip.rebuild();
    setColumns(newColumns);
  }, [rows, setColumns]);

  useEffect(() => {
    dispatch(setRemovalsStatusFilter(statusOptions[0]));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (removalsStatusFilter &&  startDate && endDate) {
      getData();
    }
  }, [removalsStatusFilter, startDate, endDate]);

  const selectStatus = (e) => {
    dispatch(setRemovalsStatusFilter(e));
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 34,
      minHeight: 34,
    }),
    singleValue: (provided) => {
      return { ...provided, paddingBottom: '5px' };
    }
  };

  const defaultSort = [{
    dataField: 'created_ts',
    order: 'desc'
  }];

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        { openCreateEditRemovalToggle &&
          <CreateEditRemoval isOpen={openCreateEditRemovalToggle} refresh={getData} />
        }
        { openRemovalsBatchDetailToggle &&
          <RemovalsBatchDetail isOpen={openRemovalsBatchDetailToggle} refresh={getData} />
        }
        <ReactTooltip />
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <FaWarehouse className="text-primary" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Removals</span>
                        </div>
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" style={{paddingRight: '10px', paddingLeft: '10px', paddingBottom: '5px', paddingTop: '5px'}} color="outline-primary" onClick={() => dispatch(openCreateEditRemoval())}><FaRegPlusSquare style={{fontSize: '15px', marginBottom: '3px'}}/></Button>
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font" 
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font"
                          style={{ fontSize: '13px' }}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '150px'}} >
                        <Select height={10} className="dropdown" value={removalsStatusFilter} styles={customStyles} name="statuses" options={statusOptions} onChange={(e) => selectStatus(e)}/>
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} defaultSorted={defaultSort} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no removals</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default Removals;