import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { openCreateEditSingleSkuShipment } from '../../redux/actions';
import './SingleSkuShipments.css';
import SingleSkuShipmentItem from '../../models/single_sku_shipment_item.model';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import { FiTruck } from 'react-icons/fi';
import { PulseLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { AiOutlinePaperClip } from 'react-icons/ai';
import singleSkuShipmentsService from '../../services/single_sku_shipments.service';
import Select from 'react-select';

const CreateEditSingleSkuShipment = (props) => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.open_create_edit_single_sku_shipment_info);
  const user = useSelector(state => state.user);
  const [searching, setSearching] = useState(false);
  const [productSearchString, setProductSearchString] = useState(info ? info.sku : '');
  const [submittingShipment, setSubmittingShipment] = useState(false);
  const [submittingMessage, setSubmittingMessage] = useState(false);
  const [changeTimer, setChangeTimer] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [usedConditionOptions] = useState([
    { value: .80, label: 'UsedLikeNew' },
    { value: .70, label: 'UsedVeryGood' },
    { value: .60, label: 'UsedGood' },
    { value: .50, label: 'UsedAcceptable' }
  ]);
  const [product, setProduct] = useState(info ? new SingleSkuShipmentItem(info) : new SingleSkuShipmentItem());
  const singleSkuShipmentsStatusFilter = useSelector(state => state.single_sku_shipments_status_filter);

  const handleScan = (e) => {
    const value = e.target.value;

    setProductSearchString(value);
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }

    if (value !== productSearchString) {
      const timer = setTimeout(async () => {
        try {
          setSearching(true);
          setNotFound(false);
          const results = await singleSkuShipmentsService.productSearch(value, product.condition);
          results.condition = product.condition;
          const condition = product.condition;
          if (results.id === 0) {
            setNotFound(true);
            const newProduct = new SingleSkuShipmentItem();
            newProduct.not_found = 1;
            newProduct.sku = productSearchString;
            newProduct.condition = condition;
            e.target.select();
            setProduct(newProduct);
            setSearching(false);
          } else {
            setProduct(new SingleSkuShipmentItem(results));
          }

          setSearching(false);
        } catch (e) {
          console.log(e);
        }
      }, 400);
  
      setChangeTimer(timer);
    }
  };

  const handleClose = () => {
    dispatch(openCreateEditSingleSkuShipment());
    props.refresh(singleSkuShipmentsStatusFilter.value);
  };

  const updateInputFields = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    
    const newProduct = JSON.parse(JSON.stringify(product));

    if (name === 'quantity') {
      newProduct.qty = value;
      setProduct(newProduct);
    } else if (name === 'shipment_name') {
      newProduct.shipment_name = value;
      setProduct(newProduct);
    } else if (name === 'weight') {
      newProduct.weight = value;
      setProduct(newProduct);
    } else if (name === 'length') {
      newProduct.length = value;
      setProduct(newProduct);
    } else if (name === 'width') {
      newProduct.width = value;
      setProduct(newProduct);
    } else if (name === 'height') {
      newProduct.height = value;
      setProduct(newProduct);
    } else if (name === 'used_price') {
      newProduct.used_price = value;
      setProduct(newProduct);
    } else if (name === 'notes_to_customer') {
      newProduct.notes_to_customer = value;
      setProduct(newProduct);
    }
  };

  const updateCondition = (condition) => {
    const newProduct = new SingleSkuShipmentItem();
    newProduct.condition = condition;
    setProduct(newProduct);
    setProductSearchString('');
  };

  const createUpdateShipment = async(submission, createSku) => {
    try {
      if (submission) {
        setSubmittingShipment(true);
        setSubmittingMessage('Submitting Shipment...');
        await singleSkuShipmentsService.createUpdateShipment(product, submission, createSku, user);
        setSubmittingShipment(false);
        Store.addNotification({
          title: 'Shipment Submission',
          message: 'Shipment has been submitted to Amazon.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      } else if (createSku) {
        setSubmittingShipment(true);
        setSubmittingMessage('Creating SKU...');
        await singleSkuShipmentsService.createUpdateShipment(product, submission, createSku, user);
        setSubmittingShipment(false);
        Store.addNotification({
          title: 'Create SKU',
          message: 'SKU creation initiated.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      } else {
        setSubmittingShipment(true);
        setSubmittingMessage('Saving Shipment...');
        await singleSkuShipmentsService.createUpdateShipment(product, submission, createSku, user);
        setSubmittingShipment(false);
        Store.addNotification({
          title: 'Save Shipment',
          message: 'Shipment saved.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }

      const newProduct = new SingleSkuShipmentItem();
      setProduct(newProduct);
      setProductSearchString('');
    } catch (e) {
      console.log(e);
    }
  };

  const requestAddProduct = async () => {
    try {
      setSubmittingShipment(true);
      setSubmittingMessage('Processing add request...');
      const newProduct = new SingleSkuShipmentItem();
      newProduct.sku = productSearchString;
      newProduct.not_found = 1;
      newProduct.status = 'not_found';
      newProduct.condition = product.condition;
      const results = await singleSkuShipmentsService.createUpdateShipment(newProduct, false, null, user);
      newProduct.shipment_id_internal = results.shipment_id_internal;
      setNotFound(false);
      setProduct(newProduct);
      singleSkuShipmentsService.requestAdd(newProduct, user);
      setSubmittingShipment(false);
    } catch (e) {
      console.log(e);
    }
  };

  const nextProduct = async () => {
    try {
      setProduct(new SingleSkuShipmentItem());
      setProductSearchString('');
    } catch (e) {
      console.log(e);
    }
  };

  const refreshProduct = async () => {
    try {
      const results = await singleSkuShipmentsService.productSearch(product.sku);
      results.condition = product.condition;
      results.shipment_id_internal = product.shipment_id_internal;
      if (results.id !== 0) {
        results.not_found = 0;
        console.log(results);
        setProduct(new SingleSkuShipmentItem(results));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteShipment = async () => {
    try {
      await singleSkuShipmentsService.deleteShipment(product.shipment_id_internal);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const updateUserCondition = (usedCondition) => {
    const newProduct = JSON.parse(JSON.stringify(product));
    newProduct.used_condition = usedCondition.label;
    newProduct.used_price = newProduct.price * usedCondition.value > 5 ? Math.round(newProduct.price * usedCondition.value * 100) / 100 : newProduct.price;
    setProduct(newProduct);
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 33,
      minHeight: 33,
    }),
    singleValue: (provided) => {
      return { ...provided, paddingBottom: '5px' };
    }
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="lg" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FiTruck style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Create / Edit Single SKU Shipment
      </ModalHeader>
      { submittingShipment &&
        <ModalBody>
          <div className="row">
            <Col lg={12} style={{textAlign: 'center', marginTop: '10px'}}>
              <p>{submittingMessage}</p>
            </Col>
            <div className="col-lg-12" style={{textAlign: 'center', marginTop: '10px'}}>
              <PulseLoader 
                sizeUnit={'px'}
                size={20}
                color={'#419bf7'}
              />
            </div>
          </div>
        </ModalBody>
      }
      { !submittingShipment &&
        <React.Fragment>
          <ModalBody className="single-sku-shipment">
            <Row>
              <Col lg={12}>
                { product.status === 'processing' &&
                  <div style={{float: 'right'}} className="text-warning">***In Process***</div>
                }
                { product.status === 'submitted' &&
                  <div style={{float: 'right'}} className="text-primary">***Submitted***</div>
                }
                { product.status === 'not_found' &&
                  <div style={{float: 'right'}} className="text-primary">***Not Found***</div>
                }
                { product.status === 'error' &&
                  <div style={{float: 'right'}} className="text-danger">***Error***</div>
                }
                { product.status === 'sku_create' &&
                  <div style={{float: 'right', color: 'blue'}}>***SKU Creation***</div>
                }
              </Col>
              { product.condition === 'New' &&
                <Col lg={12} style={{textAlign: 'center'}}>
                  <Button size="sm" disabled={product.status === 'submitted'} style={{width: '130px', marginRight: '10px'}} color="primary">New</Button>
                  <Button size="sm" disabled={product.status === 'submitted'} style={{width: '130px', marginRight: '10px'}} color="outline-primary" onClick={() => updateCondition('Used')}>Used</Button>
                </Col>
              }
              { product.condition === 'Used' &&
                <Col lg={12} style={{textAlign: 'center'}}>
                  <Button size="sm" disabled={product.status === 'submitted'} style={{width: '130px', marginRight: '10px'}} color="outline-primary" onClick={() => updateCondition('New')}>New</Button>
                  <Button size="sm" disabled={product.status === 'submitted'} style={{width: '130px', marginRight: '10px'}} color="primary">Used</Button>
                </Col>
              }
              <Col lg={12}><hr style={{marginBottom: '10px'}}/></Col>
              <Col lg={12} className="box">
                <FormGroup>
                  <Label for="scan_item" className="input-label">UPC/LPN/ASIN/FNSKU </Label>
                  <Input id="scan_item" name="scan_item" autoFocus={true} disabled={product.status === 'submitted'} placeholder="Scan/Enter UPC/LPN/ASIN/FNSKU" style={{fontSize: '12px', width: '250px', textAlign: 'center'}} type="text" value={productSearchString} onChange={(e) => handleScan(e)}/>
                  { searching &&
                    <div style={{fontSize: '14px'}}>Searching...</div>
                  }
                  { notFound &&
                    <div className="not-found">Item Not Found</div>
                  }
                </FormGroup>
              </Col>
              { (product.id !== 0 && product.not_found === 0) &&
                <React.Fragment>
                  <Col lg={12}>
                    <div className="section-header" style={{marginTop: '5px'}}> {product.product_description}</div>
                    <hr style={{marginTop: '5px'}}/>
                  </Col>
                  <Col lg={8}>
                    <table>
                      <tbody>
                        <tr>
                          <td className="category-titles">Brand:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.brand)}>
                            <span>{product.brand}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">mSKU:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.sku)}>
                            <span>{product.sku}</span>
                            <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open(`https://sellercentral.amazon.com/skucentral?mSku=${product.sku}`, '_blank')} /></span>
                          </td>
                        </tr>
                        { product.lpn !== '' &&
                          <tr>
                            <td className="category-titles">LPN:</td>
                            <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.lpn)}>
                              <span>{product.lpn}</span>
                            </td>
                          </tr>
                        }
                        <tr>
                          <td className="category-titles">UPC/EAN:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.upc_ean)}>
                            <span>{product.upc_ean}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">MPN:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.manufacturer_part_no)}>
                            <span>{product.manufacturer_part_no}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">ASIN:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.asin)}>
                            <span>{product.asin}</span>
                            <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open(`https://www.amazon.com/gp/product/${product.asin}?psc=1`, '_blank')} /></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">FNSKU:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.fnsku)}>
                            <span>{product.fnsku}</span>
                          </td>
                        </tr>
                        <React.Fragment>
                          <tr>
                            <td className="category-titles">Cost:</td>
                            <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.cost)}>
                              <span>{product.cost}</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="category-titles">Price:</td>
                            <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.price)}>
                              <span>{product.price}</span>
                            </td>
                          </tr>
                        </React.Fragment>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg={4} style={{textAlign: 'center'}}>
                    <img src={product.image} className="image"  onClick={() => window.open(product.image)}/>
                  </Col>
                  <Col lg={12}>
                    <div className="section-header" style={{marginTop: '5px'}}> Details</div>
                    <hr style={{marginTop: '5px'}}/>
                  </Col>
                  { product.label_url !== '' &&
                    <Col lg={12} style={{textAlign: 'right'}}>
                      <AiOutlinePaperClip className="image-icon" onClick={() => window.open(product.label_url)}/>
                    </Col>
                  }
                  { product.condition === 'Used' &&
                    <React.Fragment>
                      <Col lg={4}>
                        <FormGroup>
                          <Label for="condition" className="input-label"> Condition: </Label>
                          <Select height={10} className="dropdown" disabled={product.status === 'submitted'} styles={customStyles} name="inspections" options={usedConditionOptions} value={usedConditionOptions.filter(c => c.label === product.used_condition)} onChange={(e) => updateUserCondition(e)} />
                        </FormGroup>
                      </Col>
                      <Col lg={4}>
                        <FormGroup>
                          <Label for="used_price" className="input-label"> Price: </Label>
                          <Input invalid={product.height > 24} id="used_price" name="used_price" placeholder="Enter price" type="number" disabled={product.status === 'submitted'} style={{fontSize: '12px'}} value={product.used_price} onChange={(e) => updateInputFields(e)}/>
                        </FormGroup>
                      </Col>
                      <Col lg={4}></Col>
                    </React.Fragment>
                  }
                  <Col lg={4}>
                    <FormGroup>
                      <Label for="shipment_name" className="input-label"> Shipment ID: </Label>
                      <Input id="shipment_name" name="shipment_name" placeholder="Enter shipment ID" type="text" disabled={product.status === 'submitted'} style={{fontSize: '12px'}} value={product.shipment_name} onChange={(e) => updateInputFields(e)}/>
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label for="quantity" className="input-label"> Quantity: </Label>
                      <Input invalid={product.qty > 150} id="quantity" name="quantity" placeholder="Enter quantity" type="number" disabled={product.status === 'submitted'} style={{fontSize: '12px'}} value={product.qty} onChange={(e) => updateInputFields(e)}/>
                      <FormFeedback>
                        Warning: The quantity is over 150
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label for="weight" className="input-label"> Weight: </Label>
                      <Input invalid={product.weight > 48} id="weight" name="weight" placeholder="Enter weight" type="number" disabled={product.status === 'submitted'} style={{fontSize: '12px'}} value={product.weight} onChange={(e) => updateInputFields(e)}/>
                      <FormFeedback>
                        Warning: The weight is over 48
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label for="length" className="input-label"> Length: </Label>
                      <Input invalid={product.length > 24} id="length" name="length" placeholder="Enter length" type="number" disabled={product.status === 'submitted'} style={{fontSize: '12px'}} value={product.length} onChange={(e) => updateInputFields(e)}/>
                      <FormFeedback>
                        Warning: The length is over 24
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label for="width" className="input-label"> Width: </Label>
                      <Input invalid={product.width > 24} id="width" name="width" placeholder="Enter width" type="number" disabled={product.status === 'submitted'} style={{fontSize: '12px'}} value={product.width} onChange={(e) => updateInputFields(e)}/>
                      <FormFeedback>
                        Warning: The width is over 24
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label for="height" className="input-label"> Height: </Label>
                      <Input invalid={product.height > 24} id="height" name="height" placeholder="Enter height" type="number" disabled={product.status === 'submitted'} style={{fontSize: '12px'}} value={product.height} onChange={(e) => updateInputFields(e)}/>
                      <FormFeedback>
                        Warning: The height is over 24
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  { product.condition === 'Used' &&
                    <Col lg={12}>
                      <FormGroup>
                        <Label for="notes_to_customer" className="input-label"> Notes for Customer: </Label>
                        <Input id="notes_to_customer" name="notes_to_customer" placeholder="Enter notes for customer" type="textarea" disabled={product.status === 'submitted'} style={{fontSize: '12px'}} value={product.notes_to_customer} onChange={(e) => updateInputFields(e)}/>
                      </FormGroup>
                    </Col>
                  }
                </React.Fragment>
              }
              { ((product.status === 'not_found' && product.shipment_id_internal !== 0) || product.status === 'sku_create') &&
                <Col lg={12} style={{textAlign: 'center', fontSize: '14px'}}>
                  <p>Please label the box with number <span style={{fontWeight: 'bold', color: 'red'}}>{product.shipment_id_internal}</span> for future reference.</p>
                </Col>
              }
            </Row>
          </ModalBody>
          <ModalFooter>
            <Col lg={12} style={{width: '100%'}}>
              { product.shipment_id_internal !== 0 &&
                <Button size="sm" style={{float: 'left', marginLeft: '5px'}} color="outline-danger" onClick={() => deleteShipment()}>Delete</Button>
              }
              <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
              { !notFound && product.upc_ean !== '' &&
                <React.Fragment>
                  <Button 
                    size="sm" 
                    style={{float: 'right', marginLeft: '5px'}} 
                    color="outline-success" 
                    onClick={() => createUpdateShipment(true)}
                    disabled={
                      product.shipment_name && 
                      product.shipment_name !== '' && 
                      product.weight !== '' && 
                      product.weight !== 0 && 
                      product.length !== '' && 
                      product.length !== 0 && 
                      product.width !== '' && 
                      product.width !== 0 && 
                      product.height !== '' && 
                      product.height !== 0 &&
                      product.status !== 'submitted' &&
                      (
                        (product.condition === 'Used' && product.status === 'sku_create') ||
                        product.condition === 'New'
                      )
                        ? false : true
                    }
                  >
                    Save & Submit
                  </Button>
                  { (product.condition === 'Used' && product.used_condition !== '' && product.used_price !== '' && product.used_price !== 0) &&
                    <Button size="sm" disabled={product.status === 'submitted' || product.status === 'sku_create' ? true : false} style={{float: 'right', marginLeft: '5px'}} color="outline-success" onClick={() => createUpdateShipment(false, true)}>Save & Create SKU</Button>
                  }
                  <Button size="sm" disabled={product.shipment_name && product.shipment_name !== '' && product.status !== 'submitted' ? false : true} style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => createUpdateShipment(false)}>Save</Button>
                </React.Fragment>
              }
              { ((notFound || product.status === 'not_found') && productSearchString !== '' && product.shipment_id_internal === 0) &&
                <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => requestAddProduct()}>Request Add</Button>
              }
              { (product.status === 'not_found' && product.shipment_id_internal !== 0) &&
                <React.Fragment>
                  <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => refreshProduct()}>Refresh Product</Button>
                  <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => nextProduct()}>Scan New Product</Button>
                </React.Fragment>
              }
            </Col>
          </ModalFooter>
        </React.Fragment>
      }
    </Modal>
  );
};

export default CreateEditSingleSkuShipment;