import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { FaAmazon } from 'react-icons/fa';
import { openCreateEditBatch } from '../../redux/actions';
import { Store } from 'react-notifications-component';
import inboundShipmentsService from '../../services/inbound_shipments.service';

const { SearchBar } = Search;

const CreateEditBatchModal = (props) => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.open_create_edit_batch_info);
  const [rows] = useState(info);
  const [columns, setColumns] = useState(info);
  const [batchName, setBatchName] = useState('');
  // const user = useSelector(state => state.user);

  // const shipmentInvoiceStatusFilter = useSelector(state => state.shipment_invoices_status_filter);
  // const shipmentOptionsFilter = useSelector(state => state.shipment_options_filter);

  useEffect(() => {
    const newColumns = [
      {
        text: 'Shipment ID',
        dataField: 'shipment_id',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Prep Notes',
        dataField: 'prep_notes',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Delivery Date',
        dataField: 'date_delivered',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        editable: false
      },
      {
        text: 'Weight',
        dataField: 'package_weight',
        sort: true,
        style: (cell, row) => {
          if (row.package_weight > 48) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_weight}</span>;
        }
      },
      {
        text: 'L',
        dataField: 'package_length',
        sort: true,
        style: (cell, row) => {
          if (row.package_length > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_length}</span>;
        }
      },
      {
        text: 'W',
        dataField: 'package_width',
        sort: true,
        style: (cell, row) => {
          if (row.package_width > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_width}</span>;
        }
      },
      {
        text: 'H',
        dataField: 'package_height',
        sort: true,
        style: (cell, row) => {
          if (row.package_height > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_height}</span>;
        }
      },
      {
        text: 'Inbound Tracking Number',
        dataField: 'inbound_tracking_number',
        headerStyle: {width: '140px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        editable: false
      },
    ];

    setColumns(newColumns);
  }, [rows, setColumns]);

  const handleClose = () => {
    dispatch(openCreateEditBatch());
    props.refresh();
  };

  const validateRows = () => {
    if (batchName === '') return { valid: false };
    return { valid: true };
  };

  const handleSubmit = async () => {
    const validation = validateRows();
    if (!validation.valid) {
      Store.addNotification({
        title: 'Batch Submission Error',
        message: 'Batch name cannot be empty.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: false
        }
      });
      return; // Stop the submission since validation failed
    }

    try {
      await inboundShipmentsService.setBatchName(rows, batchName);
      props.clearSelectedRows(); // Assuming you have a function to clear selected rows
      handleClose(); // Close the modal
    } catch (error) {
      console.error('Failed to submit inbound shipment batch:', error);
    // Handle error (e.g., show error message)
    }
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="xl" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FaAmazon style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Inbound Shipments Batch
      </ModalHeader>
      <ModalBody className="create-edit-shipment-invoices">
        <Row>
          <Col lg={{size: 4, offset: 4}}>
            <div style={{marginBottom: '10px'}}>
              <Label htmlFor="batch_name">Batch Name</Label>
              <Input type="text" id="batch_name" className="form-control" placeholder="Enter Batch Name" style={{fontSize: '13px'}} value={batchName} onChange={(e) => setBatchName(e.target.value)} />
            </div>
          </Col>
          <Col lg={12}>
            { columns &&
              <ToolkitProvider keyField="inbound_tracking_number" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" condensed bordered={false} noDataIndication={() => (<div>There are currently no shipments</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
          <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleSubmit()}>Submit</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default CreateEditBatchModal;