export default class CustomerInfo {
  constructor(data) {
    this.order_id = data && data.order_id ? data.order_id : 0;
    this.first_name = data && data.first_name ? data.first_name : '';
    this.last_name = data && data.last_name ? data.last_name : '';
    this.street_line_1 = data && data.street_line_1 ? data.street_line_1 : '';
    this.street_line_2 = data && data.street_line_2 ? data.street_line_2 : '';
    this.city = data && data.city ? data.city : '';
    this.state_code = data && data.state_code ? data.state_code : '';
    this.state_name = data && data.state_name ? data.state_name : '';
    this.zip = data && data.zip ? data.zip : '';
    this.phone = data && data.phone ? data.phone : '';
  }
}
