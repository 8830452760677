import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let authService = {};

authService.login = async (user) => {
  try {
    const results = await axios.post(`${endpoints.api}/auth/login`, user, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
  }
};

authService.signUp = async (signupInfo) => {
  try {
    const results = await axios.post(`${endpoints.api}/auth/signup`, signupInfo, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
  }
};

authService.checkRefresh = async (user) => {
  try {
    const results = await axios.post(`${endpoints.api}/auth/check-logged-in`, user, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
  }
};

authService.verifyOtp = async (code, user) => {
  try {
    const results = await axios.post(`${endpoints.api}/auth/verify-otp`, {code, user}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
  }
};

authService.verifyOtpToken = async () => {
  try {
    const results = await axios.get(`${endpoints.api}/auth/verify-otp-token`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
  }
};

authService.logout = async () => {
  try {
    const results = await axios.get(`${endpoints.api}/auth/logout`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
  }
};

export default authService;