import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { GiReturnArrow } from 'react-icons/gi';
import { openCreateEditReturn, openImageCapture, openReturnReimbursementDetail } from '../../redux/actions';
import ReturnModel from '../../models/return.model';
import returnsService from '../../services/returns.service';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
// import { IoIosImage } from 'react-icons/io';
import { AiOutlinePaperClip } from 'react-icons/ai';
import styled from 'styled-components';
import Uploady, { useItemStartListener, useBatchFinishListener } from '@rpldy/uploady';
import UploadDropZone from '@rpldy/upload-drop-zone';
import withPasteUpload from '@rpldy/upload-paste';
import endpoints from '../../endpoints/endpoints';
import { Store } from 'react-notifications-component';
import Barcode from 'react-barcode';
import * as html2canvas from 'html2canvas';
import { TiExportOutline } from 'react-icons/ti';
import { AiOutlineCamera } from 'react-icons/ai';
import ImageCapture from './ImageCapture';
import { FaCheck, FaRegEdit, FaRegPlusSquare } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import ReturnComms from './ReturnComms';
import ReimbursementItem from '../../models/reimbursement_item.model';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';
import ReturnReimbursementDetail from './ReturnReimbursementDetail';

const StyledDropZone = styled(UploadDropZone)`
  width: 400px;
  height: 100px;
  border: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const PasteUploadDropZone = withPasteUpload(StyledDropZone);

const CreateEditReturn = (props) => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.open_create_edit_return_info);
  const user = useSelector(state => state.user);
  const [returnInfo, setReturnInfo] = useState(info ? info : new ReturnModel());
  const [changeTimer, setChangeTimer] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [showInputCaseId, setShowInputCaseId] = useState(false);
  const [caseId, setCaseId] = useState(info ? info.case_id : '');
  const [showInputReimbursementId, setShowInputReimbursementId] = useState(false);
  const [reimbursementId, setReimbursementId] = useState(info ? info.reimbursement_id : '');
  const [reimbursementData, setReimbursementData] = useState([]);

  const openImageCaptureToggle = useSelector(state => state.open_image_capture);
  const openReturnReimbursementDetailToggle = useSelector(state => state.open_return_reimbursement_detail);
  const returnStatusFilter = useSelector(state => state.return_status_filter);
  const returnInspectionFilter = useSelector(state => state.return_inspection_filter);

  const getReimbursementData = async () => {
    try {
      const results = await returnsService.getReimbursementData(returnInfo.reimbursement_id);
      const newReimbursementData = results.map((r, i) => new ReimbursementItem(r, i));
      setReimbursementData(newReimbursementData);
    } catch (e) {
      console.log(e);
    }
  };
  
  useEffect(() => {
    getReimbursementData();
  }, [returnInfo]);

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
        
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  };

  const captureLabelPackingSlip = useCallback(async (imageSrc) => {
    try {
      const file = dataURLtoFile(imageSrc, 'label-packing-slip.jpeg');
      let formData = new FormData();
      formData.append('file', file);
      formData.append('id', returnInfo.id);
  
      await returnsService.postLabelPackingSlip(formData);
      refreshRecord();
    } catch (e) {
      console.log(e);
    }
  }, [returnInfo]);

  const captureLPNUPC = useCallback(async (imageSrc) => {
    try {
      const file = dataURLtoFile(imageSrc, 'lpn-upc.jpeg');
      let formData = new FormData();
      formData.append('file', file);
      formData.append('id', returnInfo.id);
  
      await returnsService.postLPNUPC(formData);
      refreshRecord();
    } catch (e) {
      console.log(e);
    }
  }, [returnInfo]);

  const captureIssueImages = useCallback(async (imageSrc, imageNo) => {
    try {
      const file = dataURLtoFile(imageSrc, `issue-image${imageNo}.jpeg`);
      let formData = new FormData();
      formData.append('file', file);
      formData.append('id', returnInfo.id);
      formData.append('issue_no', imageNo);
  
      await returnsService.postCaptureIssueImage(formData);
      refreshRecord();
    } catch (e) {
      console.log(e);
    }
  }, [returnInfo]);

  const refreshRecord = async () => {
    try {
      const newReturnInfo = await returnsService.getReturnRecord(returnInfo.id);
      setReturnInfo(new ReturnModel(newReturnInfo));
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddLPN = (e) => {
    const value = e.target.value;
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }

    if (value !== returnInfo.lpn) {
      const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
      newReturnInfo.lpn = value;
      setReturnInfo(newReturnInfo);
      const timer = setTimeout(async () => {
        try {
          setSearching(true);
          setNotFound(false);
          const results = await returnsService.lpnLookup(value, 'false', user.id);
          if (results.lpn === 'not_found') {
            setNotFound(true);
          } else {
            setReturnInfo(new ReturnModel(results));
          }
          setSearching(false);
        } catch (e) {
          console.log(e);
        }
      }, 200);
  
      setChangeTimer(timer);
    }
  };

  const handleAddSingleScanUPC = (e) => {
    const value = e.target.value;
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }

    if (value !== returnInfo.upc_ean && value !== 0 && value !== '') {
      const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
      newReturnInfo.upc_ean = value;
      setReturnInfo(newReturnInfo);
      const timer = setTimeout(async () => {
        try {
          setSearching(true);
          setNotFound(false);
          const results = await returnsService.singleScanUPCLookup(value, user.id);
          setReturnInfo(new ReturnModel(results));
          setSearching(false);
        } catch (e) {
          console.log(e);
        }
      }, 200);
  
      setChangeTimer(timer);
    }
  };

  const handleUpdateInputField = async (e) => {
    try {
      const name = e.target.name;
      const value = e.target.value;

      const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));

      if (name === 'tracking_number' && value !== returnInfo.tracking_number) {
        if (changeTimer) {
          clearTimeout(changeTimer);
          setChangeTimer(null);
        }

        newReturnInfo.tracking_number = value;
        setReturnInfo(newReturnInfo);
        const timer = setTimeout(async () => {
          try {
            const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
            newReturnInfo.tracking_number = value;
            const removalId = await returnsService.getRemovalId(newReturnInfo.tracking_number);
            newReturnInfo.removal_id = removalId.removal_id;
            newReturnInfo.shipment_id = removalId.shipment_id;
            setReturnInfo(newReturnInfo);
            returnsService.updateReturn(newReturnInfo);
          } catch (e) {
            console.log(e);
          }
        }, 500);
        setChangeTimer(timer);
      } else if (name === 'actual_upc_ean') {
        newReturnInfo.actual_upc_ean = value;
        setReturnInfo(newReturnInfo);
        returnsService.updateReturn(newReturnInfo);
      } else if (name === 'removal_id') {
        newReturnInfo.removal_id = value;
        setReturnInfo(newReturnInfo);
        returnsService.updateReturn(newReturnInfo);
      } else if (name === 'shipment_id') {
        newReturnInfo.shipment_id = value;
        setReturnInfo(newReturnInfo);
        returnsService.updateReturn(newReturnInfo);
      } else if (returnInfo.fraud_missing === 1) {
        if (changeTimer) {
          clearTimeout(changeTimer);
          setChangeTimer(null);
        }
        
        // Handle updates to other fields when in Fraud/Missing scenario
        newReturnInfo[name] = value;
        setReturnInfo(newReturnInfo);
        const timer = setTimeout(async () => {
          try {
            returnsService.updateReturn(newReturnInfo);
          } catch (e) {
            console.log(e);
          }
        }, 500);
        setChangeTimer(timer);
      }

    } catch (e) {
      console.log(e);
    }
  };

  const updateCaseReimbursementInfo = async (name) => {
    try {
      const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));

      if (name === 'case_id') {
        setShowInputCaseId(false);
        newReturnInfo.case_id = caseId;
      } else if (name === 'reimbursement_id') {
        setShowInputReimbursementId(false);
        newReturnInfo.reimbursement_id = reimbursementId;
      }

      setReturnInfo(newReturnInfo);
      returnsService.updateReturn(newReturnInfo);

    } catch (e) {
      console.log(e);
    }
  };

  const handleNotFound = () => {
    const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
    newReturnInfo.not_found = 1;
    setReturnInfo(new ReturnModel(newReturnInfo));
    returnsService.markNotFound(newReturnInfo, user);
    dispatch(openCreateEditReturn());
    props.refresh(true, returnStatusFilter.value, returnInspectionFilter.value);
  };

  const handleProductInspection = (selection) => {
    const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
    newReturnInfo.product_inspection = selection;
    newReturnInfo.packaging_inspection = null;
    newReturnInfo.label_packing_slip_image = null;
    newReturnInfo.lpn_upc_image = null;
    newReturnInfo.issue_image_1 = null;
    newReturnInfo.issue_image_2 = null;
    newReturnInfo.issue_image_3 = null;
    newReturnInfo.issue_image_4 = null;
    newReturnInfo.removal_id = null;
    newReturnInfo.tracking_number = null;
    newReturnInfo.shipment_id = null;
    console.log(newReturnInfo);
    setReturnInfo(new ReturnModel(newReturnInfo));
    returnsService.updateReturn(newReturnInfo);
  };

  const handleIssueType = (selection) => {
    const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
    newReturnInfo.issue_type = selection;
    setReturnInfo(new ReturnModel(newReturnInfo));
    returnsService.updateReturn(newReturnInfo);
  };

  const handlePackageInspection = (selection) => {
    const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
    newReturnInfo.packaging_inspection = selection;
    setReturnInfo(new ReturnModel(newReturnInfo));
    if (selection === 'good') {
      setTimeout(() => {
        document.getElementById('actual_upc_ean').focus();
      }, 300);
    }
    returnsService.updateReturn(newReturnInfo);
  };

  const UploadStatus = () => {
    const [status, setStatus] = useState(null);
  
    useItemStartListener(() => setStatus('Uploading...'));
    useBatchFinishListener(() => {
      setStatus(null);
      if (returnInfo.complete === 1) {
        Store.addNotification({
          title: 'File Upload',
          message: 'Please clear complete status to upload files.',
          type: 'warning',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      } else {
        Store.addNotification({
          title: 'File Upload',
          message: 'File uploaded successfully.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      refreshRecord();
    });
  
    return status;
  };

  const handleClose = () => {
    dispatch(openCreateEditReturn());
    props.refresh(false, returnStatusFilter.value, returnInspectionFilter.value);
  };

  const handleFinalize = async (createNew) => {
    try {
      const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
      newReturnInfo.complete = 1;
      setReturnInfo(new ReturnModel(newReturnInfo));
      await returnsService.updateReturn(newReturnInfo);
      dispatch(openCreateEditReturn());
      createNew ? props.refresh(true, returnStatusFilter.value, returnInspectionFilter.value) : props.refresh(false, returnStatusFilter.value, returnInspectionFilter.value);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreateNew = async (createNew) => {
    try {
      dispatch(openCreateEditReturn());
      createNew ? props.refresh(true, returnStatusFilter.value, returnInspectionFilter.value) : props.refresh(false, returnStatusFilter.value, returnInspectionFilter.value);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClearComplete = async () => {
    const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
    newReturnInfo.complete = 0;
    setReturnInfo(new ReturnModel(newReturnInfo));
    returnsService.updateReturn(newReturnInfo);
  };

  const handleClearNotFound = async () => {
    try {
      const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
      const results = await returnsService.lpnLookup(newReturnInfo.lpn, 'true');
      setSearching(true);
      if (results.lpn === 'not_found') {
        setNotFound(true);
      } else {
        setReturnInfo(new ReturnModel(results));
        console.log(results);
      }
      setSearching(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleFraudMissing = async () => {
    const newReturnInfo = { ...returnInfo };
    const results = await returnsService.markFraudMissing(newReturnInfo.lpn, user.id);
    setReturnInfo(new ReturnModel(results));
  };

  // const printActualUPCEAN = () => {    
  //   var element = document.getElementById('actual_upc_ean_barcode'); 
  //   html2canvas(element).then((canvas) => {
  //     var imgData = canvas.toDataURL('image/png');
  //     var link = document.createElement('a');

  //     document.body.appendChild(link); // for Firefox
  
  //     link.setAttribute('href', imgData);
  //     link.setAttribute('download', 'upc_ean.png');
  //     link.click();
  //   });
  // };

  const printUPCEAN = () => {    
    var element = document.getElementById('upc_ean_barcode'); 
    element.style.display = 'block';
    html2canvas(element).then((canvas) => {
      var imgData = canvas.toDataURL('image/png');
      var link = document.createElement('a');

      document.body.appendChild(link); // for Firefox
  
      link.setAttribute('href', imgData);
      link.setAttribute('download', 'actual_upc_ean.png');
      link.click();
      element.style.display = 'none';
    });
  };

  const handleToggleScanType = async (scanType) => {
    try {
      const newReturnInfo = JSON.parse(JSON.stringify(returnInfo));
      newReturnInfo.single_upc_ean = scanType === 'upc_ean' ? 1 : 0;
      setReturnInfo(newReturnInfo);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteReturn = async () => {
    try {
      await returnsService.deleteReturn(returnInfo.id, returnInfo.single_upc_ean);
      dispatch(openCreateEditReturn());
      props.refresh(false, returnStatusFilter.value, returnInspectionFilter.value);
    } catch (e) {
      console.log(e);
    }
  };

  const downloadImages = () => {
    if (returnInfo.lpn_upc_image !== '') saveAs(returnInfo.lpn_upc_image, `lpn_upc_image_${returnInfo.upc_ean}.jpeg`);
    if (returnInfo.label_packing_slip_image !== '') saveAs(returnInfo.label_packing_slip_image, `label_packing_slip_image_${returnInfo.upc_ean}.jpeg`);
    if (returnInfo.issue_image_1 !== '') saveAs(returnInfo.issue_image_1, `issue_image_1_${returnInfo.upc_ean}.jpeg`);
    if (returnInfo.issue_image_2 !== '') saveAs(returnInfo.issue_image_2, `issue_image_2_${returnInfo.upc_ean}.jpeg`);
    if (returnInfo.issue_image_3 !== '') saveAs(returnInfo.issue_image_3, `issue_image_3_${returnInfo.upc_ean}.jpeg`);
    if (returnInfo.issue_image_4 !== '') saveAs(returnInfo.issue_image_4, `issue_image_4_${returnInfo.upc_ean}.jpeg`);
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="lg" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <GiReturnArrow style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Create / Edit Return
      </ModalHeader>
      <ModalBody className="create-edit-return">
        { openReturnReimbursementDetailToggle &&
          <ReturnReimbursementDetail isOpen={openReturnReimbursementDetailToggle} downloadImages={downloadImages} />
        }
        { openImageCaptureToggle &&
          <ImageCapture isOpen={openImageCaptureToggle} capture={captureLabelPackingSlip} captureIssue={captureIssueImages} captureLPNUPC={captureLPNUPC} />
        }
        <Row>
          <Col lg={12}>
            { (returnInfo.not_found === 1) &&
              <div style={{float: 'right'}} className="text-primary">***Not Found***</div>
            }
          </Col>
          { returnInfo.upc_ean === '' && returnInfo.lpn === '' && returnInfo.single_upc_ean === 0 &&
            <Col lg={12}>
              <Button size="sm" style={{float: 'right'}} color="outline-primary" onClick={() => handleToggleScanType('upc_ean')}>UPC Scan</Button>
            </Col>
          }
          { returnInfo.upc_ean === '' && returnInfo.lpn === '' && returnInfo.single_upc_ean === 1 &&
            <Col lg={12}>
              <Button size="sm" style={{float: 'right'}} color="outline-primary" onClick={() => handleToggleScanType('lpn')}>LPN Scan</Button>
            </Col>
          }
          { returnInfo.single_upc_ean === 0 &&
            <Col lg={12} className="box">
              <FormGroup>
                <Label for="lpn" className="input-label">LPN </Label>
                <Input id="lpn" name="lpn" disabled={returnInfo.complete === 1} autoFocus={true} placeholder="Scan/Enter LPN" style={{fontSize: '12px', width: '150px', textAlign: 'center'}} type="text" value={returnInfo.lpn} onChange={(e) => handleAddLPN(e)} />
                { searching &&
                  <div style={{fontSize: '14px'}}>Searching...</div>
                }
              </FormGroup>
            </Col>
          }
          { returnInfo.single_upc_ean === 1 &&
            <Col lg={12} className="box">
              <FormGroup>
                <Label for="single_upc_scan" className="input-label">UPC </Label>
                <Input id="single_upc_scan" name="single_upc_scan" disabled={returnInfo.complete === 1} autoFocus={true} placeholder="Scan/Enter UPC" style={{fontSize: '12px', width: '150px', textAlign: 'center'}} type="text" value={returnInfo.upc_ean} onChange={(e) => handleAddSingleScanUPC(e)} />
                { searching &&
                  <div style={{fontSize: '14px'}}>Searching...</div>
                }
              </FormGroup>
            </Col>
          }
          { notFound && returnInfo.fraud_missing === 0 &&
            <>
              <Col lg={12}>
                <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-warning" onClick={() => handleNotFound()}>Mark Not Found</Button>
                <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleFraudMissing()}>Fraud/Missing</Button>
              </Col>
            </>
          }
          { returnInfo.not_found === 1 && 
            <React.Fragment>
              <Col lg={12}>
                <div className="section-header" style={{marginTop: '10px'}}> Placement Instructions</div>
                <hr style={{marginTop: '5px'}}/>
              </Col>
              <Col lg={12} style={{textAlign: 'center'}}>
                <div className="placement-instructions text-success">Please place the item into Box "B"</div>
              </Col>
            </React.Fragment>
          }
        </Row>
        { (returnInfo.id !== 0 && returnInfo.not_found === 0) &&
          <React.Fragment>
            { returnInfo.single_upc_ean === 0 &&
              <React.Fragment>
                <Row>
                  <Col lg={12}>
                    <div className="section-header" style={{ marginTop: '5px' }}>
                      {returnInfo.fraud_missing === 1 ? (
                        <Input
                          id="product_description"
                          name="product_description"
                          disabled={returnInfo.complete === 1}
                          placeholder="Enter Product Description"
                          style={{ fontSize: '12px' }}
                          type="text"
                          value={returnInfo.product_description}
                          onChange={(e) => handleUpdateInputField(e)}
                        />
                      ) : (
                        returnInfo.product_description
                      )}
                    </div>
                    <hr style={{ marginTop: '5px' }} />
                  </Col>
                  { reimbursementData.length > 0 &&
                    <Col lg={12} style={{textAlign: 'right'}}>
                      <HiOutlineCurrencyDollar className="reimburse-icon" onClick={() => dispatch((openReturnReimbursementDetail({return_info: returnInfo, reimbursement_data: reimbursementData})))}/>
                    </Col>
                  }
                  <Col lg={8}>
                    <table>
                      <tbody>
                        <tr>
                          <td className="category-titles">Brand:</td>
                          <td className="category-text pointer">
                            {returnInfo.fraud_missing === 1 ? (
                              <Input
                                id="brand"
                                name="brand"
                                disabled={returnInfo.complete === 1}
                                placeholder="Enter Brand"
                                style={{ fontSize: '12px' }}
                                type="text"
                                value={returnInfo.brand}
                                onChange={(e) => handleUpdateInputField(e)}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  navigator.clipboard.writeText(returnInfo.brand)
                                }
                              >
                                {returnInfo.brand}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">LPN:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(returnInfo.lpn)}>
                            <span>{returnInfo.lpn}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">Order ID:</td>
                          <td className="category-text pointer">
                            {returnInfo.fraud_missing === 1 ? (
                              <Input
                                id="order_id"
                                name="order_id"
                                disabled={returnInfo.complete === 1}
                                placeholder="Enter Order ID"
                                style={{ fontSize: '12px' }}
                                type="text"
                                value={returnInfo.order_id}
                                onChange={(e) => handleUpdateInputField(e)}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  navigator.clipboard.writeText(returnInfo.order_id)
                                }
                              >
                                {returnInfo.order_id}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">UPC/EAN:</td>
                          <td className="category-text pointer">
                            {returnInfo.fraud_missing === 1 ? (
                              <Input
                                id="upc_ean"
                                name="upc_ean"
                                disabled={returnInfo.complete === 1}
                                placeholder="Enter UPC/EAN"
                                style={{ fontSize: '12px' }}
                                type="number"
                                value={returnInfo.upc_ean}
                                onChange={(e) => handleUpdateInputField(e)}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  navigator.clipboard.writeText(returnInfo.upc_ean)
                                }
                              >
                                {returnInfo.upc_ean}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">MPN:</td>
                          <td className="category-text pointer">
                            {returnInfo.fraud_missing === 1 ? (
                              <Input
                                id="manufacturer_part_no"
                                name="manufacturer_part_no"
                                disabled={returnInfo.complete === 1}
                                placeholder="Enter MPN"
                                style={{ fontSize: '12px' }}
                                type="text"
                                value={returnInfo.manufacturer_part_no}
                                onChange={(e) => handleUpdateInputField(e)}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    returnInfo.manufacturer_part_no
                                  )
                                }
                              >
                                {returnInfo.manufacturer_part_no}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">ASIN:</td>
                          <td className="category-text pointer">
                            {returnInfo.fraud_missing === 1 ? (
                              <Input
                                id="asin"
                                name="asin"
                                disabled={returnInfo.complete === 1}
                                placeholder="Enter ASIN"
                                style={{ fontSize: '12px' }}
                                type="text"
                                value={returnInfo.asin}
                                onChange={(e) => handleUpdateInputField(e)}
                              />
                            ) : (
                              <>
                                <span
                                  onClick={() =>
                                    navigator.clipboard.writeText(returnInfo.asin)
                                  }
                                >
                                  {returnInfo.asin}
                                </span>
                                <span>
                                  <BsBoxArrowInUpRight
                                    className="edit-icon"
                                    onClick={() =>
                                      window.open(
                                        `https://www.amazon.com/gp/product/${returnInfo.asin}?psc=1`,
                                        '_blank'
                                      )
                                    }
                                  />
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">FNSKU:</td>
                          <td className="category-text pointer">
                            {returnInfo.fraud_missing === 1 ? (
                              <Input
                                id="fnsku"
                                name="fnsku"
                                disabled={returnInfo.complete === 1}
                                placeholder="Enter FNSKU"
                                style={{ fontSize: '12px' }}
                                type="text"
                                value={returnInfo.fnsku}
                                onChange={(e) => handleUpdateInputField(e)}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  navigator.clipboard.writeText(returnInfo.fnsku)
                                }
                              >
                                {returnInfo.fnsku}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">Cost:</td>
                          <td className="category-text pointer">
                            {returnInfo.fraud_missing === 1 ? (
                              <Input
                                id="cost"
                                name="cost"
                                disabled={returnInfo.complete === 1}
                                placeholder="Enter Cost"
                                style={{ fontSize: '12px' }}
                                type="number"
                                value={returnInfo.cost}
                                onChange={(e) => handleUpdateInputField(e)}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  navigator.clipboard.writeText(returnInfo.cost)
                                }
                              >
                                {returnInfo.cost}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">Price:</td>
                          <td className="category-text pointer">
                            {returnInfo.fraud_missing === 1 ? (
                              <Input
                                id="price"
                                name="price"
                                disabled={returnInfo.complete === 1}
                                placeholder="Enter Price"
                                style={{ fontSize: '12px' }}
                                type="number"
                                value={returnInfo.price}
                                onChange={(e) => handleUpdateInputField(e)}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  navigator.clipboard.writeText(returnInfo.price)
                                }
                              >
                                {returnInfo.price}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">Return Date:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(returnInfo.return_date)}>
                            <span>{returnInfo.return_date}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">Return Reason:</td>
                          <td className="category-text pointer">
                            {returnInfo.fraud_missing === 1 ? (
                              <Input
                                id="reason"
                                name="reason"
                                disabled={returnInfo.complete === 1}
                                placeholder="Enter Return Reason"
                                style={{ fontSize: '12px' }}
                                type="text"
                                value={returnInfo.reason}
                                onChange={(e) => handleUpdateInputField(e)}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  navigator.clipboard.writeText(returnInfo.reason)
                                }
                              >
                                {returnInfo.reason}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">Customer Comments:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(returnInfo.customer_comments)}>
                            <span>{returnInfo.customer_comments}</span>
                          </td>
                        </tr>
                        { returnInfo.product_inspection === 'ifmm' &&
                          <React.Fragment>
                            <tr>
                              <td className="category-titles">Case ID:</td>
                              <td className="category-text pointer">
                                { showInputCaseId &&
                                  <React.Fragment>
                                    <input type="number" name="case_id" style={{fontSize: '12px', width: '100px'}} value={caseId} onChange={(e) => setCaseId(e.target.value)}/>
                                    <FaCheck className="edit-icon" style={{marginTop: '3px'}} onClick={() => updateCaseReimbursementInfo('case_id')} />
                                  </React.Fragment>
                                }
                                { (caseId && caseId !== '' && !showInputCaseId) &&
                                  <React.Fragment>
                                    <span onClick={() => navigator.clipboard.writeText(caseId)}>{caseId}</span>
                                    <span>
                                      { returnInfo.complete === 0 &&
                                        <FaRegEdit className="edit-icon" onClick={() => setShowInputCaseId(true)} />
                                      }
                                      <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://sellercentral.amazon.com/cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${caseId}`, '_blank')} />
                                    </span>
                                  </React.Fragment>
                                }
                                { ((!caseId || caseId === '') && !showInputCaseId && returnInfo.complete === 0) &&
                                  <FaRegPlusSquare className="edit-icon" onClick={() => setShowInputCaseId(true)} />
                                }
                              </td>
                            </tr>
                            <tr>
                              <td className="category-titles">Reimbursement ID:</td>
                              <td className="category-text pointer">
                                { showInputReimbursementId &&
                                  <React.Fragment>
                                    <input type="number" name="reimbursement_id" style={{fontSize: '12px', width: '100px'}} value={reimbursementId} onChange={(e) => setReimbursementId(e.target.value)}/>
                                    <FaCheck className="edit-icon" style={{marginTop: '3px'}} onClick={() => updateCaseReimbursementInfo('reimbursement_id')} />
                                  </React.Fragment>
                                }
                                { (reimbursementId && reimbursementId !== '' && !showInputReimbursementId) &&
                                  <React.Fragment>
                                    <span onClick={() => navigator.clipboard.writeText(reimbursementId)}>{reimbursementId}</span>
                                    <span>
                                      { returnInfo.complete === 0 &&
                                        <FaRegEdit className="edit-icon" onClick={() => setShowInputReimbursementId(true)} />
                                      }
                                      <BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open (`https://sellercentral.amazon.com/reportcentral/REIMBURSEMENTS/0`, '_blank')} />
                                    </span>
                                  </React.Fragment>
                                }
                                { ((!reimbursementId || reimbursementId === '') && !showInputReimbursementId && returnInfo.complete === 0) &&
                                  <FaRegPlusSquare className="edit-icon" onClick={() => setShowInputReimbursementId(true)} />
                                }
                              </td>
                            </tr>
                          </React.Fragment>
                        }
                      </tbody>
                    </table>
                  </Col>
                  <Col lg={4} style={{textAlign: 'center'}}>
                    <img src={returnInfo.image} className="image"  onClick={() => window.open(returnInfo.image)}/>
                  </Col>
                </Row>
              </React.Fragment>
            }
            
            <Row>
              <Col lg={12}>
                <div className="section-header" style={{marginTop: '10px'}}> Product Inspection</div>
                <hr style={{marginTop: '5px'}}/>
              </Col>
              <Col lg={12} style={{textAlign: 'center'}}>
                <div className="info-text text-danger" style={{marginBottom: '20px'}}>Please inspect the product and select one of the below options</div>
                { returnInfo.product_inspection === 'plc' && 
                  <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '130px', marginRight: '10px'}} color="primary">Product Looks Correct and New</Button>
                }
                { returnInfo.product_inspection !== 'plc' && 
                  <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '130px', marginRight: '10px'}} color="outline-primary" onClick={() => handleProductInspection('plc')}>Product Looks Correct and New</Button>
                }
                { returnInfo.product_inspection === 'pugc' && 
                  <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '130px', marginRight: '10px'}} color="primary">Product Used Good Condition</Button>
                }
                { returnInfo.product_inspection !== 'pugc' && 
                  <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '130px', marginRight: '10px'}} color="outline-primary" onClick={() => handleProductInspection('pugc')}>Product Used Good Condition</Button>
                }
                { returnInfo.product_inspection === 'pdu' && 
                  <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '130px', marginRight: '10px'}} color="primary">Product Used Bad Condition or Defective</Button>
                }
                { returnInfo.product_inspection !== 'pdu' && 
                  <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '130px', marginRight: '10px'}} color="outline-primary" onClick={() => handleProductInspection('pdu')}>Product Used Bad Condition or Defective</Button>
                }
                { returnInfo.product_inspection === 'ifmm' && 
                  <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '170px', marginRight: '10px'}} color="primary">Incorrect Product / Fraud / Missing Major Parts</Button>
                }
                { returnInfo.product_inspection !== 'ifmm' && 
                  <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '170px', marginRight: '10px'}} color="outline-primary" onClick={() => handleProductInspection('ifmm')}>Incorrect Product / Fraud / Missing Major Parts</Button>
                }
                { returnInfo.product_inspection === 'ifmm' &&
                  <>
                    <div className="info-text text-danger" style={{marginTop: '10px', marginBottom: '10px'}}>Please the most appropriate issue with this product</div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <FormGroup tag="fieldset" style={{textAlign: 'left'}}>
                        <FormGroup check style={{fontSize: '13px'}}>
                          <Input
                            id="prod_missing"
                            name="issue_type"
                            type="radio"
                            checked={returnInfo.issue_type === 'prod_missing'}
                            onChange={() => handleIssueType('prod_missing')}
                          />
                          {' '}
                          <Label check htmlFor="prod_missing">
                            Product Missing
                          </Label>
                        </FormGroup>
                        <FormGroup check style={{fontSize: '13px'}}>
                          <Input
                            id="prod_wrong_model"
                            name="issue_type"
                            type="radio"
                            checked={returnInfo.issue_type === 'prod_wrong_model'}
                            onChange={() => handleIssueType('prod_wrong_model')}
                          />
                          {' '}
                          <Label check htmlFor="prod_wrong_model">
                            Wrong Model
                          </Label>
                        </FormGroup>
                        <FormGroup check style={{fontSize: '13px'}}>
                          <Input
                            id="prod_wrong_size"
                            name="issue_type"
                            type="radio"
                            checked={returnInfo.issue_type === 'prod_wrong_size'}
                            onChange={() => handleIssueType('prod_wrong_size')}
                          />
                          {' '}
                          <Label check htmlFor="prod_wrong_size">
                            Wrong Size
                          </Label>
                        </FormGroup>
                        <FormGroup check style={{fontSize: '13px'}}>
                          <Input
                            id="prod_wrong_color"
                            name="issue_type"
                            type="radio"
                            checked={returnInfo.issue_type === 'prod_wrong_color'}
                            onChange={() => handleIssueType('prod_wrong_color')}
                          />
                          {' '}
                          <Label check htmlFor="prod_wrong_color">
                            Wrong Color
                          </Label>
                        </FormGroup>
                        <FormGroup check style={{fontSize: '13px'}}>
                          <Input
                            id="prod_wrong_brand"
                            name="issue_type"
                            type="radio"
                            checked={returnInfo.issue_type === 'prod_wrong_brand'}
                            onChange={() => handleIssueType('prod_wrong_brand')}
                          />
                          {' '}
                          <Label check htmlFor="prod_wrong_brand">
                            Wrong Brand
                          </Label>
                        </FormGroup>
                        <FormGroup check style={{fontSize: '13px'}}>
                          <Input
                            id="prod_wrong_product"
                            name="issue_type"
                            type="radio"
                            checked={returnInfo.issue_type === 'prod_wrong_product'}
                            onChange={() => handleIssueType('prod_wrong_product')}
                          />
                          {' '}
                          <Label check htmlFor="prod_wrong_product">
                            Wrong Product
                          </Label>
                        </FormGroup>
                        <FormGroup check style={{fontSize: '13px'}}>
                          <Input
                            id="prod_serial_no_match"
                            name="issue_type"
                            type="radio"
                            checked={returnInfo.issue_type === 'prod_serial_no_match'}
                            onChange={() => handleIssueType('prod_serial_no_match')}
                          />
                          {' '}
                          <Label check htmlFor="prod_serial_no_match">
                            Serial Number Does Not Match
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  </>
                }
              </Col>
            </Row>
            { returnInfo.product_inspection !== '' &&
              <React.Fragment>
                { returnInfo.product_inspection === 'plc' && 
                  <Row>
                    <Col lg={12}>
                      <div className="section-header" style={{marginTop: '10px'}}> Packaging Inspection</div>
                      <hr style={{marginTop: '5px'}}/>
                    </Col>
                    <Col lg={12} style={{textAlign: 'center'}}>
                      <div className="info-text text-danger" style={{marginBottom: '20px'}}>Information on fixing packaging and removing LPN and tape, etc</div>
                      { returnInfo.packaging_inspection === 'good' && 
                        <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '150px', marginRight: '10px'}} color="info">Packaging Like New / Fixable</Button>
                      }
                      { returnInfo.packaging_inspection !== 'good' && 
                        <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '150px', marginRight: '10px'}} color="outline-info" onClick={() => handlePackageInspection('good')}>Packaging Like New / Fixable</Button>
                      }
                      { returnInfo.packaging_inspection === 'bad' && 
                        <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '150px', marginRight: '10px'}} color="info">Packaging Bad / Not Fixable</Button>
                      }
                      { returnInfo.packaging_inspection !== 'bad' && 
                        <Button size="md" disabled={returnInfo.complete === 1 || returnInfo.single_upc_ean === 1} style={{width: '150px', marginRight: '10px'}} color="outline-info" onClick={() => handlePackageInspection('bad')}>Packaging Bad / Not Fixable</Button>
                      }
                    </Col>
                  </Row>
                }
                { returnInfo.product_inspection === 'ifmm' && returnInfo.issue_type !== '' &&
                  <Row>
                    <Col lg={12}>
                      <div className="section-header" style={{marginTop: '10px'}}> Incorrect Item Info</div>
                      <hr style={{marginTop: '5px'}}/>
                    </Col>
                    <Col lg={4} style={{textAlign: 'center'}}>
                      <FormGroup>
                        <Label for="tracking_number" className="input-label">Tracking Number </Label>
                        <Input id="tracking_number" disabled={returnInfo.complete === 1} name="tracking_number" placeholder="Scan/Enter tracking number" style={{fontSize: '12px'}} type="text" value={returnInfo.tracking_number} onChange={(e) => handleUpdateInputField(e)} />
                      </FormGroup>
                    </Col>
                    <Col lg={4} style={{textAlign: 'center'}}>
                      <FormGroup>
                        <Label for="removal_id" className="input-label">Removal ID </Label>
                        <Input id="removal_id" disabled={returnInfo.complete === 1} name="removal_id" placeholder="Scan/Enter removal ID" style={{fontSize: '12px'}} type="text" value={returnInfo.removal_id} onChange={(e) => handleUpdateInputField(e)} />
                      </FormGroup>
                    </Col>
                    <Col lg={4} style={{textAlign: 'center'}}>
                      <FormGroup>
                        <Label for="shipment_id" className="input-label">Shipment ID </Label>
                        <Input id="shipment_id" disabled={returnInfo.complete === 1} name="shipment_id" placeholder="Scan/Enter shipment ID" style={{fontSize: '12px'}} type="number" value={returnInfo.shipment_id} onChange={(e) => handleUpdateInputField(e)} />
                      </FormGroup>
                    </Col>
                    <Col lg={12} style={{marginTop: '10px'}}>
                      <div className="info-text text-danger" style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>Please take a picture of the shipping label and packing slip together and drag it on to the area below</div>
                    </Col>
                    <Col lg={12} style={{textAlign: 'right', marginBottom: '10px'}}>
                      <Button size="sm" color="outline-secondary" style={{marginLeft: '5px'}} onClick={() => dispatch(openImageCapture())}><AiOutlineCamera style={{fontSize: '18px', marginBottom: '3px'}}/> Capture Image</Button>
                    </Col>
                    <Col lg={7} style={{marginLeft: '185px'}}>
                      <Uploady multiple={false} destination={{ url: returnInfo.complete === 1 ? '' : `${endpoints.api}/returns/upload-label-packing-slip` }}>
                        <PasteUploadDropZone params={returnInfo}>
                          Drag & Drop Shipping and Packing Slip Image Here
                        </PasteUploadDropZone>
                        <UploadStatus />
                      </Uploady>
                    </Col>
                    { returnInfo.label_packing_slip_image !== '' &&
                      <Col lg={2} style={{textAlign: 'right'}}>
                        <AiOutlinePaperClip className="image-icon" onClick={() => window.open(returnInfo.label_packing_slip_image)}/>
                      </Col>
                    }
                    {/* new */}

                    <Col lg={12} style={{marginTop: '10px'}}>
                      <div className="info-text text-danger" style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>Please take a picture of the LPN and UPC together on package</div>
                    </Col>
                    <Col lg={12} style={{textAlign: 'right', marginBottom: '10px'}}>
                      <Button size="sm" color="outline-secondary" style={{marginLeft: '5px'}} onClick={() => dispatch(openImageCapture('lpn_upc'))}><AiOutlineCamera style={{fontSize: '18px', marginBottom: '3px'}}/> Capture Image</Button>
                    </Col>
                    <Col lg={7} style={{marginLeft: '185px'}}>
                      <Uploady multiple={false} destination={{ url: returnInfo.complete === 1 ? '' : `${endpoints.api}/returns/upload-lpn-upc` }}>
                        <PasteUploadDropZone params={returnInfo}>
                          Drag & Drop LPN and UPC Image Here
                        </PasteUploadDropZone>
                        <UploadStatus />
                      </Uploady>
                    </Col>
                    { returnInfo.lpn_upc_image !== '' &&
                      <Col lg={2} style={{textAlign: 'right'}}>
                        <AiOutlinePaperClip className="image-icon" onClick={() => window.open(returnInfo.lpn_upc_image)}/>
                      </Col>
                    }

                    {/* new */}
                    <Col lg={12}>
                      <div className="info-text text-danger" style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>Please take 4 pictures of the item OUTSIDE of the packaging showing the issue from multiple angles</div>
                    </Col>
                    <Col lg={12} style={{textAlign: 'right', marginBottom: '10px'}}>
                      <Button size="sm" color="outline-secondary" style={{marginLeft: '5px'}} onClick={() => dispatch(openImageCapture('issue'))}><AiOutlineCamera style={{fontSize: '18px', marginBottom: '3px'}}/> Capture Image</Button>
                    </Col>
                    <Col lg={7} style={{marginLeft: '185px'}}>
                      <Uploady grouped destination={{ url: returnInfo.complete === 1 ? '' : `${endpoints.api}/returns/upload-issue-images` }}>
                        <PasteUploadDropZone params={returnInfo}>
                          Drag & Drop Package Issue Images Here
                        </PasteUploadDropZone>
                        <UploadStatus />
                      </Uploady>
                    </Col>
                    { (returnInfo.issue_image_1 !== '' || returnInfo.issue_image_2 !== '' || returnInfo.issue_image_3 !== '' || returnInfo.issue_image_4 !== '') &&
                      <Col lg={2} style={{textAlign: 'right'}}>
                        { returnInfo.issue_image_1 !== '' && <AiOutlinePaperClip className="image-icon" onClick={() => window.open(returnInfo.issue_image_1)}/> }
                        { returnInfo.issue_image_2 !== '' && <AiOutlinePaperClip className="image-icon" onClick={() => window.open(returnInfo.issue_image_2)}/> }
                        { returnInfo.issue_image_3 !== '' && <AiOutlinePaperClip className="image-icon" onClick={() => window.open(returnInfo.issue_image_3)}/> }
                        { returnInfo.issue_image_4 !== '' && <AiOutlinePaperClip className="image-icon" onClick={() => window.open(returnInfo.issue_image_4)}/> }
                      </Col>
                    }
                  </Row>
                }
                { (returnInfo.packaging_inspection === 'bad'  || (returnInfo.packaging_inspection === 'good' && (returnInfo.blacklist_upc === 1 || returnInfo.blacklist_brand === 1))) &&
                  <Row>
                    <Col lg={12}>
                      <div className="section-header" style={{marginTop: '10px'}}> Placement Instructions</div>
                      <hr style={{marginTop: '5px'}}/>
                    </Col>
                    <Col lg={12} style={{textAlign: 'center'}}>
                      { (returnInfo.cost > 0 && returnInfo.cost < 30) &&
                        <div className="placement-instructions text-success">Please place the item into Box "C"</div>
                      }
                      { (returnInfo.cost === 0 || returnInfo.cost > 30) &&
                        <div className="placement-instructions text-success">Please place the item into Box "A"</div>
                      }
                    </Col>
                  </Row>

                }
                { (returnInfo.packaging_inspection === 'good' && returnInfo.blacklist_upc === 0 && returnInfo.blacklist_brand === 0) &&
                  <Row>
                    <Col lg={12}>
                      <div className="section-header" style={{marginTop: '10px'}}> Placement Instructions</div>
                      <hr style={{marginTop: '5px'}}/>
                    </Col>
                    <Col lg={12} style={{textAlign: 'right'}}>
                      <Button size="sm" color="outline-secondary" style={{marginLeft: '5px'}} onClick={() => printUPCEAN()}><TiExportOutline style={{fontSize: '16px', marginBottom: '3px'}}/> Print New UPC To Scan</Button>
                    </Col>
                    <Col lg={12} style={{textAlign: 'center', marginBottom: '20px'}}>
                      <div className="placement-instructions text-success">Please remove LPN and scan in the UPC</div>
                      <div style={{display: 'none'}} id="upc_ean_barcode"><Barcode value={`${returnInfo.upc_ean}`} /></div>
                    </Col>
                    {/* <Col lg={12} style={{textAlign: 'center', marginBottom: '20px'}}>
                      <div className="info-text text-danger" style={{ marginTop: '20px', textAlign: 'center' }}>If the actual UPC/EAN differs from the one above, please scan in the actual UPC/EAN in the box below</div>
                    </Col> */}
                    <Col lg={12} className="box">
                      <Input id="actual_upc_ean" disabled={returnInfo.complete === 1} name="actual_upc_ean" placeholder="Scan/Enter UPC/EAN" style={{fontSize: '12px', width: '150px'}} type="number" value={returnInfo.actual_upc_ean} onChange={(e) => handleUpdateInputField(e)} />
                    </Col>
                    <Col lg={12} style={{textAlign: 'center'}}>
                      { (returnInfo.actual_upc_ean && returnInfo.actual_upc_ean !== '') &&
                        <div id="actual_upc_ean_barcode"><Barcode value={`${returnInfo.actual_upc_ean}`} /></div>
                      }
                    </Col>
                  </Row>

                }
                { returnInfo.product_inspection === 'pdu' && 
                  <Row>
                    <Col lg={12}>
                      <div className="section-header" style={{marginTop: '10px'}}> Placement Instructions</div>
                      <hr style={{marginTop: '5px'}}/>
                    </Col>
                    <Col lg={12} style={{textAlign: 'center'}}>
                      { (returnInfo.cost > 0 && returnInfo.cost < 30) &&
                        <div className="placement-instructions text-success">Please place the item into Box "C"</div>
                      }
                      { (returnInfo.cost === 0 || returnInfo.cost > 30) &&
                        <div className="placement-instructions text-success">Please place the item into Box "B"</div>
                      }
                    </Col>
                  </Row>
                }
                { returnInfo.product_inspection === 'pugc' && 
                  <Row>
                    <Col lg={12}>
                      <div className="section-header" style={{marginTop: '10px'}}> Placement Instructions</div>
                      <hr style={{marginTop: '5px'}}/>
                    </Col>
                    <Col lg={12} style={{textAlign: 'center'}}>
                      { (returnInfo.cost > 0 && returnInfo.cost < 30) &&
                        <div className="placement-instructions text-success">Please place the item into Box "C"</div>
                      }
                      { (returnInfo.cost === 0 || returnInfo.cost > 30) &&
                        <div className="placement-instructions text-success">Please place the item into Box "A"</div>
                      }
                    </Col>
                  </Row>
                }
                { 
                  (
                    (returnInfo.label_packing_slip_image !== '' && returnInfo.lpn_upc_image !== '' && (returnInfo.issue_image_1 !== '' || returnInfo.issue_image_2 !== '' || returnInfo.issue_image_3 !== '' || returnInfo.issue_image_4 !== '') && returnInfo.tracking_number !== '' && returnInfo.removal_id !== '' && returnInfo.shipment_id !== '') ||
                    (notFound || returnInfo.not_found === 1)
                  ) && 
                  <React.Fragment>
                    <Row>
                      <Col lg={12}>
                        <div className="section-header" style={{marginTop: '10px'}}> Placement Instructions</div>
                        <hr style={{marginTop: '5px'}}/>
                      </Col>
                      <Col lg={12} style={{textAlign: 'center'}}>
                        <div className="placement-instructions text-success">Please place the item into Box "B"</div>
                      </Col>
                    </Row>
                    <Row style={{marginTop: '20px'}}>
                      { returnInfo.product_inspection === 'ifmm' &&
                        <ReturnComms returnInfo={returnInfo} downloadImages={downloadImages} />
                      }
                    </Row>
                  </React.Fragment>
                }
              </React.Fragment>
            }
          </React.Fragment>
        }
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
          {
            (
              (
                (returnInfo.packaging_inspection === 'good' && returnInfo.blacklist_upc === 0 && returnInfo.blacklist_brand === 0) || 
                (returnInfo.packaging_inspection === 'bad'  || (returnInfo.packaging_inspection === 'good' && (returnInfo.blacklist_upc === 1 || returnInfo.blacklist_brand === 1))) || 
                (
                  (returnInfo.product_inspection === 'pdu' || returnInfo.product_inspection === 'pugc') ||
                  ((returnInfo.label_packing_slip_image !== '') && (returnInfo.lpn_upc_image !== '') && (returnInfo.issue_image_1 !== '' || returnInfo.issue_image_2 !== '' || returnInfo.issue_image_3 !== '' || returnInfo.issue_image_4 !== '') && returnInfo.tracking_number !== '' && returnInfo.removal_id !== '' && returnInfo.shipment_id !== '')
                )
              ) && returnInfo.complete === 0
            ) &&
            <React.Fragment>
              <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleFinalize(false)}>Complete</Button>
              <Button size="sm" style={{float: 'right'}} color="outline-primary" onClick={() => handleFinalize(true)}>Complete & Create New</Button>
            </React.Fragment>
          }
          { returnInfo.complete === 1 &&
            <React.Fragment>
              <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleCreateNew(true)}>Create New</Button>
              <Button size="sm" style={{float: 'left', marginLeft: '5px'}} color="outline-warning" onClick={() => handleClearComplete()}>Clear Complete</Button>
            </React.Fragment>
          }
          { 
            (
              (
                !(returnInfo.packaging_inspection === 'good' && returnInfo.blacklist_upc === 0 && returnInfo.blacklist_brand === 0) &&
                !(returnInfo.packaging_inspection === 'bad'  || (returnInfo.packaging_inspection === 'good' && (returnInfo.blacklist_upc === 1 || returnInfo.blacklist_brand === 1))) &&
                !(
                  returnInfo.product_inspection === 'pdu' || returnInfo.product_inspection === 'pugc' || 
                  ((returnInfo.label_packing_slip_image !== '') && (returnInfo.lpn_upc_image !== '') && (returnInfo.issue_image_1 !== '' || returnInfo.issue_image_2 !== '' || returnInfo.issue_image_3 !== '' || returnInfo.issue_image_4 !== '') && returnInfo.tracking_number !== '' && returnInfo.removal_id !== '' && returnInfo.shipment_id !== '')
                )
              ) && (returnInfo.complete === 0 && returnInfo.id !== 0 && !notFound && returnInfo.not_found !== 1)
            ) &&
            <React.Fragment>
              <Button size="sm" style={{float: 'right'}} color="outline-warning" onClick={() => handleCreateNew(true)}>Save & Create New</Button>
            </React.Fragment>
          }
          { returnInfo.complete === 0 && returnInfo.id !== 0 &&
            <React.Fragment>
              <Button size="sm" style={{float: 'left'}} color="outline-danger" onClick={() => handleDeleteReturn()}>Delete</Button>
            </React.Fragment>
          }
          { returnInfo.not_found === 1 &&
            <Button size="sm" style={{float: 'left', marginLeft: '5px'}} color="outline-warning" onClick={() => handleClearNotFound()}>Clear Not Found & Search</Button>
          }
          {/* { returnInfo.product_inspection === 'ifmm' &&
            <Button size="sm" style={{float: 'left', marginLeft: '5px'}} color="outline-primary" onClick={() => downloadImages()}><FaDownload style={{fontSize: '14px', marginBottom: '3px'}}/> Images</Button>
          } */}
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default CreateEditReturn;