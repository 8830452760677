import React, { useEffect, useState } from 'react';
import { FaRegListAlt } from 'react-icons/fa';
// import { BiError } from 'react-icons/bi';
import { BsLightningChargeFill } from 'react-icons/bs';
import { RiAdminLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SubMenu,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { useSelector } from 'react-redux';
import './SideNavigation.css';

const SideNavigation = () => {
  const user = useSelector(state => state.user);
  const sidebarCollapse = useSelector(state => state.sidebar_collapse);
  const viewOptions = useSelector(state => state.view_options);
  const adminViewOptions = useSelector(state => state.admin_view_options);
  const titanViewOptions = useSelector(state => state.titan_view_options);
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  const handleSelect = (url) => {
    navigate(url);
    setActiveIndex(url);
  };

  useEffect(() => {
    if ((viewOptions && viewOptions.length) > 0 || (adminViewOptions && adminViewOptions.length) || (titanViewOptions && titanViewOptions.length)) setActiveIndex(window.location.pathname);
  }, [viewOptions, adminViewOptions, titanViewOptions]);

  return (
    <ProSidebar className="side-bar-height" collapsed={sidebarCollapse} breakPoint="lg" theme="light">
      <Menu>
        { user.admin === 1 &&
          <SubMenu title="Retail Fulfillment" icon={<FaRegListAlt />} defaultOpen>
            { viewOptions && viewOptions.map((v) =>
              <MenuItem key={v.id} onClick={() => handleSelect(v.url)} active={v.url === activeIndex ? true : false}>{v.label}</MenuItem>
            )}
          </SubMenu>
        }
        { (user.admin === 1 || user.removals === 1 || user.single_sku_shipments === 1 || user.returns === 1 || user.shipment_invoices === 1 || user.comp_pricing === 1 || user.backstock === 1) &&
          <SubMenu title="Titan" icon={<BsLightningChargeFill />} defaultOpen>
            { titanViewOptions && titanViewOptions.map((v) =>
              <React.Fragment key={v.id} >
                { user.admin === 1 && 
                  <MenuItem onClick={() => handleSelect(v.url)} active={v.url === activeIndex ? true : false}>{v.label}</MenuItem>
                }
                { user.admin === 0 && user.removals === 1 && v.id === 14 && 
                  <MenuItem onClick={() => handleSelect(v.url)} active={v.url === activeIndex ? true : false}>{v.label}</MenuItem>
                }
                { user.admin === 0 && user.single_sku_shipments === 1 && v.id === 16 && 
                  <MenuItem onClick={() => handleSelect(v.url)} active={v.url === activeIndex ? true : false}>{v.label}</MenuItem>
                }
                { user.admin === 0 && user.returns === 1 && v.id === 13 && 
                  <MenuItem onClick={() => handleSelect(v.url)} active={v.url === activeIndex ? true : false}>{v.label}</MenuItem>
                }
                { user.admin === 0 && user.shipment_invoices === 1 && v.id === 17 && 
                  <MenuItem onClick={() => handleSelect(v.url)} active={v.url === activeIndex ? true : false}>{v.label}</MenuItem>
                }
                { user.admin === 0 && user.comp_pricing === 1 && v.id === 18 && 
                  <MenuItem onClick={() => handleSelect(v.url)} active={v.url === activeIndex ? true : false}>{v.label}</MenuItem>
                }
                { user.admin === 0 && user.backstock === 1 && v.id === 20 && 
                  <MenuItem onClick={() => handleSelect(v.url)} active={v.url === activeIndex ? true : false}>{v.label}</MenuItem>
                }
              </React.Fragment>
            )}
          </SubMenu>
        }
        { user.admin === 1 && 
          <SubMenu title="Admin" icon={<RiAdminLine />} defaultOpen>
            { adminViewOptions && adminViewOptions.map((v) =>
              <MenuItem key={v.id} onClick={() => handleSelect(v.url)} active={v.url === activeIndex ? true : false}>{v.label}</MenuItem>
            )}
          </SubMenu>
        }
      </Menu>
    </ProSidebar>
  );
};

export default SideNavigation;