export default class User {
  constructor(data) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.email = data.email;
    this.validated = data.validated;
    this.mfaEnabled = data.mfaEnabled === 1 ? true : false;
    this.requiredMfa = data.requiredMfa;
    this.admin = data.admin;
    this.removals = data.removals;
    this.single_sku_shipments = data.single_sku_shipments;
    this.returns = data.returns;
    this.shipment_invoices = data.shipment_invoices;
    this.comp_pricing = data.comp_pricing;
    this.backstock = data.backstock;
  } 
}