import axios from 'axios';
import endpoints from '../endpoints/endpoints';

let returnTrackingService = {};

returnTrackingService.getReturnTracking = async (startDate, endDate, selectedStatus) => {
  try {
    const results = await axios.get(`${endpoints.api}/return-tracking?start_date=${startDate}&end_date=${endDate}&status=${selectedStatus.value}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnTrackingService.getReturnData = async (trackingNumber) => {
  try {
    const results = await axios.get(`${endpoints.api}/return-tracking/return-data?tracking_number=${trackingNumber}`, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnTrackingService.updateReturnData = async (trackingNumber, shipmentId, caseId, reimbursementId) => {
  try {
    const results = await axios.put(`${endpoints.api}/return-tracking/return-data`, {tracking_number: trackingNumber, shipment_id: shipmentId, case_id: caseId, reimbursement_id: reimbursementId}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnTrackingService.completeReturn = async (trackingNumber) => {
  try {
    const results = await axios.put(`${endpoints.api}/return-tracking/complete-return`, {tracking_number: trackingNumber}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

returnTrackingService.clearComplete = async (trackingNumber) => {
  try {
    const results = await axios.put(`${endpoints.api}/return-tracking/clear-complete`, {tracking_number: trackingNumber}, { withCredentials: true });
    return results.data;
  } catch (e) {
    console.log(e);
    if (e.response.status == 403 || e.response.status == 401) {
      window.location.href = '/';
    }
  }
};

export default returnTrackingService;