import moment from 'moment';

export default class InbounShipmentItem {
  constructor(data) {
    this.inbound_tracking_number = data && data.inbound_tracking_number ? data.inbound_tracking_number : '';
    this.outbound_tracking_number = data && data.outbound_tracking_number ? data.outbound_tracking_number : null;
    this.reference_numbers = data && data.reference_numbers ? data.reference_numbers : '';
    this.status = data && data.status ? data.status : '';
    this.scheduled_delivery = data && data.scheduled_delivery ? moment(new Date(data.scheduled_delivery)).format('MM/DD/YY hh:mm A') : null;
    this.date_delivered = data && data.date_delivered ? moment(new Date(data.date_delivered)).format('MM/DD/YY hh:mm A') : '';
    this.date_delivered_clean = data && data.date_delivered ? data.date_delivered : '';
    this.shipper_name = data && data.shipper_name ? data.shipper_name.trim() : '';
    this.shipper_city = data && data.shipper_city ? data.shipper_city : '';
    this.shipper_country = data && data.shipper_country ? data.shipper_country : '';
    this.shipper_state = data && data.shipper_state ? data.shipper_state : '';
    this.service_code = data && data.service_code ? data.service_code : '';
    this.service = data && data.service ? data.service : '';
    this.package_reference_no_4 = data && data.package_reference_no_4 ? data.package_reference_no_4 : '';
    this.package_weight = data && data.package_weight ? data.package_weight : 0;
    this.package_height = data && data.package_height ? data.package_height : 0;
    this.package_length = data && data.package_length ? data.package_length : 0;
    this.package_width = data && data.package_width ? data.package_width : 0;
    this.activity_date = data && data.activity_date ? moment(new Date(data.activity_date)).format('MM/DD/YY hh:mm A') : null;
    this.activity_type = data && data.activity_type ? data.activity_type : '';
    this.notes = data && data.notes ? data.notes : '';
    this.carrier = data && data.carrier ? data.carrier : '';
    this.carrier_updated = data && data.carrier_updated ? data.carrier_updated : 0;
    this.category = data && data.category ? data.category : null;
    this.shipment_id = data && data.shipment_id ? data.shipment_id : null;
    this.prep_notes = data && data.prep_notes ? data.prep_notes : null;
    this.overweight = this.package_weight > 48 ? 1 : 0;
    this.batch_name = data && data.batch_name ? data.batch_name : null;
    this.sku = data && data.sku ? data.sku : null;
    this.quantity = data && data.quantity ? data.quantity : null;
    this.qty_to_move = data && data.qty_to_move ? data.qty_to_move : 0;
    this.order_id = data && data.order_id ? data.order_id : '';
    this.shipping_label_link = data && data.shipping_label_link ? data.shipping_label_link : '';
  } 
}
