import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FaCubes } from 'react-icons/fa';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
// import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import { Store } from 'react-notifications-component';
import { openCreateEditBackstock } from '../../redux/actions';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import { PulseLoader } from 'react-spinners';
import backstockService from '../../services/backstock.service';
import BackstockItem from '../../models/backstock_item.model';
import Barcode from 'react-barcode';
import * as html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FiPrinter } from 'react-icons/fi';

const CreateEditBackstockModal = (props) => {
  const dispatch = useDispatch();

  const info = useSelector(state => state.open_create_edit_backstock_info);
  const user = useSelector(state => state.user);

  const [productSearchString, setProductSearchString] = useState('');
  const [changeTimer, setChangeTimer] = useState(null);
  const [searching, setSearching] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [product, setProduct] = useState(info?.backstock_id ? info : null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scanBarcode, setScanBarcode] = useState('');
  const [printing, setPrinting] = useState(false);

  const handleProductLookup = (e) => {
    const value = e.target.value;

    setProductSearchString(value);
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }
    
    if (value !== productSearchString) {
      const timer = setTimeout(async () => {
        try {
          setSearching(true);
          setNotFound(false);
          if (value === '') {
            setProduct(new BackstockItem());
          } else {
            const results = await backstockService.productSearch(value);
            if (results.id === 0) {
              setNotFound(true);
              setSearching(false);
            } else {
              setProduct(new BackstockItem(results));
            }
          }

          setSearching(false);
        } catch (e) {
          console.log(e);
        }
      }, 400);
  
      setChangeTimer(timer);
    }
  };

  const handleClose = (createNew) => {
    dispatch(openCreateEditBackstock(createNew));
    props.refresh();
  };

  const validateRows = () => {
    if (!product || product.po_name === '' || product.num_cases < 1 || product.case_qty < 1 || product.shelf_location === '' || product.weight < .01 || product.length < .01 || product.width < .01 || product.height < .01) {
      return { valid: false };
    }

    return { valid: true };
  };

  const handleSubmit = async (createNew) => {
    const validation = validateRows();
    if (!validation.valid) {
      Store.addNotification({
        title: 'Submission Error',
        message: 'PO Name, Shelf Location, Case QTY, Weight, Length, Width, and Height are required to create a label.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: false
        }
      });
      return; // Stop the submission since validation failed
    }

    setIsSubmitting(true); // Start loading

    try {
      backstockService.updateBackstock(product, 'submit_backstock');
      setTimeout(() => {
        // backstockService.submitInboundShipmentSingle(rows, product.sku);
        Store.addNotification({
          title: 'Backstock Submission',
          message: 'Backstock submitted successfully.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        setIsSubmitting(false); // Stop loading
        props.clearSelectedRows(); // Assuming you have a function to clear selected rows
        handleClose(createNew); // Close the modal
      }, 1500);
    } catch (error) {
      console.error('Failed to submit backstock:', error);
      setIsSubmitting(false); // Stop loading
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setProduct({
      ...product,
      [id]: value,
      barcode_printed: 0
    });
  };

  const handleComplete = async () => {
    setIsSubmitting(true);
    await backstockService.updateBackstock(product, 'complete_backstock');
    setIsSubmitting(false);
    handleClose();
  };

  const handleResetStatus = async () => {
    setIsSubmitting(true); // Start loading
    await backstockService.updateBackstock(product, 'submit_backstock');
    setIsSubmitting(false); // Start loading
    handleClose();
  };

  const createBarcode = async () => {

    const newProduct = product;
    newProduct.barcode_created = 1;

    const results = await backstockService.createBackstock(newProduct);
    setProduct({
      ...product,
      barcode_created: 1,
      backstock_id: results.backstock_id,
      backstock_group_ids: results.backstock_group_ids
    });
  };

  const printBarcodeLabel = async () => {
    setPrinting(true);
    await new Promise(resolve => setTimeout(resolve, 200));
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'in',
      format: [4, 6]
    });
  
    for (let i = 0; i < product.backstock_group_ids.length; i++) {
      const id = product.backstock_group_ids[i];
      let element = document.getElementById(`barcode_label_${id}`);

      element.style.display = 'block'; // Ensure the element is visible for printing

      const canvas = await html2canvas(element, { scale: 1.5 });
      const imgData = canvas.toDataURL('image/png');
  
      // Only hide the element after capturing it if it's not the first one
      if (i !== 0) {
        element.style.display = 'none';
      }
  
      pdf.addImage(imgData, 'PNG', 0, 0, 4, 6);
  
      if (id !== product.backstock_group_ids[product.backstock_group_ids.length - 1]) {
        pdf.addPage();
      }
    }
  
    const filename = `backstock_cases_${product?.backstock_id}.pdf`;
  
    const newProduct = product;
    newProduct.barcode_printed = 1;
    backstockService.updateBackstock(newProduct, 'print_barcode_label');
  
    pdf.save(filename);
    setProduct({
      ...product,
      barcode_printed: 1
    });
    setPrinting(false);
  };

  const handleDownload = () => {
    window.open(product.label_url);
    const newProduct = product;
    newProduct.label_printed = 1;
    setProduct({
      ...product,
      label_printed: 1
    });
    backstockService.updateBackstock(newProduct, 'label_printed');
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    await backstockService.updateBackstock(product, 'delete_backstock');
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="lg" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FaCubes style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Create / Edit Backstock
      </ModalHeader>
      <ModalBody className="backstock">
        {isSubmitting ? (
          <Row>
            <Col lg={12}>
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <PulseLoader sizeUnit={'px'} size={10} color={'#123abc'} loading={isSubmitting} />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            { product?.status !== 'shipment_created' && product?.status !== 'complete' &&
              <Row>
                <Col lg={12} className="box">
                  <FormGroup>
                    <Label for="scan_item" className="input-label">SKU/FNSKU/MPN/UPC/Part Number </Label>
                    <Input id="scan_item" name="scan_item" disabled={product?.barcode_created === 1} autoFocus={true} placeholder="Scan/Enter SKU/FNSKU/MPN/UPC/Part Number" style={{fontSize: '12px', width: '250px', textAlign: 'center'}} type="text" value={productSearchString} onChange={(e) => handleProductLookup(e)}/>
                    { searching &&
                      <div style={{fontSize: '14px'}}>Searching...</div>
                    }
                    { notFound &&
                      <div className="not-found">Item Not Found</div>
                    }
                  </FormGroup>
                </Col>
              </Row>
            }
            { product?.status === 'shipment_created' && product?.status !== 'complete' &&
              <Row>
                <Col lg={12} className="box">
                  <FormGroup>
                    <Label for="scan_barcode" className="input-label" style={{color: 'red', fontStyle: 'italic'}}>Please scan barcode </Label>
                    <Input id="scan_barcode" name="scan_barcode" autoFocus={true} placeholder="Barcode" style={{fontSize: '12px', width: '250px', textAlign: 'center'}} type="text" value={scanBarcode} onChange={(e) => setScanBarcode(e.target.value)}/>
                  </FormGroup>
                </Col>
              </Row>
            }
            { product && (product.id !== 0 || product.backstock_id !== 0) &&
              <>
                <Row>
                  <Col lg={12}>
                    <div className="section-header" style={{marginTop: '5px'}}> {product.product_description}</div>
                    <hr style={{marginTop: '5px'}}/>
                  </Col>
                  <Col lg={8}>
                    <table>
                      <tbody>
                        <tr>
                          <td className="category-titles">Brand:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.brand)}>
                            <span>{product.brand}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">mSKU:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.sku)}>
                            <span>{product.sku}</span>
                            <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open(`https://sellercentral.amazon.com/skucentral?mSku=${product.sku}`, '_blank')} /></span>
                          </td>
                        </tr>
                        { product.fnsku !== '' &&
                          <tr>
                            <td className="category-titles">FNSKU:</td>
                            <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.fnsku)}>
                              <span>{product.fnsku}</span>
                            </td>
                          </tr>
                        }
                        { product.lpn !== '' &&
                          <tr>
                            <td className="category-titles">LPN:</td>
                            <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.lpn)}>
                              <span>{product.lpn}</span>
                            </td>
                          </tr>
                        }
                        <tr>
                          <td className="category-titles">UPC/EAN:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.upc_ean)}>
                            <span>{product.upc_ean}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">MPN:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.manufacturer_part_no)}>
                            <span>{product.manufacturer_part_no}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="category-titles">ASIN:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.asin)}>
                            <span>{product.asin}</span>
                            <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open(`https://www.amazon.com/gp/product/${product.asin}?psc=1`, '_blank')} /></span>
                          </td>
                        </tr>
                        { user.admin === 1 &&
                          <>
                            <tr>
                              <td className="category-titles">Cost:</td>
                              <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.cost)}>
                                <span>{product.cost}</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="category-titles">Price:</td>
                              <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.price)}>
                                <span>{product.price}</span>
                              </td>
                            </tr>
                          </>
                        }
                        {
                          <tr>
                            <td className="category-titles">Case #:</td>
                            <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.backstock_id)}>
                              <span>{product.backstock_id}</span>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </Col>
                  <Col lg={4} style={{textAlign: 'center'}}>
                    <img src={product.image} className="image"  onClick={() => window.open(product.image)}/>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="section-header" style={{marginTop: '5px'}}> Backstock Detail</div>
                    <hr style={{marginTop: '5px'}}/>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="po_name" className="input-label">PO Name:</Label>
                      <Input id="po_name" disabled={product.status === 'shipment_created' || product.status === 'complete'} placeholder="PO Name" type="text" value={product.po_name} style={{fontSize: '12px'}} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="shelf_location" className="input-label">Shelf Location:</Label>
                      <Input id="shelf_location" disabled={product.status === 'shipment_created' || product.status === 'complete'} placeholder="Shelf Location" type="text" value={product.shelf_location} style={{fontSize: '12px'}} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup>
                      <Label for="case_qty" className="input-label">Case QTY:</Label>
                      <Input id="case_qty" disabled={product.status === 'shipment_created' || product.status === 'complete'} placeholder="Case QTY" type="number" value={product.case_qty} style={{fontSize: '12px'}} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup>
                      <Label for="num_cases" className="input-label"># of Cases:</Label>
                      <Input id="num_cases" disabled={product.status === 'shipment_created' || product.status === 'complete'} placeholder="# of Cases" type="number" value={product.num_cases} style={{fontSize: '12px'}} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup>
                      <Label for="case_weight" className="input-label">Weight:</Label>
                      <Input id="case_weight" disabled={product.status === 'shipment_created' || product.status === 'complete'} placeholder="Weight" type="number" value={product.case_weight} style={{fontSize: '12px'}} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup>
                      <Label for="case_length" className="input-label">Length:</Label>
                      <Input id="case_length" disabled={product.status === 'shipment_created' || product.status === 'complete'} placeholder="Length" type="number" value={product.case_length} style={{fontSize: '12px'}} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup>
                      <Label for="case_width" className="input-label">Width:</Label>
                      <Input id="case_width" disabled={product.status === 'shipment_created' || product.status === 'complete'} placeholder="Width" type="number" value={product.case_width} style={{fontSize: '12px'}} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup>
                      <Label for="case_height" className="input-label">Height:</Label>
                      <Input id="case_height" disabled={product.status === 'shipment_created' || product.status === 'complete'} placeholder="Height" type="number" value={product.case_height} style={{fontSize: '12px'}} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <FormGroup>
                      <Label for="notes" className="input-label">Notes (optional):</Label>
                      <Input id="notes" disabled={product.status === 'shipment_created' || product.status === 'complete'} placeholder="Notes" type="textarea" value={product.notes} style={{fontSize: '12px'}} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                </Row>
                { product.barcode_created === 1 && product.status !== 'shipment_created' && product?.status !== 'complete' &&
                  <Row>
                    <Col lg={2}></Col>
                    <Col lg={7} style={{textAlign: 'right', marginBottom: '5px'}}>
                      <FiPrinter className="label-print" data-tip="Print Label" onClick={() => printBarcodeLabel('barcode_label')}/>
                    </Col>
                    { product.backstock_group_ids.map((id, index) => (
                      <Col lg={12} key={id} className="box">
                        { (index === 0 || printing) &&
                          <div style={{border: '1px solid black'}}>
                            <div style={{height: '6in', width: '4in', padding: 5}} id={`barcode_label_${id}`}>
                              <div style={{textAlign: 'center', marginLeft: '15px', marginTop: '10px', fontSize: '17px'}}>
                                <div style={{ position: 'absolute', top: '40%', left: '-.5in', transform: 'rotate(-90deg)', transformOrigin: 'center center', width: '6in', textAlign: 'center' }}>
                                  <div className="label-case-number"># {id}</div>
                                </div>
                                <div style={{ position: 'absolute', top: '40%', right: '-.5in', transform: 'rotate(90deg)', transformOrigin: 'center center', width: '6in', textAlign: 'center' }}>
                                  <div className="label-case-number"># {id}</div>
                                </div>
                                <div className="label-case-number"># {id}</div>
                                <div>Product: {product.product_description.substring(0, 30)}</div>
                                <div>Case Qty: {product.case_qty}</div>
                                <div>UPC/EAN: {product.upc_ean}</div>
                              </div>
                              <br />
                              <p className="label-identifier">BS</p>
                              <br />
                              <br />
                              <div style={{textAlign: 'center'}}>
                                <Barcode value={`bs_${id}`} />
                              </div>
                              <div style={{textAlign: 'center', marginLeft: '15px', marginTop: '10px', fontSize: '17px'}}>
                                <div>Loc: <span className="label-case-number">{product.shelf_location}</span></div>
                                <div>{product.case_weight}lbs - {product.case_length}x{product.case_width}x{product.case_height}</div>
                              </div>
                            </div>
                          </div>
                        }
                      </Col>
                    ))}
                  </Row>
                }
                { product?.label_printed === 1 &&
                  <Row>
                    <Col lg={12} style={{textAlign: 'center'}}><p className="shipping-label-printed-message">*** Shipping label printed previously ***</p></Col>
                  </Row>
                }
                { (scanBarcode === `bs_${product.backstock_id}` || product.status === 'complete') &&
                  <Row>
                    <Col lg={12} style={{textAlign: 'center'}}>
                      <Button size="sm" style={{marginLeft: '5px'}} color="outline-primary" onClick={handleDownload}>Download Shipping Label</Button>
                    </Col>
                  </Row>
                }
              </>
            }
          </>
        )}
      </ModalBody>
      { !isSubmitting && 
        <ModalFooter>
          <Col lg={12} style={{width: '100%'}}>
            { product?.status === 'processing' &&
              <Button size="sm" style={{float: 'left', marginLeft: '5px'}} color="outline-danger" onClick={() => handleDelete()}>Delete</Button>
            }
            <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
            { product?.status !== 'shipment_created' && product?.status !== 'complete' &&
              <>
                <Button size="sm" disabled={!product || product.barcode_printed === 0} style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleSubmit(false)}>Submit</Button>
                <Button size="sm" disabled={!product || product.barcode_printed === 0} style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleSubmit(true)}>Submit & Create New</Button>
                <Button size="sm" hidden={product?.backstock_id != 0} disabled={!product || product.case_qty < 1 || product.shelf_location === '' || product.weight < .01 || product.length < .01 || product.width < .01 || product.height < .01 } style={{float: 'left', marginLeft: '5px'}} color="outline-success" onClick={() => createBarcode()}>Create Label</Button>
                <Button size="sm" hidden={product?.backstock_id < 1} disabled={!product || product.case_qty < 1 || product.shelf_location === '' || product.weight < .01 || product.length < .01 || product.width < .01 || product.height < .01 } style={{float: 'left', marginLeft: '5px'}} color="outline-success" onClick={() => printBarcodeLabel('barcode_label')}>Print Label</Button>
              </>
            }
            { product?.status === 'shipment_created' && scanBarcode === `bs_${product.backstock_id}` &&
              <Button size="sm" disabled={product?.label_printed !== 1} style={{float: 'right', marginLeft: '5px'}} color="outline-success" onClick={() => handleComplete()}>Complete</Button>
            }
            { product?.status === 'shipment_created' && user.admin === 1 &&
              <Button size="sm" style={{float: 'left', marginLeft: '5px'}} color="outline-danger" onClick={() => handleResetStatus()}>Reset Status</Button>
            }
          </Col>
        </ModalFooter>
      }
    </Modal>
  );
};

export default CreateEditBackstockModal;