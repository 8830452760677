import React from 'react';

export default class ProfitReportModel {
  constructor(data, index) {
    this.id = index;
    this.order_id = data.order_id;
    this.channel = data.channel;
    this.channel_order_id = data.channel_order_id;
    this.customer = `${data.first_name} ${data.last_name}`;
    this.distributor = data.distributor ? data.distributor.toUpperCase() : null;
    this.internal_product_id = data.internal_product_id;
    this.sales_description = data.sales_description;
    this.time_of_order = data.time_of_order;
    this.total = (Math.round(data.total * 100) / 100).toFixed(2);
    this.total_tax = (Math.round(data.total_tax * 100) / 100).toFixed(2);
    this.product_cost = (Math.round(data.product_cost * 100) / 100).toFixed(2);
    this.shipping_cost = (Math.round(data.shipping_cost * 100) / 100).toFixed(2);
    this.extra_fees = (Math.round(data.extra_fees * 100) / 100).toFixed(2);
    this.channel_fee = (Math.round(data.channel_fee * 100) / 100).toFixed(2);
    this.net_profit = (Math.round(data.net_profit * 100) / 100).toFixed(2);
    const netProfit = (Math.round(data.net_profit * 100) / 100);
    if (netProfit < 0) {
      this.net_profit_color = <div className="text-danger">{netProfit.toFixed(2)}</div>;
    } else if (netProfit > 0 && netProfit < 5) {
      this.net_profit_color = <div className="dark-orange">{netProfit.toFixed(2)}</div>;
    } else {
      this.net_profit_color = netProfit.toFixed(2);
    }
    this.sale_total = (Math.round(data.sale_total * 100) / 100).toFixed(2);
  }
}