import React, { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { Button, Col } from 'reactstrap';
import { FaDownload, FaRegCopy } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

const ReturnComms = (props) => {
  const [issueCommunicationText, setIssueCommunicationText] = useState('');

  const copyToClipboard = (id) => {
    let r = document.createRange();
    const element = document.getElementById(id);
    r.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    Store.addNotification({
      title: 'Copy To Clipboard',
      message: 'Communication copied to clipboard.',
      type: 'default',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 2000,
        onScreen: false
      }
    });
  };

  useEffect(() => {
    const updateTextBasedOnIssueType = () => {
      switch(props.returnInfo.issue_type) {
      case 'prod_wrong_model':
        return <><br/><br/>WRONG MODEL!<br/><br/></>;
      case 'prod_wrong_size':
        return <><br/><br/>WRONG SIZE!<br/><br/></>;
      case 'prod_wrong_color':
        return <><br/><br/>WRONG COLOR!<br/><br/></>;
      case 'prod_wrong_brand':
        return <><br/><br/>WRONG BRAND!<br/><br/></>;
      case 'prod_wrong_product':
        return <><br/><br/>WRONG PRODUCT!<br/><br/></>;
      case 'prod_serial_no_match':
        return <><br/><br/>SERIAL NUMBER DOES NOT MATCH!<br/><br/></>;
      default:
        return null;
      }
    };
    
    // Update the state based on the current issueType
    setIssueCommunicationText(updateTextBasedOnIssueType());
  }, [props.returnInfo.issue_type]);

  return (
    <React.Fragment>
      <ReactTooltip />
      <Col lg={6}>
        <div className="section-header"> Communication</div>
      </Col>
      <Col lg={6} style={{textAlign: 'right'}}>
        <Button size="sm" color="outline-secondary" onClick={() => props.downloadImages()}><FaDownload style={{fontSize: '14px', marginBottom: '3px'}}/> Images</Button>
        <Button size="sm" color="outline-primary" style={{marginLeft: '5px'}} onClick={() => window.open (`https://sellercentral.amazon.com/help/hub/support/INTENT_DA_RequestRemovalsAndDisposalIssues`, '_blank')}><FiSend style={{fontSize: '15px', marginBottom: '3px'}}/> Submit</Button>
      </Col>
      <Col lg={12}>
        <hr style={{marginTop: '5px'}}/>
      </Col>
      <Col lg={6} style={{marginTop: '20px'}}>
        <div className="comms-headers">For Fraud:</div>
      </Col>
      <Col lg={6} style={{textAlign: 'right', marginTop: '15px'}}>
        <Button data-tip="Copy fraud communication" size="sm" color="outline-primary" onClick={() => copyToClipboard('fraud_text')}><FaRegCopy style={{marginBottom: '3px'}} /></Button>
      </Col>
      <Col lg={12} style={{marginTop: '10px'}}>
        <div id="fraud_text">
          <p className="comms-text">We received an item or items back from amazon FBA returns that was NOT CORRECT. {issueCommunicationText}The item was swapped out by the buyer for another item. This is FRAUD and against amazon TOS. Please reimburse the item(s), and investigate your customer for fraud.</p>
          <div className="comms-text"><span className="comms-headers">LPN</span><span>: {props.returnInfo.lpn}</span></div>
          <div className="comms-text"><span className="comms-headers">ASIN</span><span>: {props.returnInfo.asin}</span></div>
          <div className="comms-text"><span className="comms-headers">MSKU</span><span>: {props.returnInfo.sku}</span></div>
          <div className="comms-text"><span className="comms-headers">UPC/EAN</span><span>: {props.returnInfo.upc_ean}</span></div>
          <div className="comms-text"><span className="comms-headers">Title</span><span>: {props.returnInfo.product_description}</span></div>
          <div className="comms-text"><span className="comms-headers">Quantity</span><span>: 1</span></div>
          <div className="comms-text"><span className="comms-headers">Removal Order ID</span><span>: {props.returnInfo.removal_id}</span></div>
          <div className="comms-text"><span className="comms-headers">Shipment ID</span><span>: {props.returnInfo.shipment_id}</span></div>
          <div className="comms-text"><span className="comms-headers">Tracking</span><span>: {props.returnInfo.tracking_number}</span></div>
        </div>
      </Col>
      <Col lg={6} style={{marginTop: '20px'}}>
        <div className="comms-headers">For Missing:</div>
      </Col>
      <Col lg={6} style={{textAlign: 'right', marginTop: '15px'}}>
        <Button data-tip="Copy missing communication" size="sm" color="outline-primary" onClick={() => copyToClipboard('missing_text')}><FaRegCopy style={{marginBottom: '3px'}} /></Button>
      </Col>
      <Col lg={12} style={{marginTop: '10px'}}>
        <div id="missing_text">
          <p className="comms-text">We received a unit back from amazon FBA returns that was MISSING THE ACTUAL OR MAJOR PARTS. Customer may have returned empty packaging with missing part(s) or Amazon warehouse members may have failed to properly bag/tape the item and protect it from falling out. Please reimburse and update your staff to tape / bag returned items.</p>
          <div className="comms-text"><span className="comms-headers">LPN</span><span>: {props.returnInfo.lpn}</span></div>
          <div className="comms-text"><span className="comms-headers">ASIN</span><span>: {props.returnInfo.asin}</span></div>
          <div className="comms-text"><span className="comms-headers">MSKU</span><span>: {props.returnInfo.sku}</span></div>
          <div className="comms-text"><span className="comms-headers">UPC/EAN</span><span>: {props.returnInfo.upc_ean}</span></div>
          <div className="comms-text"><span className="comms-headers">Title</span><span>: {props.returnInfo.product_description}</span></div>
          <div className="comms-text"><span className="comms-headers">Quantity</span><span>: 1</span></div>
          <div className="comms-text"><span className="comms-headers">Removal Order ID</span><span>: {props.returnInfo.removal_id}</span></div>
          <div className="comms-text"><span className="comms-headers">Shipment ID</span><span>: {props.returnInfo.shipment_id}</span></div>
          <div className="comms-text"><span className="comms-headers">Tracking</span><span>: {props.returnInfo.tracking_number}</span></div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default ReturnComms;
