import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FaAmazon, FaRegTimesCircle } from 'react-icons/fa';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import inboundShipmentsService from '../../services/inbound_shipments.service';
import { Store } from 'react-notifications-component';
import { openCreateEditSingle } from '../../redux/actions';
import SingleSkuShipmentItem from '../../models/single_sku_shipment_item.model';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import { PulseLoader } from 'react-spinners';

const { SearchBar } = Search;

const CreateEditSingleModal = (props) => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.open_create_edit_single_info);
  const [rows, setRows] = useState(info);
  const [columns, setColumns] = useState(info);
  const [productSearchString, setProductSearchString] = useState('');
  const [changeTimer, setChangeTimer] = useState(null);
  const [searching, setSearching] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [product, setProduct] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleProductLookup = (e) => {
    const value = e.target.value;

    setProductSearchString(value);
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }

    if (value !== productSearchString) {
      const timer = setTimeout(async () => {
        try {
          setSearching(true);
          setNotFound(false);
          if (value === '') {
            setProduct(new SingleSkuShipmentItem());
          } else {
            const results = await inboundShipmentsService.productSearch(value);
            if (results.id === 0) {
              setNotFound(true);
              setSearching(false);
            } else {
              setProduct(new SingleSkuShipmentItem(results));
            }
          }

          setSearching(false);
        } catch (e) {
          console.log(e);
        }
      }, 400);
  
      setChangeTimer(timer);
    }
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'Prep Notes',
        dataField: 'prep_notes',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Delivery Date',
        dataField: 'date_delivered',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        editable: false
      },
      {
        text: 'Qty',
        dataField: 'quantity',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Weight',
        dataField: 'package_weight',
        sort: true,
        style: (cell, row) => {
          if (row.package_weight > 48) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_weight}</span>;
        }
      },
      {
        text: 'L',
        dataField: 'package_length',
        sort: true,
        style: (cell, row) => {
          if (row.package_length > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_length}</span>;
        }
      },
      {
        text: 'W',
        dataField: 'package_width',
        sort: true,
        style: (cell, row) => {
          if (row.package_width > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_width}</span>;
        }
      },
      {
        text: 'H',
        dataField: 'package_height',
        sort: true,
        style: (cell, row) => {
          if (row.package_height > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_height}</span>;
        }
      },
      {
        text: 'OWT',
        dataField: 'overweight',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        editable: false,
        formatter: (cell, row) => {
          if (row.overweight === 1) {
            return <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
          }
        }
      },
      {
        text: 'Qty to Move',
        dataField: 'qty_to_move',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Inbound Tracking Number',
        dataField: 'inbound_tracking_number',
        headerStyle: {width: '140px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        editable: false
      }
    ];

    setColumns(newColumns);
  }, [rows, setColumns]);

  const afterSaveCell = (oldValue, newValue, row, column) => {
    newValue = newValue && column.dataField === 'package_length' || column.dataField === 'package_width' || column.dataField === 'package_height' || column.dataField === 'qty_to_move' || column.dataField === 'package_weight' || column.dataField === 'quantity' ? parseFloat(newValue) : newValue;
    setRows(currentRows => {
      const rowIndex = currentRows.findIndex(r => r.inbound_tracking_number === row.inbound_tracking_number);
      if (rowIndex === -1) return currentRows; // Row not found, no update needed.
  
      let newRow;

      if (column.dataField === 'package_weight') {
        newRow = {
          ...currentRows[rowIndex],
          [column.dataField]: newValue,
          overweight: newValue > 48 ? 1 : 0 // Assuming this logic is correct.
        };
      } else if (column.dataField === 'package_length' || column.dataField === 'package_width' || column.dataField === 'package_height' || column.dataField === 'qty_to_move' || column.dataField === 'prep_notes' || column.dataField === 'quantity') {
        newRow = {
          ...currentRows[rowIndex],
          [column.dataField]: newValue
        };
      }

      // Create a new array with the updated row.
      return [
        ...currentRows.slice(0, rowIndex),
        newRow,
        ...currentRows.slice(rowIndex + 1)
      ];
    });
  };

  const cellEdit = cellEditFactory({
    mode: 'click',
    afterSaveCell: afterSaveCell,
    blurToSave: true
  });

  const handleClose = () => {
    dispatch(openCreateEditSingle());
    props.refresh();
  };

  const validateRows = () => {
    // Check each row for required fields
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      // Check if any of the required fields are missing or invalid (e.g., not filled in)
      if (notFound || !product || product.sku === '' || row.package_weight <= 0 || row.package_length <= 0 || row.package_width <= 0 || row.package_height <= 0 || !row.quantity || row.quantity === 0) {
        // If any field is missing, return an object indicating failure and the row index
        return { valid: false, rowIndex: i + 1 }; // Adding 1 to make it human-readable (counting starts at 1)
      }
    }
    // If all rows are valid, return an object indicating success
    return { valid: true };
  };

  const handleSubmit = async () => {
    const validation = validateRows();
    if (!validation.valid) {
      Store.addNotification({
        title: 'Single Submission Error',
        message: 'SKU, Quantity, Weight, Length, Width, and Height are required to submit.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: false
        }
      });
      return; // Stop the submission since validation failed
    }

    setIsSubmitting(true); // Start loading

    try {
      setTimeout(() => {
        inboundShipmentsService.submitInboundShipmentSingle(rows, product.sku);
        Store.addNotification({
          title: 'Single Submission',
          message: 'Inbound shipment single submitted successfully.',
          type: 'default',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        setIsSubmitting(false); // Stop loading
        props.clearSelectedRows(); // Assuming you have a function to clear selected rows
        handleClose(); // Close the modal
      }, 1500);
    } catch (error) {
      console.error('Failed to submit inbound shipment dims:', error);
      setIsSubmitting(false); // Stop loading
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="xl" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FaAmazon style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Inbound Shipments Single
      </ModalHeader>
      <ModalBody className="create-edit-shipment-invoices">
        {isSubmitting ? (
          <Row>
            <Col lg={12}>
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <PulseLoader sizeUnit={'px'} size={10} color={'#123abc'} loading={isSubmitting} />
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={12} className="box">
              <FormGroup>
                <Label for="scan_item" className="input-label">SKU/MPN/UPC/Part Number </Label>
                <Input id="scan_item" name="scan_item" autoFocus={true} placeholder="Scan/Enter UPC/LPN/ASIN/FNSKU" style={{fontSize: '12px', width: '250px', textAlign: 'center'}} type="text" value={productSearchString} onChange={(e) => handleProductLookup(e)}/>
                { searching &&
                  <div style={{fontSize: '14px'}}>Searching...</div>
                }
                { notFound &&
                  <div className="not-found">Item Not Found</div>
                }
              </FormGroup>
            </Col>
            { product && product.id !== 0 &&
              <>
                <Col lg={12}>
                  <div className="section-header" style={{marginTop: '5px'}}> {product.product_description}</div>
                  <hr style={{marginTop: '5px'}}/>
                </Col>
                <Col lg={8}>
                  <table>
                    <tbody>
                      <tr>
                        <td className="category-titles">Brand:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.brand)}>
                          <span>{product.brand}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">mSKU:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.sku)}>
                          <span>{product.sku}</span>
                          <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open(`https://sellercentral.amazon.com/skucentral?mSku=${product.sku}`, '_blank')} /></span>
                        </td>
                      </tr>
                      { product.lpn !== '' &&
                        <tr>
                          <td className="category-titles">LPN:</td>
                          <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.lpn)}>
                            <span>{product.lpn}</span>
                          </td>
                        </tr>
                      }
                      <tr>
                        <td className="category-titles">UPC/EAN:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.upc_ean)}>
                          <span>{product.upc_ean}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">MPN:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.manufacturer_part_no)}>
                          <span>{product.manufacturer_part_no}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">ASIN:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.asin)}>
                          <span>{product.asin}</span>
                          <span><BsBoxArrowInUpRight className="edit-icon" onClick={() => window.open(`https://www.amazon.com/gp/product/${product.asin}?psc=1`, '_blank')} /></span>
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">Cost:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.cost)}>
                          <span>{product.cost}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">Price:</td>
                        <td className="category-text pointer" onClick={() => navigator.clipboard.writeText(product.price)}>
                          <span>{product.price}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col lg={4} style={{textAlign: 'center'}}>
                  <img src={product.image} className="image"  onClick={() => window.open(product.image)}/>
                </Col>
              </>
            }
            <Col lg={12}>
              <div className="section-header" style={{marginTop: '5px'}}> Shipments</div>
              <hr style={{marginTop: '5px'}}/>
            </Col>
            <Col lg={12}>
              { columns &&
                <ToolkitProvider keyField="inbound_tracking_number" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                  {
                    props => (
                      <div>
                        <div style = {{ float: 'right', width: '200px' }}>
                          <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                        </div>
                        <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" condensed bordered={false} cellEdit={cellEdit} noDataIndication={() => (<div>There are currently no shipments</div>)}/>
                      </div>
                    )
                  }
                </ToolkitProvider>
              }
            </Col>
          </Row>
        )}
      </ModalBody>
      { !isSubmitting &&
        <ModalFooter>
          <Col lg={12} style={{width: '100%'}}>
            <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
            <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleSubmit()}>Submit</Button>
          </Col>
        </ModalFooter>
      }
    </Modal>
  );
};

export default CreateEditSingleModal;