const { DEFINE_USER, OPEN_PRODUCT_LOOKUP, SET_SELECTED_VIEW, SET_VIEW_OPTIONS, OPEN_ORDER_DETAIL, SET_START_DATE, SET_END_DATE, SET_SHIPPING_VENDOR_OPTIONS, SET_MOBILE, SIDEBAR_COLLAPSE, SET_TITAN_VIEW_OPTIONS, OPEN_RETURN_TRACKING_HISTORY, OPEN_REIMBURSEMENT_DETAIL, OPEN_RETURN_TRACKING_IMAGE_UPLOAD, OPEN_CREATE_EDIT_RETURN, OPEN_IMAGE_CAPTURE, OPEN_RETURN_REIMBURSEMENT_DETAIL, SET_RETURN_STATUS_FILTER, SET_RETURN_INSPECTION_FILTER, SET_REMOVALS_STATUS_FILTER, OPEN_CREATE_EDIT_REMOVAL, OPEN_REMOVALS_BATCH_DETAIL, SET_ADMIN_VIEW_OPTIONS, OPEN_CREATE_EDIT_SINGLE_SKU_SHIPMENT, SET_SINGLE_SKU_SHIPMENTS_STATUS_FILTER, SET_SHIPMENT_INVOICES_STATUS_FILTER, OPEN_CREATE_EDIT_SHIPMENT_INVOICE, SET_SHIPMENT_OPTIONS_FILTER, OPEN_BULK_SHIPMENT_UPLOAD, SET_PRICE_TRACKER_VENDOR_OPTIONS, SET_PRICE_TRACKER_VENDOR, OPEN_VENDOR_DETAIL, OPEN_PRICE_TRACKER_PRODUCT_DETAIL, SET_CATEGORY_OPTIONS_FILTER, OPEN_CREATE_EDIT_DIMS, OPEN_CREATE_EDIT_SINGLE, OPEN_CREATE_EDIT_BATCH, OPEN_CREATE_EDIT_CUSTOMER, SET_PRICE_TRACKER_BRAND, SET_PRICE_TRACKER_BRAND_OPTIONS, SET_PRICE_TRACKER_BRAND_ALL_OPTIONS, OPEN_CREATE_EDIT_BACKSTOCK, SET_BACKSTOCK_OPTIONS_FILTER, OPEN_CREATE_EDIT_BACKSTOCK_SINGLE } = require('../constants/action-types');

const initialState = {
  sidebar_collapse: false,
  shipment_options_filter: {value: 3, label: 'Select List'}
};

const rootReducer = (state = initialState, action) => {
  if (action.type === DEFINE_USER) {
    return { ...state, user: action.payload };
  }

  if (action.type === OPEN_PRODUCT_LOOKUP) {
    return { ...state, open_product_lookup: !state.open_product_lookup, open_product_lookup_info: action.payload };
  }

  if (action.type === SET_SELECTED_VIEW) {
    return { ...state, selected_view: action.payload };
  }

  if (action.type === SET_VIEW_OPTIONS) {
    return { ...state, view_options: action.payload };
  }

  if (action.type === SET_TITAN_VIEW_OPTIONS) {
    return { ...state, titan_view_options: action.payload };
  }

  if (action.type === SET_ADMIN_VIEW_OPTIONS) {
    return { ...state, admin_view_options: action.payload };
  }

  if (action.type === SET_SHIPPING_VENDOR_OPTIONS) {
    return { ...state, shipping_vendor_options: action.payload };
  }

  if (action.type === SET_PRICE_TRACKER_VENDOR_OPTIONS) {
    return { ...state, price_tracker_vendor_options: action.payload };
  }

  if (action.type === SET_PRICE_TRACKER_BRAND_OPTIONS) {
    return { ...state, price_tracker_brand_options: action.payload };
  }

  if (action.type === SET_PRICE_TRACKER_BRAND_ALL_OPTIONS) {
    return { ...state, price_tracker_brand_all_options: action.payload };
  }

  if (action.type === OPEN_ORDER_DETAIL) {
    return { ...state, open_order_detail: !state.open_order_detail, open_order_detail_info: action.payload };
  }

  if (action.type === SET_START_DATE) {
    return { ...state, start_date: action.payload };
  }

  if (action.type === SET_END_DATE) {
    return { ...state, end_date: action.payload };
  }

  if (action.type === SET_MOBILE) {
    return { ...state, mobile: action.payload };
  }

  if (action.type === SIDEBAR_COLLAPSE) {
    return { ...state, sidebar_collapse: !state.sidebar_collapse };
  }

  if (action.type === OPEN_RETURN_TRACKING_HISTORY) {
    return { ...state, open_return_tracking_history: !state.open_return_tracking_history, open_return_tracking_history_info: action.payload };
  }

  if (action.type === OPEN_REIMBURSEMENT_DETAIL) {
    return { ...state, open_reimbursement_detail: !state.open_reimbursement_detail, open_reimbursement_detail_info: action.payload };
  }

  if (action.type === OPEN_RETURN_TRACKING_IMAGE_UPLOAD) {
    return { ...state, open_return_tracking_image_upload: !state.open_return_tracking_image_upload, open_return_tracking_image_upload_info: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_RETURN) {
    return { ...state, open_create_edit_return: !state.open_create_edit_return, open_create_edit_return_info: action.payload };
  }

  if (action.type === OPEN_IMAGE_CAPTURE) {
    return { ...state, open_image_capture: !state.open_image_capture, open_image_capture_info: action.payload };
  }

  if (action.type === OPEN_RETURN_REIMBURSEMENT_DETAIL) {
    return { ...state, open_return_reimbursement_detail: !state.open_return_reimbursement_detail, open_return_reimbursement_detail_info: action.payload };
  }

  if (action.type === SET_RETURN_STATUS_FILTER) {
    return { ...state, return_status_filter: action.payload };
  }

  if (action.type === SET_RETURN_INSPECTION_FILTER) {
    return { ...state, return_inspection_filter: action.payload };
  }

  if (action.type === SET_REMOVALS_STATUS_FILTER) {
    return { ...state, removals_status_filter: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_REMOVAL) {
    return { ...state, open_create_edit_removal: !state.open_create_edit_removal, open_create_edit_removal_info: action.payload };
  }

  if (action.type === OPEN_REMOVALS_BATCH_DETAIL) {
    return { ...state, open_removals_batch_detail: !state.open_removals_batch_detail, open_removals_batch_detail_info: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_SINGLE_SKU_SHIPMENT) {
    return { ...state, open_create_edit_single_sku_shipment: !state.open_create_edit_single_sku_shipment, open_create_edit_single_sku_shipment_info: action.payload };
  }

  if (action.type === SET_SINGLE_SKU_SHIPMENTS_STATUS_FILTER) {
    return { ...state, single_sku_shipments_status_filter: action.payload };
  }

  if (action.type === SET_SHIPMENT_INVOICES_STATUS_FILTER) {
    return { ...state, shipment_invoices_status_filter: action.payload };
  }

  if (action.type === SET_SHIPMENT_OPTIONS_FILTER) {
    return { ...state, shipment_options_filter: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_SHIPMENT_INVOICE) {
    return { ...state, open_create_edit_shipment_invoice: !state.open_create_edit_shipment_invoice, open_create_edit_shipment_invoice_info: action.payload };
  }

  if (action.type === OPEN_BULK_SHIPMENT_UPLOAD) {
    return { ...state, open_bulk_shipment_upload: !state.open_bulk_shipment_upload };
  }

  if (action.type === SET_PRICE_TRACKER_VENDOR) {
    return { ...state, price_tracker_vendor: action.payload };
  }

  if (action.type === SET_PRICE_TRACKER_BRAND) {
    return { ...state, price_tracker_brand: action.payload };
  }

  if (action.type === OPEN_VENDOR_DETAIL) {
    return { ...state, open_vendor_detail: !state.open_vendor_detail, open_vendor_detail_info: action.payload };
  }

  if (action.type === OPEN_PRICE_TRACKER_PRODUCT_DETAIL) {
    return { ...state, open_price_tracker_product_detail: !state.open_price_tracker_product_detail, open_price_tracker_product_detail_info: action.payload };
  }

  if (action.type === SET_CATEGORY_OPTIONS_FILTER) {
    return { ...state, category_options_filter: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_DIMS) {
    return { ...state, open_create_edit_dims: !state.open_create_edit_dims, open_create_edit_dims_info: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_SINGLE) {
    return { ...state, open_create_edit_single: !state.open_create_edit_single, open_create_edit_single_info: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_BATCH) {
    return { ...state, open_create_edit_batch: !state.open_create_edit_batch, open_create_edit_batch_info: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_CUSTOMER) {
    return { ...state, open_create_edit_customer: !state.open_create_edit_customer, open_create_edit_customer_info: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_BACKSTOCK) {
    return { ...state, open_create_edit_backstock: !state.open_create_edit_backstock, open_create_edit_backstock_info: action.payload };
  }

  if (action.type === SET_BACKSTOCK_OPTIONS_FILTER) {
    return { ...state, backstock_options_filter: action.payload };
  }

  if (action.type === OPEN_CREATE_EDIT_BACKSTOCK_SINGLE) {
    return { ...state, open_create_edit_backstock_single: !state.open_create_edit_backstock_single, open_create_edit_backstock_single_info: action.payload };
  }
};

export default rootReducer;