import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FaAmazon, FaRegTimesCircle } from 'react-icons/fa';
import { openCreateEditCustomer } from '../../redux/actions';
import inboundShipmentsService from '../../services/inbound_shipments.service';
import { PulseLoader } from 'react-spinners';
import CustomerInfo from '../../models/customer_info_item.model';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Store } from 'react-notifications-component';

const { SearchBar } = Search;

const CreateEditCustomerModal = (props) => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.open_create_edit_customer_info);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rows] = useState(info);
  const [columns, setColumns] = useState(info);
  const [customerInfo, setCustomerInfo] = useState(null);

  const getCustomerInfo = async (orderId) => {
    try {
      const newCustomerInfo = await inboundShipmentsService.getCustomerInfo(orderId);
      setCustomerInfo(new CustomerInfo(newCustomerInfo));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCustomerInfo(info[0].package_reference_no_4);
  }, []);

  useEffect(() => {
    const newColumns = [
      {
        text: 'Package Ref 4',
        dataField: 'package_reference_no_4',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      },
      {
        text: 'Delivery Date',
        dataField: 'date_delivered',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
      },
      {
        text: 'Weight',
        dataField: 'package_weight',
        sort: true,
        style: (cell, row) => {
          if (row.package_weight > 48) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_weight}</span>;
        }
      },
      {
        text: 'L',
        dataField: 'package_length',
        sort: true,
        style: (cell, row) => {
          if (row.package_length > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_length}</span>;
        }
      },
      {
        text: 'W',
        dataField: 'package_width',
        sort: true,
        style: (cell, row) => {
          if (row.package_width > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_width}</span>;
        }
      },
      {
        text: 'H',
        dataField: 'package_height',
        sort: true,
        style: (cell, row) => {
          if (row.package_height > 24) {
            return { backgroundColor: 'rgba(240, 128, 128, 0.5)', textAlign: 'center', verticalAlign: 'middle' };
          } else {
            return { textAlign: 'center', verticalAlign: 'middle' };
          }
        },
        formatter: (cell, row) => {
          return <span>{row.package_height}</span>;
        }
      },
      {
        text: 'OWT',
        dataField: 'overweight',
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        formatter: (cell, row) => {
          if (row.overweight === 1) {
            return <FaRegTimesCircle style={{fontSize: '15px', marginBottom: '2px', color: 'red'}}/>;
          }
        }
      },
      {
        text: 'Inbound Tracking Number',
        dataField: 'inbound_tracking_number',
        headerStyle: {width: '140px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true
      }
    ];

    setColumns(newColumns);
  }, [rows, setColumns]);

  const handleClose = () => {
    dispatch(openCreateEditCustomer());
    props.refresh();
  };

  const handleUpdateCustomerInfo = (e, field) => {
    const newCustomerInfo = {...customerInfo};
    newCustomerInfo[field] = e.target.value;
    setCustomerInfo(newCustomerInfo);
  };

  const validateCustomerInfo = () => {
    // everything is required except for street_line_2, if any of the field empty or blank send valid: false, else valid: true
    if (!customerInfo.first_name || !customerInfo.last_name || !customerInfo.street_line_1 || !customerInfo.city || !customerInfo.state_name || !customerInfo.zip) {
      return { valid: false };
    }

    return { valid: true };
  };

  const handleSubmit = async () => {
    const validation = validateCustomerInfo();
    if (!validation.valid) {
      Store.addNotification({
        title: 'Customer Submission Error',
        message: 'All fields are required except for Address 2.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: false
        }
      });
      return; // Stop the submission since validation failed
    }

    setIsSubmitting(true); // Start loading

    try {
      await inboundShipmentsService.submitCustomerToShipstation(customerInfo, rows);
      Store.addNotification({
        title: 'Customer Submission',
        message: 'Customer order submitted successfully!',
        type: 'default',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      setIsSubmitting(false); // Stop loading
      props.clearSelectedRows(); // Assuming you have a function to clear selected rows
      handleClose(); // Close the modal
    } catch (error) {
      console.error('Failed to submit customer order:', error);
      setIsSubmitting(false); // Stop loading
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="xl" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FaAmazon style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Inbound Shipments Customer
      </ModalHeader>
      <ModalBody className="create-edit-shipment-invoices">
        {isSubmitting ? (
          <Row>
            <Col lg={12}>
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <PulseLoader sizeUnit={'px'} size={10} color={'#123abc'} loading={isSubmitting} />
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            { customerInfo &&
              <>
                <Col lg={12}>
                  <div className="section-header" style={{marginTop: '5px'}}> Customer Info</div>
                  <hr style={{marginTop: '5px'}}/>
                </Col>
                <Col lg={8}>
                  <table>
                    <tbody>
                      <tr>
                        <td className="category-titles">Order#:</td>
                        <td className="category-text pointer">
                          <Input type="text" id="order_id" disabled style={{fontSize: '12px'}} value={customerInfo.order_id} onChange={(e) => handleUpdateCustomerInfo(e, 'order_id')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">First Name:</td>
                        <td className="category-text pointer">
                          <Input type="text" id="first_name" style={{fontSize: '12px'}} value={customerInfo.first_name} onChange={(e) => handleUpdateCustomerInfo(e, 'first_name')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">Last Name:</td>
                        <td className="category-text pointer">
                          <Input type="text" id="last_name" style={{fontSize: '12px'}} value={customerInfo.last_name} onChange={(e) => handleUpdateCustomerInfo(e, 'last_name')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">Address 1:</td>
                        <td className="category-text pointer">
                          <Input type="text" id="street_line_1" style={{fontSize: '12px'}} value={customerInfo.street_line_1} onChange={(e) => handleUpdateCustomerInfo(e, 'street_line_1')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">Address 2:</td>
                        <td className="category-text pointer">
                          <Input type="text" id="street_line_2" style={{fontSize: '12px'}} value={customerInfo.street_line_2} onChange={(e) => handleUpdateCustomerInfo(e, 'street_line_2')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">City:</td>
                        <td className="category-text pointer">
                          <Input type="text" id="city" style={{fontSize: '12px'}} value={customerInfo.city} onChange={(e) => handleUpdateCustomerInfo(e, 'city')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">State:</td>
                        <td className="category-text pointer">
                          <Input type="text" id="state_name" style={{fontSize: '12px'}} value={customerInfo.state_name} onChange={(e) => handleUpdateCustomerInfo(e, 'state_name')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">Zip:</td>
                        <td className="category-text pointer">
                          <Input type="text" id="zip" style={{fontSize: '12px'}} value={customerInfo.zip} onChange={(e) => handleUpdateCustomerInfo(e, 'zip')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="category-titles">Phone:</td>
                        <td className="category-text pointer">
                          <Input type="text" id="zip" style={{fontSize: '12px'}} value={customerInfo.phone} onChange={(e) => handleUpdateCustomerInfo(e, 'phone')} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </>
            }
            <Col lg={12}>
              <div className="section-header" style={{marginTop: '5px'}}> Items</div>
              <hr style={{marginTop: '5px'}}/>
            </Col>
            <Col lg={12}>
              { columns &&
                <ToolkitProvider keyField="inbound_tracking_number" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                  {
                    props => (
                      <div>
                        <div style = {{ float: 'right', width: '200px' }}>
                          <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                        </div>
                        <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" condensed bordered={false} noDataIndication={() => (<div>There are currently no items</div>)}/>
                      </div>
                    )
                  }
                </ToolkitProvider>
              }
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
          <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-primary" onClick={() => handleSubmit()}>Submit</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default CreateEditCustomerModal;