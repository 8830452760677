import moment from 'moment';

export default class PriceTrackerSellerViolationsModel {
  constructor(data, index) {
    this.id = index;
    this.seller_id = data && data.seller_id ? data.seller_id : '';
    this.is_authorized = data && data.is_authorized ? data.is_authorized : 0;
    this.seller_name = data && data.seller_name ? data.seller_name : '';
    this.address = data && data.address ? data.address : '';
    this.city = data && data.city ? data.city : '';
    this.state = data && data.state ? data.state : '';
    this.zip = data && data.zip ? data.zip : '';
    this.country = data && data.country ? data.country : '';
    this.asin_cnt = data && data.asin_cnt ? data.asin_cnt : 0;
    this.map_violations = data && data.map_violations ? data.map_violations : 0;
    this.asin_pct = data && data.asin_pct ? `${Math.round(data.asin_pct * 1000) / 10}%` : 0;
    this.is_fulfilled_by_amazon = data && data.is_fulfilled_by_amazon ? data.is_fulfilled_by_amazon : '';
    this.is_prime = data && data.is_prime ? data.is_prime : '';
    this.listing_price_amount = data && data.listing_price_amount ? data.listing_price_amount : 0;
    this.map = data && data.map ? data.map : 0;
    this.variance = Math.round((this.listing_price_amount - this.map) * 100) / 100;
    this.created_ts = data && data.created_ts ? moment(data.created_ts).format('MM/DD/YYYY h:mm A') : '';
  } 
}