import React, { useCallback, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import viewsService from '../../services/views.service';
import ProfitReportDailyModel from '../../models/profit_report_daily.model';
import { TiExportOutline } from 'react-icons/ti';
import { FaSyncAlt } from 'react-icons/fa';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import Select from 'react-select';
import './Views.css';
import { AiOutlineDollar } from 'react-icons/ai';
// import { useDispatch, useSelector } from 'react-redux';
// import { setSelectedView } from '../../redux/actions';

const { SearchBar } = Search;

const ProfitReportMonthly = () => {
  // const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  // const viewOptions = useSelector(state => state.view_options);
  // const selectedView = useSelector(state => state.selected_view);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const results = await viewsService.profitReportMonthly();
      const newSales = results.map(r => new ProfitReportDailyModel(r));
      setRows(newSales);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'Month',
        dataField: 'month',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Orders',
        dataField: 'order_count',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Ebay',
        dataField: 'ebay_count',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Amazon',
        dataField: 'amazon_count',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Shopify',
        dataField: 'shopify_count',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'HLC',
        dataField: 'hlc_count',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'QBP',
        dataField: 'qbp_count',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'TGC',
        dataField: 'tgc_count',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'JBI',
        dataField: 'jbi_count',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'OH',
        dataField: 'oh_count',
        style: {textAlign: 'center'},
        sort: true,
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Total',
        dataField: 'total',
        style: {textAlign: 'right'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return parseFloat(b) - parseFloat(a);
          }
          return parseFloat(a) - parseFloat(b);
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Tax',
        dataField: 'tax',
        style: {textAlign: 'right'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return parseFloat(b) - parseFloat(a);
          }
          return parseFloat(a) - parseFloat(b);
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Product Cost',
        dataField: 'product_cost',
        style: {textAlign: 'right'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return parseFloat(b) - parseFloat(a);
          }
          return parseFloat(a) - parseFloat(b);
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Shipping Cost',
        dataField: 'shipping_cost',
        style: {textAlign: 'right'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return parseFloat(b) - parseFloat(a);
          }
          return parseFloat(a) - parseFloat(b);
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Channel Fee',
        dataField: 'channel_fee',
        style: {textAlign: 'right'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return parseFloat(b) - parseFloat(a);
          }
          return parseFloat(a) - parseFloat(b);
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Net Profit',
        dataField: 'net_profit',
        style: {textAlign: 'right'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return parseFloat(b) - parseFloat(a);
          }
          return parseFloat(a) - parseFloat(b);
        },
        filter: textFilter({className: 'filter-box'})
      }
    ];

    setColumns(newColumns);
    getData();
    return () => getData();
  }, []);

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="month" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <AiOutlineDollar className="text-success" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Profit Report - Monthly</span>
                        </div>
                        {/* <Select className="dropdown" name="view_option" options={viewOptions} value={selectedView} onChange={(e) => dispatch(setSelectedView(e))}/> */}
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" color="outline-success" onClick={() => getData()}><FaSyncAlt style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable classes="table-scroll" {...props.baseProps} pagination={pagination} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no sales</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default ProfitReportMonthly;