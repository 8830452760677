import { DEFINE_USER, OPEN_BULK_SHIPMENT_UPLOAD, OPEN_CREATE_EDIT_BACKSTOCK, OPEN_CREATE_EDIT_BACKSTOCK_SINGLE, OPEN_CREATE_EDIT_BATCH, OPEN_CREATE_EDIT_CUSTOMER, OPEN_CREATE_EDIT_DIMS, OPEN_CREATE_EDIT_REMOVAL, OPEN_CREATE_EDIT_RETURN, OPEN_CREATE_EDIT_SHIPMENT_INVOICE, OPEN_CREATE_EDIT_SINGLE, OPEN_CREATE_EDIT_SINGLE_SKU_SHIPMENT, OPEN_IMAGE_CAPTURE, OPEN_ORDER_DETAIL, OPEN_PRICE_TRACKER_PRODUCT_DETAIL, OPEN_PRODUCT_LOOKUP, OPEN_REIMBURSEMENT_DETAIL, OPEN_REMOVALS_BATCH_DETAIL, OPEN_RETURN_REIMBURSEMENT_DETAIL, OPEN_RETURN_TRACKING_HISTORY, OPEN_RETURN_TRACKING_IMAGE_UPLOAD, OPEN_VENDOR_DETAIL, SET_ADMIN_VIEW_OPTIONS, SET_BACKSTOCK_OPTIONS_FILTER, SET_CATEGORY_OPTIONS_FILTER, SET_END_DATE, SET_MOBILE, SET_PRICE_TRACKER_BRAND, SET_PRICE_TRACKER_BRAND_ALL_OPTIONS, SET_PRICE_TRACKER_BRAND_OPTIONS, SET_PRICE_TRACKER_VENDOR, SET_PRICE_TRACKER_VENDOR_OPTIONS, SET_REMOVALS_STATUS_FILTER, SET_RETURN_INSPECTION_FILTER, SET_RETURN_STATUS_FILTER, SET_SELECTED_VIEW, SET_SHIPMENT_INVOICES_STATUS_FILTER, SET_SHIPMENT_OPTIONS_FILTER, SET_SHIPPING_VENDOR_OPTIONS, SET_SINGLE_SKU_SHIPMENTS_STATUS_FILTER, SET_START_DATE, SET_TITAN_VIEW_OPTIONS, SET_VIEW_OPTIONS, SIDEBAR_COLLAPSE } from '../constants/action-types';

export const defineUser = (payload) => {
  return { type: DEFINE_USER, payload };
};

export const openProductLookup = (payload) => {
  return { type: OPEN_PRODUCT_LOOKUP, payload };
};

export const setSelectedView = (payload) => {
  return { type: SET_SELECTED_VIEW, payload };
};

export const setViewOptions = (payload) => {
  return { type: SET_VIEW_OPTIONS, payload };
};

export const setTitanViewOptions = (payload) => {
  return { type: SET_TITAN_VIEW_OPTIONS, payload };
};

export const setAdminViewOptions = (payload) => {
  return { type: SET_ADMIN_VIEW_OPTIONS, payload };
};

export const setShippingVendorOptions = (payload) => {
  return { type: SET_SHIPPING_VENDOR_OPTIONS, payload };
};

export const setPriceTrackerVendorOptions = (payload) => {
  return { type: SET_PRICE_TRACKER_VENDOR_OPTIONS, payload };
};

export const setPriceTrackerBrandOptions = (payload) => {
  return { type: SET_PRICE_TRACKER_BRAND_OPTIONS, payload };
};

export const setPriceTrackerBrandAllOptions = (payload) => {
  return { type: SET_PRICE_TRACKER_BRAND_ALL_OPTIONS, payload };
};

export const openOrderDetail = (payload) => {
  return { type: OPEN_ORDER_DETAIL, payload };
};

export const setStartDate = (payload) => {
  return { type: SET_START_DATE, payload };
};

export const setEndDate = (payload) => {
  return { type: SET_END_DATE, payload };
};

export const setMobile = (payload) => {
  return { type: SET_MOBILE, payload };
};

export const sidebarCollapse = (payload) => {
  return { type: SIDEBAR_COLLAPSE, payload };
};

export const openReturnTrackingHistory = (payload) => {
  return { type: OPEN_RETURN_TRACKING_HISTORY, payload };
};

export const openReimbursementDetail = (payload) => {
  return { type: OPEN_REIMBURSEMENT_DETAIL, payload };
};

export const openReturnTrackingImageUpload = (payload) => {
  return { type: OPEN_RETURN_TRACKING_IMAGE_UPLOAD, payload };
};

export const openCreateEditReturn = (payload) => {
  return { type: OPEN_CREATE_EDIT_RETURN, payload };
};

export const openImageCapture = (payload) => {
  return { type: OPEN_IMAGE_CAPTURE, payload };
};

export const openReturnReimbursementDetail = (payload) => {
  return { type: OPEN_RETURN_REIMBURSEMENT_DETAIL, payload };
};

export const setReturnStatusFilter = (payload) => {
  return { type: SET_RETURN_STATUS_FILTER, payload };
};

export const setReturnInspectionFilter = (payload) => {
  return { type: SET_RETURN_INSPECTION_FILTER, payload };
};

export const setRemovalsStatusFilter = (payload) => {
  return { type: SET_REMOVALS_STATUS_FILTER, payload };
};

export const openCreateEditRemoval = (payload) => {
  return { type: OPEN_CREATE_EDIT_REMOVAL, payload };
};

export const openRemovalsBatchDetail = (payload) => {
  return { type: OPEN_REMOVALS_BATCH_DETAIL, payload };
};

export const openCreateEditSingleSkuShipment = (payload) => {
  return { type: OPEN_CREATE_EDIT_SINGLE_SKU_SHIPMENT, payload };
};

export const setSingleSkuShipmentsStatusFilter = (payload) => {
  return { type: SET_SINGLE_SKU_SHIPMENTS_STATUS_FILTER, payload };
};

export const setShipmentInvoicesStatusFilter = (payload) => {
  return { type: SET_SHIPMENT_INVOICES_STATUS_FILTER, payload };
};

export const setShipmentOptionsFilter = (payload) => {
  return { type: SET_SHIPMENT_OPTIONS_FILTER, payload };
};

export const openCreateEditShipmentInvoice = (payload) => {
  return { type: OPEN_CREATE_EDIT_SHIPMENT_INVOICE, payload };
};

export const openBulkShipmentUpload = (payload) => {
  return { type: OPEN_BULK_SHIPMENT_UPLOAD, payload };
};

export const setPriceTrackerVendor = (payload) => {
  return { type: SET_PRICE_TRACKER_VENDOR, payload };
};

export const setPriceTrackerBrand = (payload) => {
  return { type: SET_PRICE_TRACKER_BRAND, payload };
};

export const openVendorDetail = (payload) => {
  return { type: OPEN_VENDOR_DETAIL, payload };
};

export const openPriceTrackerProductDetail = (payload) => {
  return { type: OPEN_PRICE_TRACKER_PRODUCT_DETAIL, payload };
};

export const setCategoryOptionsFilter = (payload) => {
  return { type: SET_CATEGORY_OPTIONS_FILTER, payload };
};

export const openCreateEditDims = (payload) => {
  return { type: OPEN_CREATE_EDIT_DIMS, payload };
};

export const openCreateEditSingle = (payload) => {
  return { type: OPEN_CREATE_EDIT_SINGLE, payload };
};

export const openCreateEditBatch = (payload) => {
  return { type: OPEN_CREATE_EDIT_BATCH, payload };
};

export const openCreateEditCustomer = (payload) => {
  return { type: OPEN_CREATE_EDIT_CUSTOMER, payload };
};

export const openCreateEditBackstock = (payload) => {
  return { type: OPEN_CREATE_EDIT_BACKSTOCK, payload };
};

export const setBackstockOptionsFilter = (payload) => {
  return { type: SET_BACKSTOCK_OPTIONS_FILTER, payload };
};

export const openCreateEditBackstockSingle = (payload) => {
  return { type: OPEN_CREATE_EDIT_BACKSTOCK_SINGLE, payload };
};