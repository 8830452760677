import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { openBulkShipmentUpload } from '../../redux/actions';
import InvoiceUploader from './InvoiceUploader';
import { PulseLoader } from 'react-spinners';
import ShipmentInvoiceItem from '../../models/shipment_invoice_item.model';

const BulkShipmentUpload = (props) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const shipmentInvoiceStatusFilter = useSelector(state => state.shipment_invoices_status_filter);
  const shipmentOptionsFilter = useSelector(state => state.shipment_options_filter);
  const [shipmentInvoiceInfo, setShipmentInvoiceInfo] = useState(new ShipmentInvoiceItem());

  const handleClose = () => {
    dispatch(openBulkShipmentUpload());
    props.refresh(shipmentInvoiceStatusFilter.value, shipmentOptionsFilter.value);
  };
  
  const handleCheck = (e) => {
    const newShipmentInvoiceInfo = JSON.parse(JSON.stringify(shipmentInvoiceInfo));
    newShipmentInvoiceInfo[e.target.id] = e.target.checked;
    setShipmentInvoiceInfo(newShipmentInvoiceInfo);
  };

  return (
    <Modal className="product-lookup" isOpen={props.isOpen} toggle={() => handleClose()} backdrop="static" size="xl" autoFocus={false}>
      <ModalHeader toggle={() => handleClose()}>
        <FaFileInvoiceDollar style={{marginBottom: '5px', color: '#00008b', marginRight: '8px' }} />Bulk Invoice Upload
      </ModalHeader>
      <ModalBody className="create-edit-shipment-invoices">
        <Row>
          <Col lg={12} style={{marginBottom: '10px', textAlign: 'right'}}>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" id="qbp" checked={shipmentInvoiceInfo.qbp} onChange={(e) => handleCheck(e)} />{' '}
                QBP
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" id="jbi" checked={shipmentInvoiceInfo.jbi} onChange={(e) => handleCheck(e)} />{' '}
                JBI
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" id="hlc" checked={shipmentInvoiceInfo.hlc} onChange={(e) => handleCheck(e)} />{' '}
                HLC
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" id="tgc" checked={shipmentInvoiceInfo.tgc} onChange={(e) => handleCheck(e)} />{' '}
                TGC
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" id="olympic" checked={shipmentInvoiceInfo.olympic} onChange={(e) => handleCheck(e)} />{' '}
                Olympic
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" id="bti" checked={shipmentInvoiceInfo.bti} onChange={(e) => handleCheck(e)} />{' '}
                BTI
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" id="mpn" checked={shipmentInvoiceInfo.mpn} onChange={(e) => handleCheck(e)} />{' '}
                MPN
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" id="upc" checked={shipmentInvoiceInfo.upc} onChange={(e) => handleCheck(e)} />{' '}
                UPC
              </Label>
            </FormGroup>
          </Col>
          <Col lg={12}>
            <div className="bulk-upload-instructions">
              Invoice files must be named with proper Shipment IDs and be in PDF format for bulk load to work. (e.g. FBA179VRD1F9.pdf)
            </div>
          </Col>
          { uploading ? 
            <Col lg={12} style={{textAlign: 'center', marginTop: '20px'}}>
              <PulseLoader 
                sizeUnit={'px'}
                size={20}
                color={'#419bf7'}
              />
            </Col>
            : 
            <Col lg={12} style={{marginLeft: '350px', marginTop: '20px'}}>
              <InvoiceUploader bulk={true} shipmentInvoiceInfo={shipmentInvoiceInfo} setUploading={setUploading}/>
            </Col>
          }
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col lg={12} style={{width: '100%'}}>
          <Button size="sm" style={{float: 'right', marginLeft: '5px'}} color="outline-secondary" onClick={() => handleClose()}>Close</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default BulkShipmentUpload;