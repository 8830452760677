export default class ShipmentInvoiceDetailItem {
  constructor(data) {
    this.shipment_name = data && data.shipment_name ? data.shipment_name : '';
    this.shipment_id = data && data.shipment_id ? data.shipment_id : '';
    this.seller_sku = data && data.seller_sku ? data.seller_sku : '';
    this.fnsku = data && data.fnsku ? data.fnsku : '';
    this.asin = data && data.asin ? data.asin : '';
    this.qty_shipped = data && data.qty_shipped ? data.qty_shipped : 0;
    this.qty_received = data && data.qty_received ? data.qty_received : 0;
    this.qty_in_case = data && data.qty_in_case ? data.qty_in_case : 0;
    this.vendor_name = data && data.vendor_name ? data.vendor_name : '';
    this.vendor_mpn = data && data.vendor_mpn ? data.vendor_mpn : '';
    this.upc_ean = data && data.upc_ean ? data.upc_ean : 0;
    this.manufacturer_part_no = data && data.manufacturer_part_no ? data.manufacturer_part_no : '';
    this.description = data && data.description ? data.description : '';
    this.qbp_sku = data && data.qbp_sku ? data.qbp_sku : '';
    this.jbi_sku = data && data.jbi_sku ? data.jbi_sku : '';
    this.hlc_sku = data && data.hlc_sku ? data.hlc_sku : '';
    this.tgc_sku = data && data.tgc_sku ? data.tgc_sku : '';
    this.olympic_sku = data && data.olympic_sku ? data.olympic_sku : '';
    this.bti_sku = data && data.bti_sku ? data.bti_sku : '';
  } 
}